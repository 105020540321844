define("web-map/components/map-info-bar", ["exports", "axios", "web-map/config/environment"], function (_exports, _axios, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['w-100'],
    olMapservice: Ember.inject.service('ol-mapservice'),
    store: Ember.inject.service(),
    tagName: 'div',
    srs_value: 0,
    map_scale: 1000000,
    // need to calculate this
    x_pos: 400000,
    y_pos: 600000,
    //   fp_status: true,
    guideLink: null,
    srsFieldValue: '5247',
    showLegendContent: false,
    activeRowStyle: Ember.String.htmlSafe("background-color: #d3d7dc;"),
    currentNlumpDesc: null,

    init() {
      this._super(...arguments);

      if (_environment.default.W3O_openlayers.deployment == 'GEOPORTAL') {
        this.setProperties({
          form_color: 'white',
          input_scale: 'inputScale11',
          srs_disabled: false,
          is_geoportal: true
        });
        this.guideLink = "".concat(this.store.adapterFor('application').get('host'), "/pdf/GeoportalUserGuide.pdf");
        (0, _axios.default)({
          method: "GET",
          url: "/assets/map-legends.json"
        }).then(response => {
          if (response.data) {
            console.log('nlump response');
            console.log(response);
            this.set("legends", response.data); // let legendArray = [];
            // Object.keys(this.get("legend")).forEach(keyLegend => {
            // legendArray.push( EmberObject.create(this.get("legend")[keyLegend]) );
            // });
            // this.set("legendArray", legendArray);
          }
        });
      } else {
        this.setProperties({
          form_color: 'green',
          input_scale: 'inputScale',
          srs_disabled: true,
          is_geoportal: false
        });
        this.set('srsFieldValue', this.get('srsSwitch'));
      }
    },

    didInsertElement() {
      /* const olmap2svc = this.get('olmap2svc');
      const map = olmap2svc.getOmap();
      const initialExtent = olmap2svc.getOinitialextent(map);    
       const layers = olmap2svc.getOlayers(map);
       this.setProperties({
        map: map,
        initialExtent: initialExtent,
        layers: layers
      }); */
      this.initMap(); // action closure

      if (_environment.default.W3O_openlayers.deployment == 'RAD') {
        this.set('srsFieldValue', this.get('srsSwitch'));
      }

      console.log('hoichong stmap map-info-bar sendaction done');
    },

    actions: {
      updateMapScale() {
        const olMapservice = this.get('olMapservice');
        olMapservice.changeMapScale(olMapservice, this.map_scale);
      },

      selectSRS(value, event) {
        const olMapservice = this.get('olMapservice');
        console.log('map-info-bar trying to clear layers and search results');
        olMapservice.clearSearchResults();
        this.reloadMapLayers(value);
        olMapservice.searchEPSG(olMapservice, value);
      },

      setInfoMousePointer() {
        const olMapservice = this.get('olMapservice');
        console.log('map-info-bar trying to set info mouse pointer');
        let map_panel_arr = document.getElementsByClassName("ol-map-panel");
        map_panel_arr[0].style.cursor = "pointer";
      },

      toggleFunctionPanel() {
        this.toggleFunctionPanel();
        /*
        let fp_status1 = this.get('fp_status');
        const elementA =  document.querySelector('#functionPanel21'); 
        const olMapservice = this.get('olMapservice');
        console.log('hoichong stmap map-info-bar YOU just CLICK on ATOM fp_status1: ' +fp_status1);
        if (Boolean(fp_status1)) {
        console.log('Hide FP..');
        fp_status1 = !fp_status1;
        olMapservice.animateCSS('#functionPanel21', 'bounceOutLeft', function() {
          elementA.style.display = "none";
          //fp_status1 = fp_status1 ? false : true;         
        });
        } else {
        console.log('Show FP..');
        fp_status1 = !fp_status1;
        elementA.style.display = "block";
        olMapservice.animateCSS('#functionPanel21', 'bounceInLeft', function() {
          elementA.style.display = "block";
        });        
        }
        console.log('final change to ' +fp_status1);
        this.set('fp_status',fp_status1);
        */
      },

      invalidateSession() {
        this.invalidateSession();
      },

      gotoHome() {
        window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/start-gp"));
      },

      gotoShopping() {
        window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/shopping-cart"));
      },

      toggleShowLegendContent() {
        this.set("showLegendContent", !this.get("showLegendContent"));
      },

      toogleTooltip(legend) {
        if (this.get("selectedLegend")) {
          if (legend.name === this.get("selectedLegend.name")) {
            legend.set("activeRow", null);
            this.set("selectedLegend", null);
          } else {
            this.get("selectedLegend").set("activeRow", null);
            legend.set("activeRow", this.get("activeRowStyle"));
            this.set("selectedLegend", legend);
          }
        } else {
          legend.set("activeRow", this.get("activeRowStyle"));
          this.set("selectedLegend", legend);
        }
      },

      closeLegend() {
        this.get("selectedLegend").set("activeRow", null);
        this.set("selectedLegend", null);
      },

      changeNlumpDesc(selectedNlumpDesc) {
        this.set('currentNlumpDesc', selectedNlumpDesc);
      }

    }
  });

  _exports.default = _default;
});