define("web-map/routes/cashpayment", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "axios", "jquery"], function (_exports, _authenticatedRouteMixin, _axios, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    authObject: Ember.inject.service('session'),

    beforeModel() {
      if (!this.get('authObject.data.user.receive_cash_payment')) {
        this.transitionTo("checkout");
      }
    },

    model() {
      return this.store.query('order', {
        where: {
          user: this.get('authObject.data.user.id'),
          isdelete: false,
          status: 'Process'
        }
      });
    },

    async setupController(controller, model) {
      model = model.objectAt(0);
      controller.set('model', model);
      controller.set('user', this.get('authObject.data.user'));
      console.log(model);
      let orderDetails = [];

      if (model.get('type') === 'RSO') {
        orderDetails = await this.store.query('rsoexport', {
          where: {
            order_number: model.get('order_number'),
            isdelete: false
          },
          sort: "id ASC"
        });
      } else if (model.get('type') === 'Geodetic') {
        orderDetails = await this.store.query('geodeticexport', {
          where: {
            order_number: model.get('order_number'),
            isdelete: false
          },
          sort: "id ASC"
        });
      }

      console.log(orderDetails);
      controller.setProperties({
        showReceiptNumberError: false,
        disableFOCfields: false,
        receipt_number: null,
        paymentSuccess: false,
        orderDetails
      });
    },

    actions: {
      showCancelOrder() {
        (0, _jquery.default)("#cancel-order-modal-cashpayment").modal("show");
      },

      cancelOrder() {
        let controller = this.get('controller');
        let model = controller.get('model');
        model.set('status', 'Cancelled');
        model.save().then(() => {
          (0, _jquery.default)("#cancel-order-modal-cashpayment").modal("hide");
          window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/map"));
        });
      },

      submitCashPayment() {
        let controller = this.controller;

        if (controller.get("receipt_number") === null || controller.get("receipt_number") === '') {
          controller.set("showReceiptNumberError", true);
          return;
        } else {
          controller.set("showReceiptNumberError", false);
        }

        controller.set("disableFOCfields", true);
        (0, _axios.default)({
          method: "POST",
          data: {
            order_number: controller.get("model.order_number"),
            receipt_number: controller.get("receipt_number")
          },
          url: this.store.adapterFor('application').get('host') + this.store.adapterFor('application').get('namespace') + '/orders/submitCashPayment'
        }).then(function (response) {
          if (response && response.status === 200) {
            controller.set("paymentSuccess", true);
            controller.set("paymentSuccessMessage", response.data.message);
          }
        }).catch(function (error) {
          console.log('Error ' + error);
        });
      },

      gotoHome() {
        //cannot use transitionTo because it wont load the map
        window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/map"));
      },

      async downloadRSO(model) {
        let controller = this.get('controller');
        controller.set('start_loading', true);
        let pdf_name;
        controller.get('orderDetails').forEach((order, index) => {
          console.log(order);

          if (index === 0) {
            pdf_name = order.pdf_name;
          }
        });
        let response = null;
        let url_rso_geodetic = model.get('type') === 'RSO' ? '/rsosheets/downloadRSOSheet' : '/geodetics/downloadGeodeticSheet';
        let url_featureexport = "".concat(this.store.adapterFor('application').get('serverHost')).concat(this.store.adapterFor('application').get('namespace')).concat(url_rso_geodetic);
        response = await _axios.default.post(url_featureexport, {
          order_number: model.get('order_number'),
          pdf_name
        }).catch(error => {
          console.log("An error occured while downloading ".concat(model.get('type'), " Sheet, ").concat(error.response.data));
          alert("An error occured while downloading ".concat(model.get('type'), " Sheet, ").concat(error.response.data));
          return null;
        });

        if (response.data) {
          let {
            base64,
            fileName,
            type
          } = response.data;
          let blob;

          try {
            blob = this.dataURItoBlob(base64);
          } catch (e) {
            // source: https://stackoverflow.com/a/21797381
            let binary_string = window.atob(base64);
            let len = binary_string.length;
            let bytes = new Uint8Array(len);

            for (let i = 0; i < len; i++) {
              bytes[i] = binary_string.charCodeAt(i);
            }

            let arrayBuffer = bytes.buffer;
            blob = new Blob([arrayBuffer], {
              type
            });
          }

          let blobUrl = URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = blobUrl;
          a.download = fileName || blobUrl.split('/').pop();
          a.click();
          controller.set('start_loading', false);
        }
      }

    }
  });

  _exports.default = _default;
});