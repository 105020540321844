define("web-map/components/function-panel-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      logText(event) {
        // Only when assigning the action to an inline handler, the event object
        // is passed to the action as the first parameter.
        console.log('Single Click was recorded!');
      }

    }
  });

  _exports.default = _default;
});