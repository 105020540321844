define("web-map/models/ol-feature", ["exports", "ember-data", "ol/Feature"], function (_exports, _emberData, _Feature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    ready() {
      const feature = this.get('feature');

      if (!feature) {
        const newFeature = new _Feature.default({
          geometry: this.get('geometry')
        });
        newFeature._emberRecord = this;
        this.set('feature', newFeature);
      }
    },

    geometry: _emberData.default.attr('ol-geometry')
  });

  _exports.default = _default;
});