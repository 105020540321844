define("web-map/transforms/ol-geometry", ["exports", "ember-data", "ol/format"], function (_exports, _emberData, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const format = new _format.GeoJSON();

  var _default = _emberData.default.Transform.extend({
    deserialize(serialized) {
      return format.readGeometry(serialized);
    },

    serialize(deserialized) {
      return format.writeGeometry(deserialized);
    }

  });

  _exports.default = _default;
});