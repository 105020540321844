define("web-map/mixins/w3o-users-roles/focus-for-keypress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    focusForKeypress: Ember.on('didInsertElement', function () {
      //fix for catching key events
      this.$().attr('tabindex', 0);
      this.$().focus();
    })
  });

  _exports.default = _default;
});