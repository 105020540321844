define("web-map/models/ol-layer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    ready() {
      if (!this.get('id')) {
        this.set('id', Ember.guidFor(this));
      }

      let layer = this.get('layer');
      console.log("hoichong ol-layer model layer is...");
      console.log(layer);
      layer._modelRecord = this;
      this.set('title', layer.get('title'));
      this.set('font_char', layer.get('font_char'));
      this.set('font_color', layer.get('font_color'));
      this.set('minScale', layer.get('minScale'));
      this.set('maxScale', layer.get('maxScale'));
      this.set('visiType', layer.get('visiType'));
      this.set('visible', layer.get('visible'));
      this.set('exclusive', layer.get('exclusive'));
      this.addObserver('visible', function () {
        console.log('CHANGE0 LAYER VISIBILITY');
        layer.set('visible', this.get('visible'));
      });
      layer.on('change:visible', () => {
        console.log('CHANGE LAYER VISIBILITY');
        this.set('visible', layer.get('visible'));
      });

      if (layer.getLayers) {
        console.log('hoichong ol-layer model looks like LAYERGROUP...'); //console.log(layer.getLayers())

        this.set('isGroup', true);
        const layersArray = layer.getLayers().getArray(); //console.log(layersArray)
        //FIX here

        /*const layers = layersArray.map(layer => { 
          console.log('Trying to process layers in group layers')
          console.log(layer)
          return this.store.createRecord('ol-layer', { layer }) 
        }) */
        //this.set('layers', layers)
      }
    },

    isGroup: attr('boolean', {
      defaultValue: false
    }),
    title: attr('string'),
    font_char: attr('string'),
    font_color: attr('string'),
    minScale: attr('number'),
    maxScale: attr('number'),
    visiType: attr('string'),
    visible: attr('boolean'),
    exclusive: attr('exclusive'),
    layers: hasMany('ol-layer', {
      inverse: null
    })
  });

  _exports.default = _default;
});