define("web-map/routes/login", ["exports", "jquery", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/unauthenticated-route-mixin", "web-map/mixins/w3o-users-roles/check-user-role", "web-map/mixins/w3o-users-roles/invalidate-session", "web-map/mixins/w3o-users-roles/check-session-activity", "web-map/mixins/w3o-users-roles/set-session-user", "axios", "web-map/utils/get-cookie"], function (_exports, _jquery, _applicationRouteMixin, _unauthenticatedRouteMixin, _checkUserRole, _invalidateSession, _checkSessionActivity, _setSessionUser, _axios, _getCookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // importing 3 3rd party libraries
  var _default = Ember.Route.extend(_applicationRouteMixin.default, _unauthenticatedRouteMixin.default, _checkUserRole.default, _invalidateSession.default, _checkSessionActivity.default, _setSessionUser.default, {
    authObject: Ember.inject.service('session'),
    // actionList: null,
    renderFlag: true,

    //session ganti nama jadi authObject
    async beforeModel() {
      if ((0, _getCookie.default)('isMobile') === "Yes") {
        //window.location.replace(`${this.store.adapterFor('application').get('host')}/momap`);
        this.transitionTo('momap');
      }

      if (this.get('authObject.data.user')) {
        if (this.get('authObject.data.user.status') !== 'Active') {
          return this.get('authObject').invalidate();
        } // if (this.get('authObject.data.user.authEnable') && !this.get('authObject.data.isAuth')) {
        // 	this.transitionTo('authenticate');
        // }

      } // if (this.session.get('isAuthenticated')) {
      // 	this.transitionTo('start');
      // }


      if (this.get('authObject.data.authenticated.user')) {
        this._super();

        let user = await this.store.findRecord('user', this.get('authObject.data.authenticated.user.id'));

        if (user.get('forceChangePassword')) {
          this.transitionTo('change-password');
        }
      }
    },

    async model() {
      if (this.get('authObject.data.user')) {
        let response = await this.setSessionUser();

        if (response) {
          let euser = await this.store.query('euser', {
            user: this.get('authObject.data.user.id')
          });
          let companyId = euser.get("firstObject") ? euser.get("firstObject").get('companynum.content.id') : null;

          _jquery.default.ajax({
            url: '/api/v1/applications/getApplications',
            type: 'POST',
            data: {
              userId: this.get('authObject.data.user.id'),
              companyId
            }
          }).then(response => {
            this.controller.set('loading', false);
            return this.controller.set('model', response.applications);
          });
        }
      }
    },

    setupController: function (controller) {
      controller.set('session', this.get('authObject.session'));
      controller.set('loading', true); // console.log(this.get('authObject'));

      if (this.get('authObject.isAuthenticated')) {
        controller.set('authObject', this.get('authObject'));
        controller.set('geolink', '/stmap');
      } else {
        controller.set('geolink', '/map');
      } //Set variables for nav bar


      this.controllerFor('application').set('appID', null);
      this.controllerFor('application').set('menus', null);
      Ember.run.schedule('afterRender', () => {
        (0, _jquery.default)('.owl-carousel').owlCarousel({
          loop: true,
          margin: 10,
          nav: true,
          navText: ["<i style='position: absolute; left: -5%; top: 30%; z-index: 5;' class='fa fa-2x fa-arrow-left'></i>", "<i style='position: absolute; right: -5%; top: 30%; z-index: 5;' class='fa fa-2x fa-arrow-right'></i>"],
          responsive: {
            0: {
              items: 1
            },
            768: {
              items: 4
            }
          }
        });
      });
    },

    renderTemplate() {
      this._super();
    },

    generateAppSession() {
      let controller = this.get('controller');

      if (this.get('authObject.data.user')) {
        controller.set('authObject', this.get('authObject'));

        _jquery.default.ajax({
          url: '/api/v1/applications/getAppSession',
          type: 'GET',
          data: {
            userId: this.get('authObject.data.user').id
          }
        }).then(response => {
          response.appsessions.forEach(appsession => {
            if (appsession.id) {
              this.store.findRecord(appsession.modelName, appsession.id).then(result => {
                this.set(appsession.initializerName, result);
              });
            }
          });
        });
      }
    },

    async setSession() {
      let response = await this.setSessionUser();

      if (response) {
        if (response.forceChangePassword) {
          this.transitionTo("change-password");
          return;
        }

        this.controllerFor('application').set('authorizationCompleted', true);
        this.beforeModel();
        this.model();
        this.generateAppSession();
        this.checkSessionActivity();
      }
    },

    auth2fa: function (user, identification, password) {
      this.controllerFor('application').setProperties({
        user,
        identification,
        password
      });
      this.transitionTo('authenticate');
    },
    login: function () {
      let controller = this.get('controller');
      controller.set('errorMessage', null);
      const {
        identification,
        password
      } = controller.getProperties('identification', 'password');
      this.get('authObject').authenticate('authenticator:oauth2', identification.toLowerCase().trim(), password).then(async result => {
        this.store.adapterFor("application").authorizeToken();
        await this.setSession();
        this.controllerFor("application").set("showAppLoading", false); //redirect to /start-gp

        window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/start-gp"));
      }).catch(reason => {
        if (this.get('authObject.session.isAuthenticated')) {
          this.setSession();
          this.controllerFor("application").set("showAppLoading", false); //redirect to /stmap
          // window.location.replace(`${this.store.adapterFor('application').get('host')}/disclaimerstmap`);
        } else {
          controller.set('errorMessage', reason.error || reason);
          this.controllerFor("application").set("showAppLoading", false);
        }
      });
    },
    checkUser: async function (identification, password) {
      let controller = this.get('controller');
      let url = "".concat(this.store.adapterFor('application').get('serverHost'), "/api/v1/auths/checkUser");
      let response = await _axios.default.post(url, {
        identification,
        password
      }).catch(error => {
        this.controllerFor("application").set("showAppLoading", false);
        controller.set('errorMessage', error.response.data);
      });

      if (response) {
        let {
          user
        } = response.data;
        user.authEnable ? this.auth2fa(user.id, identification, password) : this.login();
      }
    },
    actions: {
      /*
      	Transition to route
      */
      goTo(app) {
        if (app.isExternalLink) {
          if (app.routeName.indexOf('http') > -1) {
            window.location.assign(app.routeName);
          } else {
            window.location.assign('http://' + app.routeName);
          }
        } else if (app.appId) {
          this.transitionTo(app.routeName, app.appId);
        } else {
          this.transitionTo(app.routeName);
        }
      },

      setSession() {
        this.setSession();
      },

      /*
      	invalidate current session and after that, redirect to start page to login
      */
      invalidateSession() {
        this.invalidateSession();
      },

      authenticate: function () {
        let self = this;
        let flag = 0;
        let controller = this.get('controller'); // this.set('errorMessage', null);

        if (Ember.isEmpty(controller.get('identification'))) {
          controller.set('errorMessage', 'Email must be filled');
          flag++;
          return;
        }

        if (Ember.isEmpty(controller.get('password'))) {
          controller.set('errorMessage', 'Password must be filled');
          flag++;
          return;
        }

        if (flag === 0) {
          controller.set('errorMessage', null);
          this.controllerFor("application").set("showAppLoading", true);
          this.checkUser(controller.get('identification'), controller.get('password'));
        }
      },

      gotoRegistered() {
        //cannot use transitionTo because it wont load the map
        window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/stmap"));
      },

      gotoPublic() {
        //cannot use transitionTo because it wont load the map
        window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/disclaimermap"));
      },

      gotoHome() {
        //cannot use transitionTo because it wont load the map
        window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/start"));
      },

      register() {
        //cannot use transitionTo because it wont load the map
        window.open("".concat(this.store.adapterFor('application').get('ecomWebsite'), "/register"));
      }

    }
  });

  _exports.default = _default;
});