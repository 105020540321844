define("web-map/components/ol-layer-tree-item-check", ["exports", "web-map/templates/components/ol-layer-tree-item-check", "ol/proj.js"], function (_exports, _olLayerTreeItemCheck, proj) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    olMapservice: Ember.inject.service('ol-mapservice'),
    layout: _olLayerTreeItemCheck.default,
    tagName: 'i',
    classNameBindings: ['isChecked:right-icon:right-icon-inactive', 'isLocked:right-icon-lock'],
    isChecked: true,
    isLocked: false,
    previousChkValue: '',
    isLayerFirstLoaded: true,
    properTitle: '',
    layer_control_value: Ember.computed('isChecked', function () {
      const olMapservice = this.get('olMapservice');
      let map = olMapservice.get('map');
      let firstLoadedGlobal = olMapservice.get('firstLoaded');
      let clayer = this.get('layer');
      console.log('ol-layer-tree-item-check layer_control_value ...'); //console.log('compute layer_control_value  ' + this.get('isChecked') + '  ' + firstLoadedGlobal)
      //this.set('isLayerFirstLoaded',true) 

      if (firstLoadedGlobal == true && this.get('isLayerFirstLoaded') == true) {
        this.set('previousChkValue', '');
        this.set('isLayerFirstLoaded', false);
      } else {
        // potential bug here, where zoom in and out will cause isChecked to be change and this will cause this code to run
        // and then layer vistype is change to Manual override wrongly  
        olMapservice.setLayerVisType(clayer, this.get('isChecked'));
        this.set('previousChkValue', !this.get('isChecked'));
      }

      let mctlObj = {
        id: this.get('elementId'),
        currVal: this.get('isChecked'),
        prevVal: this.get('previousChkValue')
      };
      return mctlObj;
    }),

    init() {
      this._super(...arguments);

      let clayer = this.get('layer');
      let imgLayer = null;

      if ('group_array' in clayer) {
        //imgLayer = clayer.group_class.get('layer');
        imgLayer = clayer.get('layer');
      } else {
        imgLayer = clayer.get('layer');
      }

      console.log('INIT ol-layer-tree-item-check');
      let ltitle = imgLayer.values_.title.replace(/\s+/g, '');
      console.log(ltitle);
      this.set('elementId', "layer-item-eye-id-".concat(ltitle)); //layer-item-eye-id-xxxx is the id of the eye icon, layer-item-id-xxxx is the id of the input element
    },

    didRender() {
      this._super(...arguments);

      const olMapservice = this.get('olMapservice');
      let map = olMapservice.get('map');
      let unit = map.getView().getProjection().getUnits();
      let resolution = map.getView().getResolution();
      let inchesPerMetre = 39.37;
      let dpi = 96;
      let clayer = this.get('layer');
      let imgLayer = null;

      if ('group_array' in clayer) {
        //imgLayer = clayer.group_class.get('layer');
        imgLayer = clayer.get('layer');
        this.set('properTitle', imgLayer.values_.title);
      } else {
        imgLayer = clayer.get('layer');
        this.set('properTitle', imgLayer.values_.title);
      }

      let currentScale = resolution * proj.METERS_PER_UNIT[unit] * inchesPerMetre * dpi;
      console.log('ol-layer-tree-item-check component didRender...'); //console.log('layer tree item check ' + this.get('layer.visible'));

      console.log('>> elementId is: ' + this.get('elementId'));
      let elementId = this.get('elementId');
      console.log(imgLayer);
      console.log(this.get('layer').title); //console.log('currentScale: ' + currentScale);
      //console.log('layer.minScale : ' + clayer.minScale);
      //console.log('layer.maxScale : ' + clayer.maxScale);

      if (imgLayer.values_.minScale !== undefined && imgLayer.values_.minScale >= 0) {
        if (currentScale > imgLayer.values_.maxScale || currentScale < imgLayer.values_.minScale) {
          document.getElementById(elementId).disabled = true;
          this.set('isLocked', true);
          console.log('just disable this checkbox');
        } else {
          document.getElementById(elementId).disabled = false;
          this.set('isLocked', false);
          console.log('just enable this checkbox');
        }
      }

      console.log(this.get('layer_control_value')); //let itrun1 = this.get('layer_control_value');
    }

  });

  _exports.default = _default;
});