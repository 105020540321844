define("web-map/models/geodeticexport", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    isdelete: _emberData.default.attr('boolean'),
    ctl_pnt: _emberData.default.attr('string'),
    order_number: _emberData.default.attr('string'),
    job_number: _emberData.default.attr('string'),
    scala: _emberData.default.attr('string'),
    price: _emberData.default.attr('number'),
    preview_name: _emberData.default.attr('string'),
    image_name: _emberData.default.attr('string'),
    pdf_name: _emberData.default.attr('string'),
    area_size: _emberData.default.attr('number'),
    data_size: _emberData.default.attr('number'),
    file_location: _emberData.default.attr('string'),
    with_height: _emberData.default.attr('boolean'),
    is_public: _emberData.default.attr('string')
  });

  _exports.default = _default;
});