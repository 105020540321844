define("web-map/routes/momap", ["exports", "ol", "ol/layer/Tile", "ol/source/OSM"], function (_exports, _ol, _Tile, _OSM) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* import 'ol/ol.css'; */
  var _default = Ember.Route.extend({
    queryParam: null,

    init() {
      this._super(...arguments);
    },

    beforeModel: async function (transition) {
      this._super(...arguments);

      let queryParam = transition.to.queryParams;
      this.set('queryParam', queryParam);
    },

    setupController() {
      this._super(...arguments);

      Ember.run.schedule('afterRender', () => {
        if (this.get('queryParam')['rso_type']) {
          $('#layers-tab').removeClass('active');
          $('#layers').removeClass('show').removeClass('active');
          $('#rsodigital-tab').addClass('active');
          $('#rsodigital').addClass('show').addClass('active');
        }
      });
    }

  });

  _exports.default = _default;
});