define("web-map/components/feature-table", ["exports", "ember-light-table"], function (_exports, _emberLightTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    model: null,
    classNames: 'feature-table',
    columns: Ember.computed(function () {
      return [{
        label: 'FID',
        valuePath: 'fid',
        sortable: false,
        cellComponent: 'cell-fid',
        width: '30px'
      }, {
        label: 'Geometry',
        valuePath: 'geometry',
        cellComponent: 'cell-geom'
      }];
    }),
    table: Ember.computed('model', function () {
      return new _emberLightTable.default(this.get('columns'), this.get('model'), {
        enableSync: true
      });
    })
  });

  _exports.default = _default;
});