define("web-map/services/ol-geopservice", ["exports", "axios", "ol/Observable", "ol/Overlay.js", "ol", "ol/layer/Tile", "ol/source/OSM", "ol/Feature", "ol/geom/Point", "ol/geom", "ol/sphere", "ol/source", "ol/format", "ol/format/GML2", "ol/format/GML3", "ol/format/GML32", "ol/loadingstrategy", "ol/layer/Vector.js", "ol/source/Vector.js", "ol/style.js", "ol/source/Stamen.js", "ol/layer.js", "ol/source/TileJSON.js", "ol/control.js", "ol/interaction.js", "ol/format/WKT.js", "ol/control/MousePosition.js", "ol/coordinate.js", "ol/proj.js", "proj4", "ol/proj/proj4.js", "ol/extent.js", "ol/source/Image", "ol/layer/Image", "ol/source/ImageWMS", "ol/tilegrid/WMTS", "ol/format/WMTSCapabilities", "ol/source/WMTS", "ol/size", "jquery", "web-map/config/environment"], function (_exports, _axios, _Observable, _Overlay, _ol, _Tile, _OSM, _Feature, _Point, _geom, _sphere, _source, _format, _GML, _GML2, _GML3, _loadingstrategy, _Vector, _Vector2, _style, _Stamen, _layer, _TileJSON, _control, _interaction, _WKT, _MousePosition, _coordinate, proj, _proj2, _proj3, _extent, _Image, _Image2, _ImageWMS, _WMTS, _WMTSCapabilities, _WMTS2, olSize, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import Polygon from 'ol/geom/Polygon';
  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    map: null,
    layers: null,
    initialExtent: null,
    initActiveLayers: null,
    defaultProjection: null,
    defaultCenter: null,
    rsoCenter: null,
    wgs84Center: null,
    initLayersG: null,
    initLayersTRSO: null,
    initLayersWRSO: null,
    initLayersRSODigital: null,
    vectorSource: null,
    featureVectorSource: null,
    vectorLayer: null,
    featureVectorLayer: null,
    testCoordinates: null,
    firstLoaded: false,
    // flag to indicate first time loaded by browser
    isLoading: false,
    // flag to indicate service is loading data for popup
    measureSource: null,
    // measuring tool array to store measuresource
    measureVectorLayer: null,
    // measuring tool array to store measurevectorlayer
    measureOutOverlay: null,

    // measuring tool array to store measureoutputoverlay

    /**
     * init() now basically doing nothing
     */
    init() {
      console.log('ol geop service initialized');

      this._super(...arguments);
    },

    /**
     * moved the code from init to promiseInit, because need to wait the returned data from API
     * default to Public (roleid = 1) if no parameter pass in
     */
    async promiseInitRSODigital(is_internal = false, srs_init = '5247', layer_appid = "rso_digital") {
      console.log('ol geop service init RSO Digital layers');

      if (!this.cachedMaps) {
        Ember.set(this, 'cachedMaps', {});
      }

      this._super(...arguments);

      _proj2.default.defs("EPSG:29873", "+proj=omerc +lat_0=4 +lonc=115 +alpha=53.31582047222222 +k=0.99984 +x_0=590476.87 +y_0=442857.65 +gamma=53.13010236111111 +ellps=evrstSS +towgs84=-679,669,-48,0,0,0,0 +units=m +no_defs");

      _proj2.default.defs("EPSG:5247", "+proj=omerc +lat_0=4 +lonc=115 +alpha=53.31580995 +k=0.99984 +x_0=0 +y_0=0 +no_off +gamma=53.13010236111111 +ellps=GRS80 +units=m +no_defs");

      _proj2.default.defs("EPSG:32650", "+proj=utm +zone=50 +datum=WGS84 +units=m +no_defs");

      _proj2.default.defs('EPSG:4326', "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees");

      (0, _proj3.register)(_proj2.default);
      const wgs84Projection = (0, proj.get)('EPSG:4326');
      const gdbd2009Projection = (0, proj.get)('EPSG:5247');
      const gdbd2009Center = (0, proj.transform)([114.7277, 4.5353], 'EPSG:4326', 'EPSG:5247');
      console.log('Projection test gdbd2009 114.7277, 4.5353 : ' + gdbd2009Center);
      console.log('Projection test gdbd2009 115, 4 : ' + (0, proj.transform)([115, 4], 'EPSG:4326', 'EPSG:5247'));
      const rsotimbalaiProjection = (0, proj.get)('EPSG:29873');
      const rsotimbalaiCenter = (0, proj.transform)([114.7277, 4.5353], 'EPSG:4326', 'EPSG:29873');
      console.log('Projection test rso timbalai 114.7277, 4.5353 : ' + rsotimbalaiCenter);
      console.log('Projection test rso timbalai 115, 4 : ' + (0, proj.transform)([115, 4], 'EPSG:4326', 'EPSG:29873'));
      this.set('defaultProjection', gdbd2009Projection);
      this.set('defaultCenter', gdbd2009Center);
      this.set('wgs84Center', [114.7277, 4.5353]);
      this.set('rsoCenter', rsotimbalaiCenter); //to deep clean up javascript object

      function emptyObject(objRef) {
        for (var key in objRef) {
          if (objRef.hasOwnProperty(key)) {
            delete objRef[key];
          }
        }
      }

      let api_endpoint = layer_appid === 'rso_digital' ? 'layersetuprsos' : 'layersetupgdts'; //getting all the layers from database 

      let url_layersetup = this.store.adapterFor('application').get('gpserverHost') + this.store.adapterFor('application').get('namespace') + "/".concat(api_endpoint, "/getLayerSetup");
      let response = await _axios.default.post(url_layersetup, {
        "appid": layer_appid
      }).catch(error => {
        console.log("An error occured while loading map layers, ".concat(error));
        alert("An error occured while loading map layers, ".concat(error));
      }); // console.log(response);
      //to deep clone the javascript object (jquery)

      let results = _jquery.default.extend(true, {}, response); //console.log(results);


      let layers1 = [];
      let projections = ['EPSG:5247', 'EPSG:29873', 'EPSG:4326'];
      let layer_arr = [];
      let layergroup_arr = [];
      let layergroup_objarr = {};
      /**
       * to loop by projections, and create 3 sets of layers with different projection
       */

      projections.forEach((proj, i) => {
        results.data.layers_result.forEach(layer => {
          let layer_obj = _jquery.default.extend(true, {}, layer);

          let layer_source_obj = _jquery.default.extend(true, {}, layer.source);

          if (layer_obj.layer_type === 'image') {
            // delete layer_obj.layer_type;
            delete layer_obj.source; // console.log(proj);

            let meter_per_unit;
            layer_source_obj.projection = {};

            if (proj === 'EPSG:5247') {
              layer_source_obj.projection = gdbd2009Projection; // unit = gdbd2009Projection.getUnits();

              meter_per_unit = gdbd2009Projection.getMetersPerUnit(); // console.log('gdbd unit');
              // console.log(unit);
              // console.log(meter_per_unit);
            } else if (proj === 'EPSG:29873') {
              layer_source_obj.projection = rsotimbalaiProjection; // unit = rsotimbalaiProjection.getUnits();

              meter_per_unit = rsotimbalaiProjection.getMetersPerUnit(); // console.log('rso unit');
              // console.log(unit);
              // console.log(meter_per_unit);
            } else if (proj === 'EPSG:4326') {
              layer_source_obj.projection = wgs84Projection; // unit = wgs84Projection.getUnits();

              meter_per_unit = wgs84Projection.getMetersPerUnit(); // console.log('wgs unit');
              // console.log(unit);
              // console.log(meter_per_unit);
            }

            const inchesPerMetre = 39.37;
            const dpi = 96;

            if (layer_obj.minScale && layer_obj.maxScale) {
              layer_obj.minResolution = layer_obj.minScale / (meter_per_unit * inchesPerMetre * dpi);
              layer_obj.maxResolution = layer_obj.maxScale / (meter_per_unit * inchesPerMetre * dpi);
            }

            layer_obj.visiType = ''; //console.log('layer_obj.group_name: ' +layer_obj.group_name);

            if (typeof layer_obj.group_name == 'undefined' || layer_obj.group_name == null || layer_obj.group_name === '') {
              layer_obj.source = new _ImageWMS.default(layer_source_obj);
              layer_arr.push(new _Image2.default(layer_obj)); //console.log('Not inside a layer group');             
            } else {
              if (Boolean(layergroup_objarr[layer_obj.group_name])) {} else {
                layergroup_objarr[layer_obj.group_name] = [];
              }

              layer_obj.source = new _ImageWMS.default(layer_source_obj);
              layergroup_objarr[layer_obj.group_name].push(new _Image2.default(layer_obj)); //console.log('Debug iterating layer group');
              //console.log(layergroup_objarr[layer_obj.group_name]);

              if (layer_obj.group_seq === 'end') {
                let lg11 = new _layer.Group({
                  title: layer_obj.group_name,
                  visible: true,
                  visiType: '',
                  layers: layergroup_objarr[layer_obj.group_name]
                });
                layer_arr.push(lg11);
                layergroup_objarr[layer_obj.group_name] = [];
              }
            }
            /* new LayerGroup({
              title: 'Layer group',
              layers: [
                vector
              ]
            }) */


            emptyObject(layer_source_obj);
            emptyObject(layer_obj);
          } else if (layer_obj.layer_type === 'tile') {
            delete layer_obj.source;
            console.log('layer_obj.group_name: ' + layer_obj.group_name);
            console.log(layer.source);

            if (typeof layer_obj.group_name == 'undefined' || layer_obj.group_name == null || layer_obj.group_name === '') {
              layer_obj.source = new _OSM.default(layer.source); //new TileLayer(layer_obj);

              layer_arr.push(new _Tile.default(layer_obj)); //console.log('Not inside a layer group');
            } else {
              if (Boolean(layergroup_objarr[layer_obj.group_name])) {} else {
                layergroup_objarr[layer_obj.group_name] = [];
              }

              console.log('Check layer_obj');
              console.log(layer_obj);
              let osm_source = '';

              if (layer_obj.title == 'Google Map (GDBD2009)') {
                let extent11 = [298469.22, 443468.67, 630678.53, 698468.74];
                gdbd2009Projection.setExtent(extent11);
                console.log('projection');
                console.log(gdbd2009Projection);
                let projectionExtent = gdbd2009Projection.getExtent();
                console.log(projectionExtent);
                let size = 5700; // size here is actually start resolution

                console.log((0, _extent.getWidth)(projectionExtent));
                console.log(size);
                let resolutions = new Array(20);
                let matrixIds = new Array(20);

                for (let z = 0; z < 20; ++z) {
                  // generate resolutions and matrixIds arrays for this WMTS
                  resolutions[z] = size / Math.pow(2, z);
                  matrixIds[z] = z;
                }

                console.log(resolutions);
                console.log(matrixIds); // NOTE: dynamic config of WMTS require the URL to the WMTS service, the starting resolution "5700", 
                // the number of zoom levels "20", the projection extent, the SRS
                // tileSize is not important, 

                layer_obj.source = new _WMTS2.default({
                  url: "http://gp2gmap.w3o.io/wmts/gm/{TileMatrixSet}/{TileMatrix}/{TileCol}/{TileRow}.png",
                  layer: 'gm',
                  matrixSet: 'gdbd',
                  format: 'image/png',
                  //projection: gdbd2009Projection,
                  tileGrid: new _WMTS.default({
                    origin: (0, _extent.getTopLeft)(projectionExtent),
                    resolutions: resolutions,
                    matrixIds: matrixIds
                  }),
                  style: 'default',
                  wrapX: false,
                  requestEncoding: "REST"
                });
                layergroup_objarr[layer_obj.group_name].push(new _Tile.default(layer_obj));
              } else if (layer_obj.title == 'OpenStreetMap (GDBD2009)') {
                let extent11 = [298469.22, 443468.67, 630678.53, 698468.74];
                gdbd2009Projection.setExtent(extent11);
                console.log('projection');
                console.log(gdbd2009Projection);
                let projectionExtent = gdbd2009Projection.getExtent();
                console.log(projectionExtent); //looks like this size formula to get resolutions is not correct
                //let size = getWidth(projectionExtent) / 256;

                let size = 5700; // size here is actually start resolution

                console.log((0, _extent.getWidth)(projectionExtent));
                console.log(size);
                let resolutions = new Array(20);
                let matrixIds = new Array(20);

                for (let z = 0; z < 20; ++z) {
                  // generate resolutions and matrixIds arrays for this WMTS
                  resolutions[z] = size / Math.pow(2, z);
                  matrixIds[z] = z;
                }

                console.log(resolutions);
                console.log(matrixIds); // NOTE: dynamic config of WMTS require the URL to the WMTS service, the starting resolution "5700", 
                // the number of zoom levels "20", the projection extent, the SRS
                // tileSize is not important, 

                layer_obj.source = new _WMTS2.default({
                  url: "http://gp2mp2.w3o.io/wmts/osm/{TileMatrixSet}/{TileMatrix}/{TileCol}/{TileRow}.png",
                  layer: 'osm',
                  matrixSet: 'gdbd',
                  format: 'image/png',
                  //projection: gdbd2009Projection,
                  tileGrid: new _WMTS.default({
                    origin: (0, _extent.getTopLeft)(projectionExtent),
                    resolutions: resolutions,
                    matrixIds: matrixIds
                  }),
                  style: 'default',
                  wrapX: false,
                  requestEncoding: "REST"
                });
                layergroup_objarr[layer_obj.group_name].push(new _Tile.default(layer_obj));
              } else {
                osm_source = layer.source;
                layer_obj.source = new _OSM.default(osm_source);
                layergroup_objarr[layer_obj.group_name].push(new _Tile.default(layer_obj));
              } //console.log('Debug iterating layer group');


              if (layer_obj.group_seq === 'end') {
                let lg11 = new _layer.Group({
                  title: layer_obj.group_name,
                  layers: layergroup_objarr[layer_obj.group_name]
                });
                layer_arr.push(lg11);
                layergroup_objarr[layer_obj.group_name] = [];
              }
            }

            emptyObject(layer_obj);
          }
        });

        if (proj === 'EPSG:5247') {
          this.set('initLayersRSODigital', layer_arr);
        } else if (proj === 'EPSG:29873') {
          this.set('initLayersTRSO', layer_arr);
        } else if (proj === 'EPSG:4326') {
          this.set('initLayersWRSO', layer_arr);
        }

        layer_arr = [];
        layergroup_objarr = {};
      }); //console.log(this.get('initLayersG'));
      //console.log(this.get('initLayersTRSO'));

      console.log(this.get('initLayersRSODigital'));
      console.log('finally!!!!!!'); //TODO

      if (srs_init === '5247') {
        this.set('defaultProjection', gdbd2009Projection);
        this.set('defaultCenter', gdbd2009Center);
        this.set('initActiveLayers', this.get('initLayersRSODigital'));
      } else if (srs_init === '29873') {
        this.set('defaultProjection', rsotimbalaiProjection);
        this.set('defaultCenter', rsotimbalaiCenter);
        this.set('initActiveLayers', this.get('initLayersTRSO'));
      } else if (srs_init === '4326') {
        this.set('defaultProjection', wgs84Projection);
        this.set('defaultCenter', [114.7277, 4.5353]);
        this.set('initActiveLayers', this.get('initLayersWRSO'));
      }
    },

    _setProjection(code, name, proj4def, bbox) {
      console.log("Inside ol-geopservice set projection = " + code);
      console.log("Inside ol-geopservice set projection = " + name);
      console.log(proj4def);
      console.log(bbox);
      let map = this.get('map');

      if (code === null || name === null || proj4def === null || bbox === null) {
        map.setView(new _ol.View({
          projection: 'EPSG:3857',
          center: [0, 0],
          zoom: 1
        }));
        return 0;
      }

      var newProjCode = 'EPSG:' + code;

      _proj2.default.defs(newProjCode, proj4def);

      (0, _proj3.register)(_proj2.default);
      var newProj = (0, proj.get)(newProjCode);
      var fromLonLat = (0, proj.getTransform)('EPSG:4326', newProj); // very approximate calculation of projection extent

      var extent = (0, _extent.applyTransform)([bbox[1], bbox[2], bbox[3], bbox[0]], fromLonLat);
      console.log(extent);
      newProj.setExtent(extent);
      console.log(newProj);
      let newView = new _ol.View({
        projection: newProj
      });
      console.log(map.getView().getProjection());
      /* let clayer1 = map.getLayers();
      clayer1.forEach(layer => {
        console.log(layer);
        map.removeLayer(layer);
      });*/
      // wrong way as map rebuild layer index after each removal

      map.setView(newView); //newView.fit(extent);
      // if (code == '29873') {
      //   newView.setCenter(this.get('rsoCenter'));
      //   newView.setZoom(5);
      // } else if (code == '5247') {
      //   newView.setCenter(this.get('defaultCenter'));
      //   newView.setZoom(3);
      // }

      if (code == '4326') {
        newView.setCenter(this.get('wgs84Center'));
        newView.setZoom(10);
      } else if (code == '29873') {
        newView.setCenter(this.get('rsoCenter'));
        newView.setZoom(5);
      } else if (code == '5247') {
        newView.setCenter(this.get('defaultCenter'));
        newView.setZoom(3);
      }
      /* newView.fit(extent, map.getSize(), {
        constrainResolution: false
      }); */


      return 1;
    },

    searchEPSG(self, query) {
      console.log("Inside ol-geopservice srs query = " + query);
      const new_key = 'mqLMhcmbmHNjvwbbknpO';
      const new_url = "https://api.maptiler.com/coordinates/search/".concat(query, ".json?key=").concat(new_key, "&exports=true");
      fetch(new_url).then(function (response) {
        return response.json();
      }).then(function (json) {
        var results = json['results'];
        console.log("Inside ol-geopservice srs query result = " + results);

        if (results && results.length > 0) {
          for (var i = 0, ii = results.length; i < ii; i++) {
            var result = results[i];

            if (result) {
              //var code = result['code'];
              var code = result['id']['code'];
              var name = result['name']; //var proj4def = result['proj4'];

              var proj4def = result['exports']['proj4'];
              var bbox = result['bbox'];

              if (code && proj4def && proj4def.length > 0 && bbox && bbox.length == 4) {
                let abc = self._setProjection(code, name, proj4def, bbox);

                return;
              }
            }
          }
        }

        let abc = this._setProjection(null, null, null, null);
      });
    },

    calcMapScale(map, dpi) {
      var unit = map.getView().getProjection().getUnits();
      var resolution = map.getView().getResolution();
      var inchesPerMetre = 39.37;
      return resolution * proj.METERS_PER_UNIT[unit] * inchesPerMetre * dpi;
    },

    debugMapScale() {
      let map = this.get('map');
      var extent = map.getView().calculateExtent(map.getSize());
      var bottomLeft = (0, proj.toLonLat)((0, _extent.getBottomLeft)(extent));
      var topRight = (0, proj.toLonLat)((0, _extent.getTopRight)(extent));
      var res1 = map.getView().getResolution();
      var zoom = map.getView().getZoom(); //var dpi = 25.4 / 0.28;

      var dpi = 96;
      var cScale = this.calcMapScale(map, dpi);
      var units = map.getView().getProjection().getUnits();
      var mpu = proj.METERS_PER_UNIT[units];
      var res = cScale / (mpu * 39.37 * dpi);
      console.log("Map resolution = " + res1);
      console.log("Map zoom = " + zoom);
      console.log("Map units = " + units);
      console.log("Map mpu = " + mpu);
      console.log("Map calc res = " + res);
      console.log("Map calc scale = " + cScale);
    },

    _calcMapResolution(map, scaleVal, dpiVal) {
      var units = map.getView().getProjection().getUnits();
      var mpu = proj.METERS_PER_UNIT[units];
      var res = scaleVal / (mpu * 39.37 * dpiVal);
      return res;
    },

    changeMapScale(self, scvalue) {
      let map = this.get('map');

      let res1 = self._calcMapResolution(map, scvalue, 96);

      console.log("Scale Input the resolution = " + res1);
      let view1 = map.getView();
      view1.setResolution(res1);
    },

    getInitLayers(code) {
      let res1 = null;

      if (code == '5247') {
        res1 = this.get('initLayersRSODigital');
      } else if (code == '29873') {
        res1 = this.get('initLayersTRSO');
      } else {
        res1 = this.get('initLayersWRSO');
      }

      return res1;
    },

    getOlayers(map) {
      const store = this.get('store');
      console.log('ol-geopservice getOlayers...'); // this is likely the cause of the error - cannot fetch layers inside LG

      console.log(map.getLayers().getArray().concat());
      const layers = map.getLayers().getArray().concat().reverse().map(function (layer) {
        if (layer.getLayers) {
          let layerC = store.createRecord('ol-layer', {
            layer
          });
          const layersArray = layer.getLayers().getArray();
          const layersCArr = layersArray.map(layer => store.createRecord('ol-layer', {
            layer
          })); //const layersCArr = [];

          let compositeLayer = layerC;
          compositeLayer.group_array = layersCArr; //compositeLayer.group_class = layerC;
          //return {group_class: layerC, group_array: layersCArr};

          return compositeLayer;
        } else {
          return store.createRecord('ol-layer', {
            layer
          });
        }
      });
      console.log('ol-geopservice getOlayers... after process by...');
      console.log(layers);
      this.set('layers', layers);
      return layers;
    },

    clearSearchResults() {
      console.log('Clear Search Results');
      const map = this.get('map');
      let layers1 = map.getLayers();
      layers1.forEach(element => {//console.log(element);
        //console.log(element.getProperties());
        //console.log(element.get('title'));
      });
    }

  });

  _exports.default = _default;
});