define("web-map/controllers/momap", ["exports", "web-map/mixins/w3o-users-roles/invalidate-session", "web-map/utils/get-cookie", "axios"], function (_exports, _invalidateSession, _getCookie, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_invalidateSession.default, {
    olmap2svc: Ember.inject.service('ol-mapservice'),
    olgp2svc: Ember.inject.service('ol-geopservice'),
    authObject: Ember.inject.service('session'),
    componentRef: null,
    canInitLayers: false,
    canReInitLayers: false,
    // not being used now
    canClearResults: false,
    canClearRSOResults: false,
    canClearGeodeticResults: false,
    canLoadAppLayers: false,
    queryParams: ['rso_type'],
    rso_type: null,
    geoRoleId: 1,
    is_internal: false,
    fp_status: false,

    async init() {
      this._super(...arguments); //create cookies for mobile use


      document.cookie = "isMobile=Yes";

      if ((0, _getCookie.default)('public_disclaimer') === "") {
        document.cookie = "public_disclaimer=No";
      }

      if ((0, _getCookie.default)('public_disclaimer') === "No") {
        window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/disclaimermap-mobile"));
      } // document.cookie = "registered_disclaimer=No"; 


      let cookies = document.cookie;
      console.log((0, _getCookie.default)('isMobile'));
      console.log((0, _getCookie.default)('public_disclaimer')); // console.log(getCookie('registered_disclaimer'));
      //getting the user role from API if the user logged in

      let userid = this.get('authObject.data.user.id');

      if (this.get('authObject.isAuthenticated')) {
        this.set('is_internal', this.get('authObject.data.user.is_internal'));
        let url_userrole = this.store.adapterFor('application').get('serverHost') + this.store.adapterFor('application').get('namespace') + '/layersetups/getUserRole';
        let response = await _axios.default.post(url_userrole, {
          userid
        }).catch(error => {
          console.log("An error occured while getting user role, ".concat(error));
          alert("An error occured while getting user role, ".concat(error));
        }); //the login user is not part of registered user of Geoportal

        if (response.data.registered === false) {
          this.set('geoRoleId', 1);
        } else {
          this.set('geoRoleId', response.data.roleid);
        }
      }
    },

    actions: {
      async initMap() {
        if ((0, _getCookie.default)('public_disclaimer') === "Yes") {
          const olmap2svc = this.get('olmap2svc');
          const olgp2svc = this.get('olgp2svc');
          await olmap2svc.promiseInit(this.get('geoRoleId'));
          olmap2svc.setMobileMode('Yes');
          const map = olmap2svc.getOmap();
          const initialExtent = olmap2svc.getOinitialextent(map);
          const layers = olmap2svc.getOlayers(map);
          this.setProperties({
            map: map,
            initialExtent: initialExtent,
            layers: layers,
            isInitialized: true
          });
          await olgp2svc.promiseInitRSODigital(this.get('is_internal'));
          console.log('hoichong map controller initMap done');
        }
      },

      closePopOver() {
        let elem = document.getElementById('popup');
        $(elem).popover('dispose');
      },

      finishMapSetup(element) {
        const map = this.get('map');
        console.log("Inside map controller, map is: " + map);
        map.setTarget(element); //const initialExtent = this.get('initialExtent')
        //if (initialExtent) map.getView().fit(initialExtent, map.getSize())

        this.set('canInitLayers', true);
      },

      reloadMapLayers(code) {
        const map = this.get('map');
        const olmap2svc = this.get('olmap2svc');
        console.log("Inside map controller, doing a reloadMapLayers");
        this.set('canReInitLayers', true); // correct way to remove all layers from map

        let clayers1 = [...map.getLayers().getArray()];
        clayers1.forEach(layer => map.removeLayer(layer));
        let alayer1 = olmap2svc.getInitLayers(code);
        alayer1.forEach(layer => {
          //console.log(layer);
          map.addLayer(layer);
        });
        const layers = olmap2svc.getOlayers(map);
        this.set('layers', layers);
        this.set('canClearResults', true);
        this.set('canClearRSOResults', true); //console.log("Inside map controller, canClearResults: " + this.get('canClearResults')); 
      },

      async loadAppLayers(code, type) {
        this.set('canLoadAppLayers', true);
        console.log("Inside map controller, doing a loadAppLayers");
        const map = this.get('map');
        const olgp2svc = this.get('olgp2svc');
        await olgp2svc.promiseInitRSODigital(this.get('is_internal'), '5247', type);
        this.set('canReInitLayers', true); // correct way to remove all layers from map

        let clayers1 = [...map.getLayers().getArray()];
        clayers1.forEach(layer => map.removeLayer(layer));
        let alayer1 = olgp2svc.getInitLayers(code);
        alayer1.forEach(layer => {
          //console.log(layer);
          map.addLayer(layer);
        });
        const layers = olgp2svc.getOlayers(map);
        this.set('layers', layers);
        this.set('canClearResults', true); //console.log("Inside map controller, canClearResults: " + this.get('canClearResults'));       
      },

      resetClearResults() {
        this.set('canClearResults', false);
      },

      resetClearRSOResults() {
        this.set('canClearRSOResults', false);
      },

      resetClearGeodeticResults() {
        this.set('canClearGeodeticResults', false);
      },

      registerComponent(componentRef) {
        this.set('componentRef', componentRef);
      },

      deregisterComponent() {
        this.set('componentRef', null);
      },

      callCompleteMapSetup() {
        this.get('componentRef').completeMapSetup();
      },

      /* Above code finishMapSetup is to fix the problem of synching the Map object to be available for setTarget to element of the ol-map-panel,  
      in a simplistic scenario, where all map visual elements are within the map then ol-map-panel can setTarget, but because we need to set the 
      X, Y coordinates to outside of the map, the code to trigger the initMap has to be in the map-info-bar component, and therefore a timing issue arise, 
      so this finishMapSetup method (map.setTarget) is here, so that this controller can do initMap and finishMapSetup in correct sequence. 
      All this in order to make OpenLayers and Emberjs work well together. */

      /* registerComponent and deregisterComponent is an attempt to setup something where One component can call Another component */
      invalidateSession() {
        this.invalidateSession();
      },

      authorize() {
        this.store.adapterFor('application').authorizeToken();
      },

      toggleFunctionPanel() {
        let fp_status1 = this.get('fp_status');
        const elementA = document.querySelector('#functionPanel21');
        const olMapservice = this.get('olmap2svc');
        console.log('hoichong stmap map-info-bar YOU just CLICK on ATOM fp_status1: ' + fp_status1);

        if (Boolean(fp_status1)) {
          console.log('Hide FP..');
          fp_status1 = !fp_status1;
          olMapservice.animateCSS('#functionPanel21', 'bounceOutLeft', function () {
            elementA.style.display = "none";
          });
        } else {
          console.log('Show FP..');
          fp_status1 = !fp_status1;
          elementA.style.display = "block";
          olMapservice.animateCSS('#functionPanel21', 'bounceInLeft', function () {
            elementA.style.display = "block";
          });
        }

        console.log('final change to ' + fp_status1);
        this.set('fp_status', fp_status1);
      }

    }
  });

  _exports.default = _default;
});