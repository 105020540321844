define("web-map/validators/unique-searchvalue", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const UniqueSearchvalue = _base.default.extend({
    validate(value, options, model, attribute) {
      console.log('Inside unique-searchvalue validator...');
      console.log(attribute);
      console.log(value);
      console.log(model);
      console.log(options);
      let message = '';
      console.log(model.searchtype);

      switch (model.searchtype.name) {
        case 'Lots':
          message = 'Note: Input Numbers only';
          break;

        case 'TOL':
          message = 'Note: Input Numbers only';
          break;

        case 'Gazette':
          message = 'Note: Input Numbers only';
          break;

        case 'Place':
          message = 'Note: Input the name of the Place, can be partial name';
          break;

        case 'Kampung':
          message = 'Note: Input the name of the Kampung, can be partial name';
          break;

        case 'GeoCoordinates':
          message = 'Note: Geo Coordinates search format is "Northing, Easting" eg:  542150, 581524 for GDBD2009 and RSO Timbalai / 4.948, 114.960  for WGS84';
          break;

        default:
          message = '';
      }

      ;
      if (message == '') return true;else return message;
    }

  });

  UniqueSearchvalue.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor(attribute, options) {
      return ['model.searchtype'];
    }

  });
  var _default = UniqueSearchvalue;
  _exports.default = _default;
});