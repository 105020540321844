define("web-map/mixins/table-common", ["exports", "ember-light-table"], function (_exports, _emberLightTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    page: 0,
    limit: 10,
    dir: 'asc',
    sort: 'firstName',
    //isLoading: computed.oneWay('fetchRecords.isRunning'),
    canLoadMore: true,
    enableSync: true,

    /* model1: [{
      objectnum: '1221',
      objectfield1: '12/122',
      objectfield2: 'BM'
    }], */
    meta: null,
    columns: null,
    table: null,

    init() {
      this._super(...arguments);

      console.log('table-common doing init');

      let table = _emberLightTable.default.create({
        columns: this.get('columns'),
        rows: this.get('model1')
      }, {
        enableSync: this.get('enableSync')
      });

      let sortColumn = table.get('allColumns').findBy('valuePath', this.get('sort')); // Setup initial sort column

      if (sortColumn) {
        sortColumn.set('sorted', true);
      }

      this.set('table', table);
    },

    /* fetchRecords: task(function*() {
      let records = yield this.get('store').query('user', this.getProperties(['page', 'limit', 'sort', 'dir']));
      this.get('model').pushObjects(records.toArray());
      this.set('meta', records.get('meta'));
      this.set('canLoadMore', !isEmpty(records));
    }).restartable(), */
    fetchRecords() {},

    updateModel(newm) {
      console.log('table-common mixin update model');
      console.log(newm);
      this.set('model1', newm);
    },

    actions: {
      onScrolledToBottom() {
        if (this.get('canLoadMore')) {
          this.incrementProperty('page'); //this.get('fetchRecords').perform();
        }
      },

      onColumnClick(column) {
        if (column.sorted) {
          this.setProperties({
            dir: column.ascending ? 'asc' : 'desc',
            sort: column.get('valuePath'),
            canLoadMore: true,
            page: 0
          }); //this.get('model1').clear();
        }
      }

    }
  });

  _exports.default = _default;
});