define("web-map/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "web-map/mixins/w3o-users-roles/check-session-activity", "web-map/mixins/w3o-users-roles/set-session-user"], function (_exports, _applicationRouteMixin, _checkSessionActivity, _setSessionUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _checkSessionActivity.default, _setSessionUser.default, {
    sumApp: 0,
    authObject: Ember.inject.service('session'),
    sessionFull: 0,
    sessionCurrent: 0,
    isSessionEmpty: 0,
    promise: Ember.RSVP.Promise,
    model: async function () {
      // Call function on adapters/application to set authorization headers (access token)
      this.store.adapterFor('application').authorizeToken();

      if (!$.isEmptyObject(this.get('authObject.data.authenticated'))) {
        // Set user data that can be modified (not immutable)
        let response = await this.setSessionUser();

        if (response && response.forceChangePassword) {
          this.transitionTo("change-password");
          return null;
        }

        if (response) {
          this.generateAppSession();
          return this.get('promise');
        }

        return false;
      } else {
        console.log('here, reset authObject.data');
        this.get('authObject').set('data.user', null);
      }

      return false;
    },

    setupController(controller, model) {
      Ember.run.schedule('afterRender', () => {
        this.checkSessionActivity();
      });

      if (model) {
        controller.set('authorizationCompleted', true);
      } // fetch localization here


      controller.set("showAppLoading", false);
    },

    generateAppSession() {
      if (this.get('authObject.data.user')) {
        $.ajax({
          url: '/api/v1/applications/getAppSession',
          type: 'GET',
          data: {
            userId: this.get('authObject.data.user').id
          }
        }).then(response => {
          response.appsessions.forEach(appsession => {
            if (appsession.id) {
              this.store.findRecord(appsession.modelName, appsession.id).then(result => {
                this.set(appsession.initializerName, result);
              });
            }
          });
        });
      }
    },

    actions: {
      invalidateSession() {
        this.invalidateSession();
      },

      changeLanguage(value) {
        console.log("CHANGE LANGUAGE ROUTE");
        console.log(value);
        this.set('authObject.data.locale', value); // this.controller.set('currentLanguage', value);

        window.location.reload(true);
      }

    }
  });

  _exports.default = _default;
});