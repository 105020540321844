define("web-map/components/map-search", ["exports", "ember-cp-validations", "ember-light-table", "ol/style.js", "ol/format/GeoJSON.js", "ol/layer/Vector.js", "ol/source/Vector.js", "ol/Feature", "ol/geom/Polygon", "ol/geom/Point", "ol/geom/LinearRing"], function (_exports, _emberCpValidations, _emberLightTable, _style, _GeoJSON, _Vector, _Vector2, _Feature, _Polygon, _Point, _LinearRing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import TableCommon from '../mixins/table-common';
  const Validations = (0, _emberCpValidations.buildValidations)({
    searchvalue: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('unique-searchvalue', {
      showSuggestions: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    store: Ember.inject.service(),
    columns: Ember.computed('searchtype', function () {
      let col1 = '';
      let col2 = '';

      switch (this.get('searchtype.name')) {
        case 'Lots':
        case 'Lots_exact':
          col1 = 'LOT Num';
          col2 = 'RSO';
          break;

        case 'Lots_EDR':
          col1 = 'EDR';
          col2 = 'RSO';
          break;

        case 'TOL':
          col1 = 'TOL Num';
          col2 = 'RSO';
          break;

        case 'Gazette':
        case 'Gazette_exact':
          col1 = 'Gazette Num';
          col2 = 'Owner';
          break;

        case 'Place':
          col1 = '';
          col2 = 'Name';
          break;

        case 'Kampung':
          col1 = 'Kg Name';
          col2 = 'Mukim';
          break;

        case 'Mukim':
          col1 = 'Mukim';
          col2 = 'District Code';
          break;

        case 'GeoCoordinates':
          col1 = '';
          col2 = '';
          break;

        default:
          console.log('Error Encountered in Search Case Switch');
      }

      ;
      return [{
        label: col1,
        valuePath: 'objectnum',
        responsiveHidden: false,
        //must be false
        breakpoints: ['mobile', 'tablet', 'desktop'] //width: ($(window).width() * 0.23 * 0.3) + 'px'

      }, {
        label: col2,
        valuePath: 'objectfield1',
        responsiveHidden: false,
        breakpoints: ['mobile', 'tablet', 'desktop'] //width: ($(window).width() * 0.23 * 0.7) + 'px'

      }];
    }),
    table: Ember.computed('model1', function () {
      return _emberLightTable.default.create({
        columns: this.get('columns'),
        rows: this.get('model1')
      });
    }),
    olMapservice: Ember.inject.service('ol-mapservice'),
    // stoptions: ['Lots', 'TOL', 'Gazette', 'Place', 'Kampung'],
    stoptions: [{
      name: 'Lots',
      label: 'Lot (Partial Search)'
    }, {
      name: 'Lots_exact',
      label: 'Lot'
    }, {
      name: 'Lots_EDR',
      label: 'EDR (Partial Search)'
    }, {
      name: 'Gazette',
      label: 'Gazette (Partial Search)'
    }, {
      name: 'Gazette_exact',
      label: 'Gazette'
    }, {
      name: 'TOL',
      label: 'TOL'
    }, {
      name: 'Place',
      label: 'Place'
    }, {
      name: 'Kampung',
      label: 'Kampung'
    }, {
      name: 'Mukim',
      label: 'Mukim'
    }, {
      name: 'GeoCoordinates',
      label: 'Geo Coordinates'
    }],
    // searchtype: 'Lots',
    searchtype: {
      name: 'Lots',
      label: 'Lot (Partial Search)'
    },
    searchvalue: '',
    ffeatures: [],
    model1: [],
    usedVectorSource: null,
    usedVectorLayer: null,
    usedMarkerVectorLayer: null,

    /* model1: [{
      objectnum: '1221',
      objectfield1: '12/122',
      objectfield2: 'BM'
    }, {
      objectnum: '1221',
      objectfield1: '12/122',
      objectfield2: 'BM'
    }, {
      objectnum: '1221',
      objectfield1: '12/122',
      objectfield2: 'BM'
    }], */
    isResizeCapture: false,
    canClearResults: false,
    doClearResults: Ember.computed(function () {
      const olMapservice = this.get('olMapservice');
      olMapservice.clearSearchResults();
      console.log('Doing Clear Results');
      return 1;
    }),

    init() {
      this._super(...arguments);
    },

    didInsertElement() {},

    observer: Ember.observer('canClearResults', function () {
      console.log('trying to clear search results after srs change');

      if (this.canClearResults) {
        console.log('can clear search results after srs change');
        this.set('model1', []); //this.set('canClearResults', false);
        //   this.resetClearResults();
      }

      console.log(this.get('canClearResults'));
    }),
    actions: {
      onRowClick(row) {
        const olMapservice = this.get('olMapservice');
        let vs1 = olMapservice.get('featureVectorSource');
        let vla1 = olMapservice.get('featureVectorLayer');
        let map = olMapservice.get('map');
        console.log('onRowClick');
        console.log(row);
        console.log(row.get('content'));
        console.log(row.get('objectnum')); //console.log(row.get('geom'));

        let pol1 = row.get('geom');

        let _c_srs = map.getView().getProjection().getCode();

        if (row.get('objectfield2') == 'Place') {
          let posStr = pol1.Point._content_.pos;
          console.log(posStr);
          let lrr2 = posStr.trim().split(" ");
          console.log(lrr2); //olMapservice.plotOnePoint(map, lrr2);

          let lrarr1 = lrr2.map(pos => {
            let coordfloat = parseFloat(pos);
            return coordfloat;
          }); // lrarr1 must be in number format, using string will trigger error about finite number

          map.getView().setCenter(lrarr1);

          if (_c_srs == 'EPSG:4326') {
            map.getView().setResolution(0.000005948652404239215);
          } else {
            map.getView().setResolution(0.6614596562526458386250105833545);
          }
        } else {
          console.log("Navigating the Surface object"); // check for multipolygons eg: mukim search sengkurong
          // pol1 is geom 

          let lrr1 = null;

          if (pol1.hasOwnProperty('MultiSurface')) {
            let tmarr1 = pol1.MultiSurface._content_.surfaceMember;
            lrr1 = tmarr1[0].Surface._content_.patches.PolygonPatch.exterior.LinearRing;
          } else {
            lrr1 = pol1.Surface._content_.patches.PolygonPatch.exterior.LinearRing;
          }

          console.log(lrr1);
          let poss1 = lrr1._content_.pos;
          console.log(poss1);
          let lrarr1 = poss1.map(function (pos) {
            let posarr = pos.split(' '); //console.log(posarr);

            let posarrfloat = posarr.map(function (coord) {
              let coordfloat = parseFloat(coord);
              return coordfloat;
            }); //console.log(posarrfloat);
            // damn , got to be very careful in how to process the arrays and arrays structure

            let val1 = posarrfloat;
            return val1;
          });
          console.log(lrarr1); //lrarr1.pop();

          let lrrobj1 = new _LinearRing.default(lrarr1);
          console.log(lrrobj1);
          let poly1 = new _Polygon.default([lrarr1]);
          console.log(poly1); //poly1.appendLinearRing(lrrobj1);

          const targetFeature = new _Feature.default({
            geometry: poly1
          });
          targetFeature.setStyle(new _style.Style({
            stroke: new _style.Stroke({
              color: '#0000ff',
              width: 3
            }),
            fill: new _style.Fill({
              color: 'rgba(0, 0, 255, 0.2)'
            })
          }));
          var geojsonObject = {
            'type': 'FeatureCollection',
            'crs': {
              'type': 'name',
              'properties': {
                'name': 'EPSG:29873'
              }
            },
            'features': [{
              'type': 'Feature',
              'geometry': {
                'type': 'Polygon',
                'coordinates': [lrarr1]
              }
            }]
          };
          console.log(geojsonObject); //'coordinates': [[[-5e6, -1e6], [-4e6, 1e6], [-3e6, -1e6]]]
          //console.log('polygon linear ring count: ' + pol1.getLinearRingCount());
          //console.log(pol1.getLinearRing(0));
          //console.log(pol1.getLinearRing(0).getType());

          let feGeoJson = new _GeoJSON.default({
            dataProjection: 'EPSG:29873',
            featureProjection: 'EPSG:29873'
          });
          console.log(feGeoJson);
          console.log(feGeoJson.readProjection(geojsonObject));

          if (Boolean(this.get('usedVectorSource'))) {
            let uvs = this.get('usedVectorSource');
            uvs.clear();
            this.set('usedVectorSource', null);
          }

          let feVectorSource = new _Vector2.default({
            features: feGeoJson.readFeatures(geojsonObject),
            useSpatialIndex: false
          });
          console.log(feVectorSource);
          this.set('usedVectorSource', feVectorSource); //feVectorSource.addFeature(targetFeature);
          //vla1.setSource(vs1);
          //console.log(vla1.getStyle());

          if (Boolean(this.get('usedVectorLayer'))) {
            map.removeLayer(this.get('usedVectorLayer'));
          }

          ;
          var layer22 = new _Vector.default({
            title: 'SearchLayer',
            source: feVectorSource,
            zIndex: 200,
            style: new _style.Style({
              stroke: new _style.Stroke({
                color: 'red',
                width: 3
              }),
              fill: new _style.Fill({
                color: 'rgba(0, 0, 255, 0.2)'
              })
            })
          });
          map.addLayer(layer22);
          this.set('usedVectorLayer', layer22);
          feVectorSource.refresh();
          let polycoord1 = poly1.getFirstCoordinate();
          console.log('testing..........');
          console.log(polycoord1);
          map.getView().setCenter(polycoord1);

          if (row.get('objectfield2') == 'Kampung') {
            if (_c_srs == 'EPSG:4326') {
              map.getView().setResolution(0.00002379460961695686 * 4);
            } else {
              map.getView().setResolution(2.645838625 * 4);
            }
          } else if (row.get('objectfield2') == 'Mukim') {
            if (_c_srs == 'EPSG:4326') {
              map.getView().setResolution(0.00002379460961695686 * 10);
            } else {
              map.getView().setResolution(2.645838625 * 10);
            }
          } else {
            if (_c_srs == 'EPSG:4326') {
              map.getView().setResolution(0.000005948652404239215);
            } else {
              map.getView().setResolution(0.6614596562526458386250105833545);
            }
          } //map.getView().setResolution(0.6614596562526458386250105833545);
          //map.getView().changed();
          //console.log(map.getLayers());
          //console.log(layer22.getSource().getFeatures());
          //console.log(layer22.getSource().getProjection());
          //console.log(poly1.getLinearRing(0));

        } //Automatic close the left panel if user is in mobile mode


        if (this.get('isMobile') === 'Yes') {
          this.toggleFunctionPanel();
        }
      },

      setSearchType(value) {
        //const olMapservice = this.get('olMapservice');
        //this.set('canClearResults', true);
        this.set('model1', []);
        this.set('searchtype', value);
        console.log('map search type : ' + value);
        /* window.addEventListener("resize", function () {
          console.log('Window Resize Triggered via addEventListener');
        }); */
        // captured by jquery above
      },

      async doSearch() {
        //this.set('canClearResults', true);
        let self = this;
        this.set('model1', []);
        let sv1 = this.get('searchvalue');
        let st1 = this.get('searchtype');
        let wfs1 = '';
        let layer1 = '';
        let attr1 = '';
        let str1 = '';
        const olMapservice = this.get('olMapservice');
        console.log('map search value : ' + sv1 + ' search type ' + st1.name);

        switch (st1.name) {
          case 'Lots':
            //swfs1,slayer1,ssrs1,sattr1,sstr1
            wfs1 = "".concat(self.store.adapterFor('application').get('erdasHost'), "/erdas-apollo/vector/LOTS3");
            layer1 = 'MV_LOTS3';
            attr1 = 'VC_LOTNUM';
            str1 = '%' + sv1 + '%';
            break;

          case 'Lots_exact':
            //swfs1,slayer1,ssrs1,sattr1,sstr1
            wfs1 = "".concat(self.store.adapterFor('application').get('erdasHost'), "/erdas-apollo/vector/LOTS3");
            layer1 = 'MV_LOTS3';
            attr1 = 'VC_LOTNUM';
            str1 = sv1;
            break;

          case 'Lots_EDR':
            //swfs1,slayer1,ssrs1,sattr1,sstr1
            wfs1 = "".concat(self.store.adapterFor('application').get('erdasHost'), "/erdas-apollo/vector/LOTS3");
            layer1 = 'MV_LOTS3';
            attr1 = 'VC_EDRNUM';
            str1 = '%' + sv1 + '%';
            break;

          case 'TOL':
            //swfs1,slayer1,ssrs1,sattr1,sstr1
            wfs1 = "".concat(self.store.adapterFor('application').get('erdasHost'), "/erdas-apollo/vector/GDBD");
            layer1 = 'TOL';
            attr1 = 'TOLNUM';
            str1 = '%' + sv1 + '%';
            break;

          case 'Gazette':
            //swfs1,slayer1,ssrs1,sattr1,sstr1
            wfs1 = "".concat(self.store.adapterFor('application').get('erdasHost'), "/erdas-apollo/vector/GAZETTE3");
            layer1 = 'MV_GAZETTE1';
            attr1 = 'VC_GR_NUM';
            str1 = '%' + sv1 + '%';
            break;

          case 'Gazette_exact':
            //swfs1,slayer1,ssrs1,sattr1,sstr1
            wfs1 = "".concat(self.store.adapterFor('application').get('erdasHost'), "/erdas-apollo/vector/GAZETTE3");
            layer1 = 'MV_GAZETTE1';
            attr1 = 'VC_GR_NUM';
            str1 = sv1;
            break;

          case 'Place':
            //swfs1,slayer1,ssrs1,sattr1,sstr1
            wfs1 = "".concat(self.store.adapterFor('application').get('erdasHost'), "/erdas-apollo/vector/SSDI");
            layer1 = 'POI';
            attr1 = 'PLACENAME';
            str1 = '%' + sv1 + '%';
            break;

          case 'Kampung':
            //swfs1,slayer1,ssrs1,sattr1,sstr1
            wfs1 = "".concat(self.store.adapterFor('application').get('erdasHost'), "/erdas-apollo/vector/GDBD_BR10");
            layer1 = 'KAMPUNG_P';
            attr1 = 'KG_NAME';
            str1 = '%' + sv1 + '%';
            break;

          case 'Mukim':
            //swfs1,slayer1,ssrs1,sattr1,sstr1
            wfs1 = "".concat(self.store.adapterFor('application').get('erdasHost'), "/erdas-apollo/vector/GDBD_BR10");
            layer1 = 'MUKIM_P';
            attr1 = 'MUKIM_NAME';
            str1 = '%' + sv1 + '%';
            break;

          case 'GeoCoordinates':
            //swfs1,slayer1,ssrs1,sattr1,sstr1
            wfs1 = '';
            layer1 = '';
            attr1 = '';
            str1 = '';
            break;

          default:
            console.log('Error Encountered in Search Case Switch');
        }
        /* olMapservice.findWfsObjects(olMapservice, wfs1, layer1, attr1, str1).then(
          function (self,features1) {
              console.log('map-search component found ...');
              console.log(features1);
              this.set('ffeatures',features1);
          }
        ); */


        let map = olMapservice.get('map');
        let markerVectorLayer = olMapservice.get('vectorLayer');

        if (st1.name == 'GeoCoordinates') {
          const dummy = await 20; // to resolve promise error at console

          if (Boolean(this.get('usedVectorLayer'))) {
            map.removeLayer(this.get('usedVectorLayer'));
            console.log('REMOVE USED LAYER...');
          }

          ;
          let mvs = olMapservice.get('vectorSource');
          mvs.clear();
          let carray = sv1.split(",");

          let _c_srs = map.getView().getProjection().getCode();

          if (Boolean(this.get('usedMarkerVectorLayer'))) {} else {
            map.addLayer(markerVectorLayer);
            this.set('usedMarkerVectorLayer', markerVectorLayer);
          }

          ; //olMapservice.plotOnePoint(map,carray);

          let [lon, lat] = carray.reverse(); // marker permanently change by reverse

          let point2 = new _Feature.default({
            geometry: new _Point.default(carray)
          });
          point2.setStyle(new _style.Style({
            image: new _style.Circle({
              radius: 7,
              //fill: new Fill({color: '#666666'}),
              stroke: new _style.Stroke({
                color: '#ff0000',
                width: 3
              })
            })
          }));
          const vectorSource = olMapservice.get('vectorSource');
          vectorSource.addFeature(point2);
          vectorSource.refresh();
          map.getView().setCenter(carray);

          if (_c_srs == 'EPSG:4326') {
            map.getView().setResolution(0.000005948652404239215);
          } else {
            map.getView().setResolution(0.6614596562526458386250105833545);
          }
        } else {
          let ff1 = await olMapservice.findWfsObjects(olMapservice, wfs1, layer1, attr1, str1, st1.name);
          console.log('map-search component found ...');
          console.log(ff1);
          this.set('model1', ff1);
          let redf1 = ff1.map(function (fea1) {
            let mt1 = {
              objectnum: fea1.objectnum
            };
            return mt1;
          });
          this.set('ffeatures', redf1);

          if (this.isResizeCapture == false) {
            (function (self) {
              $(window).bind('resize', function () {
                console.log('Window Resize Triggered'); //console.log(value);

                console.log(self.get('model1'));
                let wid1 = $(window).width();
                console.log(wid1);

                if (420 < wid1 && wid1 < 1025) {
                  console.log('Trying to resize 1');
                  self.get('table').columns.objectAt(0).set('width', wid1 * 0.3 * 0.3 + 'px');
                  self.get('table').columns.objectAt(1).set('width', wid1 * 0.3 * 0.6 + 'px'); //self.get('table').columns.objectAt(1).set('width', '0px');
                  //self.get('table').columns.objectAt(1).set('hidden', true);
                  //self.get('table').set('rows', self.get('ffeatures'));
                } else if (wid1 < 420) {
                  console.log('Trying to resize 3');
                  self.get('table').columns.objectAt(0).set('width', wid1 * 0.8 * 0.3 + 'px');
                  self.get('table').columns.objectAt(1).set('width', wid1 * 0.8 * 0.6 + 'px');
                  self.get('table').columns.objectAt(1).set('hidden', false);
                } else {
                  console.log('Trying to resize 2');
                  self.get('table').columns.objectAt(0).set('width', wid1 * 0.23 * 0.3 + 'px');
                  self.get('table').columns.objectAt(1).set('width', wid1 * 0.23 * 0.6 + 'px');
                  self.get('table').columns.objectAt(1).set('hidden', false);
                }
              }).trigger('resize');
            })(this);

            this.set('isResizeCapture', true);
          }
        } //this.updateModel(ff1);
        // rerender component
        //this.rerender();

      }

    }
  });

  _exports.default = _default;
});