define("web-map/components/cell-fid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    shortFid: Ember.computed('value', function () {
      const fid = this.get('value');
      const shortFid = fid ? fid.substr(0, 6) : 'n/a';
      return shortFid;
    })
  });

  _exports.default = _default;
});