define("web-map/models/formuser", ["exports", "ember-data/model", "ember-data/relationships"], function (_exports, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    user: (0, _relationships.belongsTo)('user', {
      async: true
    }),
    forms: (0, _relationships.hasMany)('form', {
      async: true
    }) //entries: hasMany('entry', {async: true})

  });

  _exports.default = _default;
});