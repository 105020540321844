define("web-map/routes/disclaimermap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      gotoHome() {
        //cannot use transitionTo because it wont load the map
        window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/start-gp"));
      },

      gotoPublic() {
        //cannot use transitionTo because it wont load the map
        window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/map"));
      }

    }
  });

  _exports.default = _default;
});