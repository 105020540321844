define("web-map/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    //relationships
    //usergroups: DS.hasMany('usergroup', { async: true }),
    //secretquestion: DS.belongsTo('secretquestion', { async: true }),
    // w3cmsuser: DS.belongsTo('w3cmsuser', { async: true }),
    //formuser: DS.belongsTo('formuser', { async: true }),
    //euser: DS.belongsTo('euser'),
    // processes: DS.hasMany('process', { async: true, inverse: 'supervisor' }),
    //userprocesstasks: DS.hasMany('userprocesstask', { async: true }),
    //processmemos: DS.hasMany('processmemo', { async: true }),
    //country: DS.belongsTo('country', { async: true}),
    // organizationnum: DS.belongsTo('organization', { async: true, inverse: 'users' }),
    // companynum: DS.belongsTo('company', { async: true, inverse: 'users' }),
    // createdBy: DS.belongsTo('user', { async: true }),
    // updatedBy: DS.belongsTo('user', { async: true }),
    // attributes
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    username: _emberData.default.attr('string'),
    tagline: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    website: _emberData.default.attr('string'),
    profilePicture: _emberData.default.attr('string'),
    // userType: DS.attr('string'),
    notification: _emberData.default.attr('array'),
    newNotification: _emberData.default.attr('boolean'),
    phone: _emberData.default.attr('string'),
    authEnable: _emberData.default.attr('boolean'),
    status: _emberData.default.attr('string'),
    postalCode: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    activeStatus: _emberData.default.attr('boolean'),
    noOfLoginAttempt: _emberData.default.attr('number'),
    forceChangePassword: _emberData.default.attr('boolean'),
    customattributes: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date', {
      defaultValue() {
        return new Date();
      }

    }),
    updatedAt: _emberData.default.attr('date', {
      defaultValue() {
        return new Date();
      }

    }),
    // computed
    fullName: Ember.computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    })
  });

  _exports.default = _default;
});