define("web-map/components/login-modal", ["exports", "axios"], function (_exports, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    actions: {
      login: async function () {
        const {
          identification,
          password
        } = this.getProperties('identification', 'password');
        (0, _axios.default)({
          method: 'POST',
          url: this.get('checkUserUrl'),
          data: {
            identification,
            password
          }
        }).then(async checkUserResult => {
          this.get('authObject').set('isAuthenticated', true);
          let user = checkUserResult.data.user.id;
          this.get('authObject').authenticate('authenticator:oauth2', identification.toLowerCase().trim(), password).then(async () => {
            await this.authorize();
            this.exportAction(user);
          });
        }).catch(err => {
          console.log(err);
          this.set('errorMessage', err.response.data);
        });
      },
      cancel: function () {
        this.set('errorMessage', null);
        $(".modal-backdrop").remove();
      },
      signUp: function () {
        window.location.replace("".concat(this.store.adapterFor('application').get('ecomWebsite'), "/register"));
      }
    }
  });

  _exports.default = _default;
});