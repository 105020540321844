define("web-map/routes/checkout", ["exports", "web-map/utils/get-cookie", "ember-simple-auth/mixins/authenticated-route-mixin", "web-map/mixins/w3o-users-roles/check-user-role", "web-map/mixins/checkout/checkout-list", "axios"], function (_exports, _getCookie, _authenticatedRouteMixin, _checkUserRole, _checkoutList, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _checkUserRole.default, _checkoutList.default, {
    authObject: Ember.inject.service('session'),
    registered: true,
    btn_confirmPayment: false,

    model() {
      return this.store.query('order', {
        where: {
          user: this.get('authObject.data.user.id'),
          isdelete: false,
          status: 'Process'
        }
      });
    },

    async afterModel() {
      let url_userrole = this.store.adapterFor('application').get('serverHost') + this.store.adapterFor('application').get('namespace') + '/layersetups/getUserRole';
      let response = await _axios.default.post(url_userrole, {
        userid: this.get("authObject.data.user.id")
      }).catch(error => {
        console.log("An error occured while getting user role, ".concat(error));
      });

      if (response.data.registered) {
        this.set("registered", true);
      } else {
        this.set("registered", false);
      }
    },

    async setupController(controller, model) {
      model = model.objectAt(0);
      controller.set('user', this.get('authObject.data.user'));

      if (this.get("registered") && this.get('authObject.data.user.is_foc')) {
        controller.set("paymentMethodOption", [{
          value: "foc",
          label: "Free of Charge"
        }]);
        controller.set("paymentMethod", "foc");
      } else {
        controller.set("paymentMethodOption", [{
          value: "baiduri",
          label: "Credit/Debit Card"
        }]);
        controller.set("paymentMethod", "baiduri");
      }

      if (this.get('authObject.data.user.receive_cash_payment')) {
        controller.get("paymentMethodOption").pushObject({
          value: "cashpayment",
          label: "Cash Payment"
        });
      }

      console.log('createdAt');
      let orderDetails = [];

      if (model.get('type') === 'Feature') {
        orderDetails = await this.store.query('featureexport', {
          where: {
            order_number: model.get('order_number'),
            isdelete: false
          },
          sort: "id ASC"
        });
      } else if (model.get('type') === 'Imagery') {
        orderDetails = await this.store.query('imageexport', {
          where: {
            order_number: model.get('order_number'),
            isdelete: false
          },
          sort: "id ASC"
        });
      } else if (model.get('type') === 'RSO') {
        orderDetails = await this.store.query('rsoexport', {
          where: {
            order_number: model.get('order_number'),
            isdelete: false
          },
          sort: "id ASC"
        });
      } else if (model.get('type') === 'Geodetic') {
        orderDetails = await this.store.query('geodeticexport', {
          where: {
            order_number: model.get('order_number'),
            isdelete: false
          },
          sort: "id ASC"
        });
      }

      if (controller.get('orderType') === 'RSO' || controller.get('orderType') === 'Geodetic') {
        controller.set('show_dataarea', false);
      } else {
        controller.set('show_dataarea', false);
      }

      let totalPrice = 0;
      orderDetails.forEach(record => {
        console.log(record.price);
        totalPrice += parseFloat(record.price);
        record.set("formatted_data_size", (parseFloat(record.data_size) / 1048576).toFixed(5));
      });
      model.set("total_price", totalPrice);
      model.save().then(result => {
        console.log("Order saved");
        console.log(result);
      });

      let _country,
          country_nicename = '-';

      if (controller.get("user.country")) {
        _country = await this.store.query("country", {
          id: controller.get("user.country")
        });
        country_nicename = _country.get("firstObject").get("nicename");
      }

      controller.setProperties({
        "country_nicename": country_nicename,
        "ordercreatedat": model.createdAt.toDateString(),
        "model": model,
        "orderDetails": orderDetails,
        "totalPrice": totalPrice.toLocaleString(),
        "totalPrice_num": totalPrice
      });
    },

    actions: {
      showCancelOrder() {
        $("#cancel-order-modal-checkout").modal("show");
      },

      cancelOrder() {
        let controller = this.get('controller');
        let model = controller.get('model');
        model.set('status', 'Cancelled');
        model.save().then(() => {
          $("#cancel-order-modal-checkout").modal("hide"); //cannot use transitionTo because it wont load the map
          //Go back to /momap if using mobile app

          if ((0, _getCookie.default)('isMobile') === 'Yes') {
            window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/momap"));
          } else {
            window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/map"));
          }
        });
      },

      confirmPayment(model) {
        let controller = this.controller;

        if (controller.get("paymentMethod")) {
          controller.set("errorPaymentMethod", false);

          if (controller.get("paymentMethod") === "foc") {
            this.transitionTo("foc");
          } else if (controller.get("paymentMethod") === "cashpayment") {
            this.transitionTo("cashpayment");
          } else {
            // to prevent double click by accident
            if (!this.get("btn_confirmPayment")) {
              this.set("btn_confirmPayment", true);
              this.readypay(model);
            }
          }
        } else {
          controller.set("errorPaymentMethod", true);
        }
      },

      gotoHome() {
        //cannot use transitionTo because it wont load the map
        //Go back to /momap if using mobile app
        if ((0, _getCookie.default)('isMobile') === 'Yes') {
          window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/momap"));
        } else {
          window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/map"));
        }
      }

    }
  });

  _exports.default = _default;
});