define("web-map/components/ol-layer-tree-item", ["exports", "web-map/templates/components/ol-layer-tree-item"], function (_exports, _olLayerTreeItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _olLayerTreeItem.default,
    tagName: 'div',
    id: Ember.computed('layer', function () {
      console.log("Inside ol layer tree item component");
      console.log("layer id is: " + this.get('layer.id'));
      return 'ol-layer-item-' + this.get('layer.id');
    }),
    id2: Ember.computed('layer', function () {
      let clayer = this.get('layer');
      let imgLayer = null;

      if ('group_array' in clayer) {
        //imgLayer = clayer.group_class.get('layer');
        imgLayer = clayer.get('layer');
      } else {
        imgLayer = clayer.get('layer');
      }

      console.log('ID2 ol-layer-tree-item');
      console.log(clayer);
      console.log(imgLayer); //console.log(this.get('layer'));

      let ltitle = imgLayer.values_.title.replace(/\s+/g, '');
      console.log(ltitle);
      return "layer-item-id-".concat(ltitle);
    }),
    properTitle: '',
    group_layers: null,
    isGroup: false,
    isGroupVisible: false,
    isShowSubLayers: false,
    actions: {
      toggleSubLayers() {
        console.log('Toggle Sub Layers...');
        let currv = this.get('isShowSubLayers');
        this.set('isShowSubLayers', !currv);
      }

    },

    init() {
      this._super(...arguments);

      let clayer = this.get('layer');
      let imgLayer = null;

      if ('group_array' in clayer) {
        //imgLayer = clayer.group_class.get('layer');
        imgLayer = clayer.get('layer');
        this.set('properTitle', imgLayer.values_.title);
        this.set('group_layers', clayer.group_array);
        this.set('isGroup', true);
        this.set('isGroupVisible', true);
        this.set('isShowSubLayers', true);
        console.log('ol-layer-tree-item component init()...');
        console.log(clayer.group_array);
      } else {
        imgLayer = clayer.get('layer');
        this.set('properTitle', imgLayer.values_.title);
      }
    },

    layer_active_value: Ember.computed('layer', function () {
      console.log('ol-layer-tree-item component ...');
      console.log('compute layer_active_value  ' + this.get('layer.visible'));
      console.log('layer.minScale : ' + this.get('layer.minScale'));
      console.log('layer.maxScale : ' + this.get('layer.maxScale'));
      return this.get('layer.visible') ? 'right-icon' : 'right-icon-inactive';
    }),
    isBaseLayer: Ember.computed('layer', function () {
      console.log('ol-layer-tree-item component compute isBaseLayer  ...');
      console.log(this.get('layer'));
      let clayer = this.get('layer');
      let imgLayer = null;

      if ('group_array' in clayer) {
        //imgLayer = clayer.group_class.get('layer');
        imgLayer = clayer.get('layer');
      } else {
        imgLayer = clayer.get('layer');
      }

      if (imgLayer.values_.group_name == 'Basemap') {
        return true;
      } else {
        return false;
      }
      /* if (this.get('layer.isGroup')) {
        return true;
      } else {
        return false;
      } */

    })
  });

  _exports.default = _default;
});