define("web-map/router", ["exports", "web-map/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('momap');
    this.route('map');
    this.route('stmap');
    this.route('start');
    this.route('start-gp');
    this.route('unauthorized');
    this.route('disclaimermap');
    this.route('disclaimermap-mobile');
    this.route('disclaimerstmap');
    this.route('login');
    this.route('shopping-cart');
    this.route('under-construction');
    this.route('checkout');
    this.route('foc');
    this.route('payment-success');
    this.route('conditions-of-use');
    this.route('privacy-policy');
    this.route('disclaimer');
    this.route('change-password');
    this.route('cashpayment');
  });
  var _default = Router;
  _exports.default = _default;
});