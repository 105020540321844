define("web-map/components/cell-geom", ["exports", "ol/format/WKT.js"], function (_exports, _WKT) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    wkt: Ember.computed('value', function () {
      const geometry = this.get('value');
      const format = new _WKT.default();
      const wkt = format.writeGeometry(geometry);
      return wkt;
    })
  });

  _exports.default = _default;
});