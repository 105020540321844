define("web-map/routes/start", ["exports", "jquery", "web-map/mixins/w3o-users-roles/invalidate-session", "axios", "web-map/utils/get-cookie"], function (_exports, _jquery, _invalidateSession, _axios, _getCookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import ApplicationRouteMixin from 'ember-simple-auth/mixins/application-route-mixin';
  // import CheckUserRole from '../mixins/w3o-users-roles/check-user-role';
  // import CheckSessionActivity from '../mixins/w3o-users-roles/check-session-activity';
  // import SetSessionUser from '../mixins/w3o-users-roles/set-session-user';
  // importing 3 3rd party libraries
  //export default Route.extend(ApplicationRouteMixin, CheckUserRole, InvalidateSession, CheckSessionActivity, SetSessionUser, {
  var _default = Ember.Route.extend(_invalidateSession.default, {
    authObject: Ember.inject.service('session'),
    // actionList: null,
    renderFlag: true,

    //session ganti nama jadi authObject
    async beforeModel() {
      if ((0, _getCookie.default)('isMobile') === "Yes") {
        //window.location.replace(`${this.store.adapterFor('application').get('host')}/momap`);
        this.transitionTo('momap');
      }

      if (this.get('authObject.data.user')) {
        if (this.get('authObject.data.user.status') !== 'Active') {
          return this.get('authObject').invalidate();
        } // if (this.get('authObject.data.user.authEnable') && !this.get('authObject.data.isAuth')) {
        // 	this.transitionTo('authenticate');
        // }

      } // if (this.session.get('isAuthenticated')) {
      // 	this.transitionTo('start');
      // }


      if (this.get('authObject.data.authenticated.user')) {
        this._super(...arguments);
      }
    },

    async model() {// if (this.get('authObject.data.user')) {
      // 	let response = await this.setSessionUser();
      // 	if (response) {
      // 		let euser = await this.store.queryRecord('euser', { user: this.get('authObject.data.user.id') });
      // 		let companyId = euser ? euser.get('companynum.content.id') : null;
      // 		$.ajax({
      // 			url: '/api/v1/applications/getApplications',
      // 			type: 'POST',
      // 			data: {
      // 				userId: this.get('authObject.data.user.id'),
      // 				companyId
      // 			}
      // 		}).then((response) => {
      // 			this.controller.set('loading', false);
      // 			return this.controller.set('model', response.applications);
      // 		});
      // 	}
      // }
    },

    setupController: function (controller) {
      controller.set('session', this.get('authObject.session'));
      controller.set('loading', true); // console.log(this.get('authObject'));

      if (this.get('authObject.isAuthenticated')) {
        controller.set('authObject', this.get('authObject'));
        controller.set('geolink', '/stmap');
      } else {
        controller.set('geolink', '/map');
      }

      controller.set('mapLink', this.store.adapterFor('application').get('ecomHost')); //Set variables for nav bar

      this.controllerFor('application').set('appID', null);
      this.controllerFor('application').set('menus', null);
      Ember.run.schedule('afterRender', () => {
        (0, _jquery.default)('.owl-carousel').owlCarousel({
          loop: true,
          margin: 10,
          nav: true,
          navText: ["<i style='position: absolute; left: -5%; top: 30%; z-index: 5;' class='fa fa-2x fa-arrow-left'></i>", "<i style='position: absolute; right: -5%; top: 30%; z-index: 5;' class='fa fa-2x fa-arrow-right'></i>"],
          responsive: {
            0: {
              items: 1
            },
            768: {
              items: 4
            }
          }
        });
      });
    },

    renderTemplate() {
      this._super();
    },

    actions: {
      /*
        Transition to route
      */
      goTo(app) {
        if (app.isExternalLink) {
          if (app.routeName.indexOf('http') > -1) {
            window.location.assign(app.routeName);
          } else {
            window.location.assign('http://' + app.routeName);
          }
        } else if (app.appId) {
          this.transitionTo(app.routeName, app.appId);
        } else {
          this.transitionTo(app.routeName);
        }
      },

      // setSession() {
      // 	this.setSession();
      // },

      /*
        invalidate current session and after that, redirect to start page to login
      */
      invalidateSession() {
        this.invalidateSession();
      },

      authenticate: function () {
        window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/login")); // let self = this;
        // let flag = 0;
        // let controller = this.get('controller');
        // // this.set('errorMessage', null);
        // if(isEmpty(controller.get('identification'))) {
        // 	controller.set('errorMessage', 'Email must be filled');
        // 	flag++;
        // 	return;
        // }
        // if(isEmpty(controller.get('password'))) {
        // 	controller.set('errorMessage', 'Password must be filled');
        // 	flag++;
        // 	return;
        // }
        // if(flag === 0) {
        // 	// controller.set('errorMessage', null);
        // 	// const { identification, password } = controller.getProperties('identification', 'password');
        // 	// this.get('authObject').authenticate('authenticator:oauth2', identification.toLowerCase().trim(), password).then((result) => {
        // 	// 	self.setSession();
        // 	// }).catch((reason) => {
        // 	// 	if (self.get('authObject.session.isAuthenticated')) {
        // 	// 		self.setSession();
        // 	// 	}
        // 	// 	else {
        // 	// 	controller.set('errorMessage', reason.error || reason);
        // 	// 	}
        // 	// });
        // 	$('#loading-login').show();
        // 	this.checkUser(controller.get('identification'), controller.get('password'));
        // }
      },

      gotoRegistered() {
        //cannot use transitionTo because it wont load the map
        if (this.get('authObject.data.user')) {
          window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/disclaimerstmap"));
        } else {
          window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/login"));
        }
      },

      gotoPublic() {
        //cannot use transitionTo because it wont load the map
        window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/disclaimermap"));
      },

      gotoHome() {
        //cannot use transitionTo because it wont load the map
        window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/start"));
      },

      conditionsOfUse() {
        window.location.replace("".concat(this.store.adapterFor('application').get('hostSurvey'), "/conditions-of-use"));
      },

      privacyPolicy() {
        window.location.replace("".concat(this.store.adapterFor('application').get('hostSurvey'), "/privacy-policy"));
      },

      disclaimer() {
        window.location.replace("".concat(this.store.adapterFor('application').get('hostSurvey'), "/disclaimer"));
      }

    }
  });

  _exports.default = _default;
});