define("web-map/mixins/w3o-users-roles/create-edit-appaccess", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    appId: '',
    authObject: Ember.inject.service('session'),
    edit: false,
    setupController: function () {
      var controller = this.get('controller');
      var model = this.get('currentModel');
      controller.set('model', model);
      controller.set('appId', this.get('appId'));
      this.store.query('role', {
        application: this.get('appId')
      }).then(function (roles) {
        controller.set('roleList', roles);
      });
      this.store.findRecord('application', this.get('appId')).then(function (app) {
        controller.set('model.application', app);
      });
      Ember.run.scheduleOnce('afterRender', this, function () {
        (0, _jquery.default)('#access').removeClass('active');
        (0, _jquery.default)('#assign').removeClass('active');
        (0, _jquery.default)('#role').removeClass('active');
        (0, _jquery.default)('#route').removeClass('active');
        (0, _jquery.default)('#app-access').addClass('active');
      });
    },
    saveAppAccess: function (model) {
      var flag = 0;
      var self = this;
      var controller = this.get('controller');

      if (Ember.isEmpty(model.get('role.content'))) {
        (0, _jquery.default)("#roleForm").addClass("has-error");
        (0, _jquery.default)("#roleIsEmpty").removeClass("d-none");
        flag++;
      }

      if (flag === 0) {
        controller.set('disableSubmit', true);
        model.save().then(function () {
          self.transitionTo('users-roles.role-manager.app.app-access', self.get('appId'));
        });
      }
    },
    goTo: function (param) {
      this.transitionTo('users-roles.role-manager.app.' + param, this.get('appId'));
    }
  });

  _exports.default = _default;
});