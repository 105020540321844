define("web-map/templates/components/nlump-hover-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0iEiQW2/",
    "block": "{\"symbols\":[\"row\",\"detail\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"table table-striped popover-table\"],[8],[0,\"\\n\"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"rows\"]]],null,{\"statements\":[[0,\"    \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"col1\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[\"col2\"]]],null,{\"statements\":[[0,\"                \"],[1,[23,2,[]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-map/templates/components/nlump-hover-table.hbs"
    }
  });

  _exports.default = _default;
});