define("web-map/components/nlump-hover-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['table', 'mb-0'],
    tagName: "table",

    click() {
      this.closeLegend();
    }

  });

  _exports.default = _default;
});