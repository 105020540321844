define("web-map/mixins/w3o-users-roles/create-edit-user", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    authObject: Ember.inject.service('session'),
    create: false,
    setupController: function () {
      var controller = this.get('controller');
      var model = this.get('currentModel');
      controller.set('model', model);
      controller.set('groupModel', this.store.findAll('group'));
      controller.set('applicationModel', this.store.findAll('application'));
      controller.set('secretQuestionModel', this.store.findAll('secretquestion'));
      controller.set('hasSelect', false);
      controller.set('countryList', this.store.query('country', {
        sort: 'nicename ASC'
      }));
    },
    getInfo: function (param) {
      var self = this;
      var controller = this.get('controller');
      controller.set('model.state', '-');
      (0, _jquery.default)("#stateForm").removeClass('has-error');
      (0, _jquery.default)('#glyphState').addClass("d-none");
      (0, _jquery.default)('#stateIsEmpty').addClass("d-none");

      _jquery.default.ajax({
        type: "POST",
        data: {
          sourceId: param
        },
        url: this.store.adapterFor('application').get('namespace') + '/additionals/getAddressByPostalCodeFromGoThere',
        success: function (response) {
          controller.set('model.address', response.value);

          if (response.value) {
            (0, _jquery.default)("#addressForm").removeClass('has-error');
            (0, _jquery.default)('#glyphAddress').addClass("d-none");
            (0, _jquery.default)('#addressIsEmpty').addClass("d-none");
          }
        },
        error: function (xhr, status, error) {
          console.log('Error ' + error);
        }
      });

      _jquery.default.ajax({
        type: "POST",
        data: {
          sourceId: param
        },
        url: this.store.adapterFor('application').get('namespace') + '/additionals/getAddressLineByPostalCodeFromGoThere',
        success: function (response) {
          controller.set('model.address1', response.value);

          if (response.value) {
            (0, _jquery.default)("#address1Form").removeClass('has-error');
            (0, _jquery.default)('#glyphAddress1').addClass("d-none");
            (0, _jquery.default)('#address1IsEmpty').addClass("d-none");
          }
        },
        error: function (xhr, status, error) {
          console.log('Error ' + error);
        }
      });

      _jquery.default.ajax({
        type: "POST",
        data: {
          sourceId: param
        },
        url: this.store.adapterFor('application').get('namespace') + '/additionals/getCountryNameByPostalCodeFromGoThere',
        success: function (response) {
          controller.set('model.city', response.value);

          if (response.value) {
            (0, _jquery.default)("#cityForm").removeClass('has-error');
            (0, _jquery.default)('#glyphCity').addClass("d-none");
            (0, _jquery.default)('#cityIsEmpty').addClass("d-none");
          }
        },
        error: function (xhr, status, error) {
          console.log('Error ' + error);
        }
      });

      _jquery.default.ajax({
        type: "POST",
        data: {
          sourceId: param
        },
        url: this.store.adapterFor('application').get('namespace') + '/additionals/getCountryNameCodeByPostalCodeFromGoThere',
        success: function (response) {
          self.store.queryRecord('country', {
            iso: response.value
          }).then(function (country) {
            controller.set('model.country', country);

            if (response.value) {
              (0, _jquery.default)("#countryForm").removeClass('has-error');
              (0, _jquery.default)('#glyphCountry').addClass("d-none");
              (0, _jquery.default)('#countryIsEmpty').addClass("d-none");
            }
          });
        },
        error: function (xhr, status, error) {
          console.log('Error ' + error);
        }
      });
    },
    saveUser: function (model) {
      let self = this;
      let flag = 0;
      let regexEmail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      /*
          Regex for password
          at least 8 characters
          at least contains 1 uppercase, 1 lowercase, 1 number, and 1 special character( !"#$%&'()*+,-.:;<=>/?@\^[]_`{}|~ no space currently) 
      */

      let regexPass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,-.:;<=>/?@\\^[\]_`{}|~])[a-zA-Z\d!"#$%&'()*+,-.:;<=>/?@\\^[\]_`{}|~]{8,}$/;
      let controller = this.get('controller');
      let scrolled = false;

      if (Ember.isEmpty(model.get('firstName'))) {
        (0, _jquery.default)("#firstNameForm").addClass("has-error");
        (0, _jquery.default)("#firstNameIsEmpty").removeClass("d-none");
        (0, _jquery.default)("#glyphFirstName").removeClass("d-none");
        flag++;

        if (!scrolled) {
          (0, _jquery.default)('html, body').animate({
            scrollTop: (0, _jquery.default)("#firstNameForm").offset().top - ((0, _jquery.default)("#firstNameForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (Ember.isEmpty(model.get('lastName'))) {
        (0, _jquery.default)("#lastNameForm").addClass("has-error");
        (0, _jquery.default)("#lastNameIsEmpty").removeClass("d-none");
        (0, _jquery.default)("#glyphLastName").removeClass("d-none");
        flag++;

        if (!scrolled) {
          (0, _jquery.default)('html, body').animate({
            scrollTop: (0, _jquery.default)("#lastNameForm").offset().top - ((0, _jquery.default)("#lastNameForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (Ember.isEmpty(model.get('username'))) {
        (0, _jquery.default)("#usernameForm").addClass("has-error");
        (0, _jquery.default)("#usernameIsEmpty").removeClass("d-none");
        (0, _jquery.default)("#glyphUsername").removeClass("d-none");
        flag++;

        if (!scrolled) {
          (0, _jquery.default)('html, body').animate({
            scrollTop: (0, _jquery.default)("#usernameForm").offset().top - ((0, _jquery.default)("#usernameForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (Ember.isEmpty(model.get('email'))) {
        (0, _jquery.default)("#emailForm").addClass("has-error");
        (0, _jquery.default)("#emailIsEmpty").removeClass("d-none");
        (0, _jquery.default)("#glyphEmail").removeClass("d-none");
        flag++;

        if (!scrolled) {
          (0, _jquery.default)('html, body').animate({
            scrollTop: (0, _jquery.default)("#emailForm").offset().top - ((0, _jquery.default)("#emailForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      } else if (!regexEmail.test(model.get('email'))) {
        (0, _jquery.default)("#emailForm").addClass("has-error");
        (0, _jquery.default)("#emailRegexError").removeClass("d-none");
        (0, _jquery.default)("#glyphEmail").removeClass("d-none");
        flag++;

        if (!scrolled) {
          (0, _jquery.default)('html, body').animate({
            scrollTop: (0, _jquery.default)("#emailForm").offset().top - ((0, _jquery.default)("#emailForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (Ember.isEmpty(model.get('phone'))) {
        (0, _jquery.default)("#phoneForm").addClass("has-error");
        (0, _jquery.default)("#phoneIsEmpty").removeClass("d-none");
        (0, _jquery.default)("#glyphPhone").removeClass("d-none");
        flag++;

        if (!scrolled) {
          (0, _jquery.default)('html, body').animate({
            scrollTop: (0, _jquery.default)("#phoneForm").offset().top - ((0, _jquery.default)("#phoneForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (Ember.isEmpty(model.get('postalCode'))) {
        (0, _jquery.default)("#postalCodeForm").addClass("has-error");
        (0, _jquery.default)("#postalCodeIsEmpty").removeClass("d-none");
        (0, _jquery.default)("#glyphPostalCode").removeClass("d-none");
        flag++;

        if (!scrolled) {
          (0, _jquery.default)('html, body').animate({
            scrollTop: (0, _jquery.default)("#phoneForm").offset().top - ((0, _jquery.default)("#phoneForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (Ember.isEmpty(model.get('address'))) {
        (0, _jquery.default)("#addressForm").addClass("has-error");
        (0, _jquery.default)("#addressIsEmpty").removeClass("d-none");
        (0, _jquery.default)("#glyphAddress").removeClass("d-none");
        flag++;

        if (!scrolled) {
          (0, _jquery.default)('html, body').animate({
            scrollTop: (0, _jquery.default)("#addressForm").offset().top - ((0, _jquery.default)("#addressForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (Ember.isEmpty(model.get('address1'))) {
        (0, _jquery.default)("#address1Form").addClass("has-error");
        (0, _jquery.default)("#address1IsEmpty").removeClass("d-none");
        (0, _jquery.default)("#glyphAddress1").removeClass("d-none");
        flag++;

        if (!scrolled) {
          (0, _jquery.default)('html, body').animate({
            scrollTop: (0, _jquery.default)("#address1Form").offset().top - ((0, _jquery.default)("#address1Form").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (Ember.isEmpty(model.get('city'))) {
        (0, _jquery.default)("#cityForm").addClass("has-error");
        (0, _jquery.default)("#cityIsEmpty").removeClass("d-none");
        (0, _jquery.default)("#glyphCity").removeClass("d-none");
        flag++;

        if (!scrolled) {
          (0, _jquery.default)('html, body').animate({
            scrollTop: (0, _jquery.default)("#cityForm").offset().top - ((0, _jquery.default)("#cityForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (Ember.isEmpty(model.get('state'))) {
        (0, _jquery.default)("#stateForm").addClass("has-error");
        (0, _jquery.default)("#stateIsEmpty").removeClass("d-none");
        (0, _jquery.default)("#glyphState").removeClass("d-none");
        flag++;

        if (!scrolled) {
          (0, _jquery.default)('html, body').animate({
            scrollTop: (0, _jquery.default)("#stateForm").offset().top - ((0, _jquery.default)("#stateForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (Ember.isEmpty(model.get('country.content'))) {
        (0, _jquery.default)("#countryForm").addClass("has-error");
        (0, _jquery.default)("#countryIsEmpty").removeClass("d-none");
        (0, _jquery.default)("#glyphCountry").removeClass("d-none");
        flag++;

        if (!scrolled) {
          (0, _jquery.default)('html, body').animate({
            scrollTop: (0, _jquery.default)("#countryForm").offset().top - ((0, _jquery.default)("#countryForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      }

      if (this.get('create')) {
        if (Ember.isEmpty(model.get('password'))) {
          (0, _jquery.default)("#passwordForm").addClass("has-error");
          (0, _jquery.default)("#passwordIsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphPassword").removeClass("d-none");
          flag++;

          if (!scrolled) {
            (0, _jquery.default)('html, body').animate({
              scrollTop: (0, _jquery.default)("#passwordForm").offset().top - ((0, _jquery.default)("#passwordForm").height() + 80)
            }, 1000);
            scrolled = true;
          }
        } else if (!regexPass.test(model.get('password'))) {
          (0, _jquery.default)("#passwordForm").addClass("has-error");
          (0, _jquery.default)("#passwordValidation").removeClass("d-none");
          (0, _jquery.default)("#glyphPassword").removeClass("d-none");
          flag++;

          if (!scrolled) {
            (0, _jquery.default)('html, body').animate({
              scrollTop: (0, _jquery.default)("#passwordForm").offset().top - ((0, _jquery.default)("#passwordForm").height() + 80)
            }, 1000);
            scrolled = true;
          }
        }

        if (Ember.isEmpty(model.get('passwordRepeat'))) {
          (0, _jquery.default)("#passwordRepeatForm").addClass("has-error");
          (0, _jquery.default)("#passwordRepeatIsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphPasswordRepeat").removeClass("d-none");
          flag++;

          if (!scrolled) {
            (0, _jquery.default)('html, body').animate({
              scrollTop: (0, _jquery.default)("#passwordRepeatForm").offset().top - ((0, _jquery.default)("#passwordRepeatForm").height() + 80)
            }, 1000);
            scrolled = true;
          }
        } else if (model.get('password') !== model.get('passwordRepeat')) {
          (0, _jquery.default)("#passwordRepeat").addClass("has-error");
          (0, _jquery.default)("#passwordIsNotSame").removeClass("d-none");
          (0, _jquery.default)("#glyphPasswordRepeat").removeClass("d-none");
          flag++;

          if (!scrolled) {
            (0, _jquery.default)('html, body').animate({
              scrollTop: (0, _jquery.default)("#passwordRepeat").offset().top - ((0, _jquery.default)("#passwordRepeat").height() + 80)
            }, 1000);
            scrolled = true;
          }
        }

        if (Ember.isEmpty(model.get('answer'))) {
          (0, _jquery.default)("#answerForm").addClass("has-error");
          (0, _jquery.default)("#answerIsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphAnswer").removeClass("d-none");
          flag++;

          if (!scrolled) {
            (0, _jquery.default)('html, body').animate({
              scrollTop: (0, _jquery.default)("#answerForm").offset().top - ((0, _jquery.default)("#answerForm").height() + 80)
            }, 1000);
            scrolled = true;
          }
        }

        if (Ember.isEmpty(model.get('answerRepeat'))) {
          (0, _jquery.default)("#answerRepeatForm").addClass("has-error");
          (0, _jquery.default)("#answerRepeatIsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphAnswerRepeat").removeClass("d-none");
          flag++;

          if (!scrolled) {
            (0, _jquery.default)('html, body').animate({
              scrollTop: (0, _jquery.default)("#answerRepeatForm").offset().top - ((0, _jquery.default)("#answerRepeatForm").height() + 80)
            }, 1000);
            scrolled = true;
          }
        } else if (model.get('answer') !== model.get('answerRepeat')) {
          (0, _jquery.default)("#answerRepeatForm").addClass("has-error");
          (0, _jquery.default)("#answerIsNotSame").removeClass("d-none");
          (0, _jquery.default)("#glyphAnswerRepeat").removeClass("d-none");
          flag++;

          if (!scrolled) {
            (0, _jquery.default)('html, body').animate({
              scrollTop: (0, _jquery.default)("#answerRepeatForm").offset().top - ((0, _jquery.default)("#answerRepeatForm").height() + 80)
            }, 1000);
            scrolled = true;
          }
        }

        if (Ember.isEmpty(model.get('secretquestion.content'))) {
          (0, _jquery.default)("#questionForm").addClass("has-error");
          (0, _jquery.default)("#questionIsEmpty").removeClass("d-none");
          (0, _jquery.default)("#glyphQuestion").removeClass("d-none");
          flag++;

          if (!scrolled) {
            (0, _jquery.default)('html, body').animate({
              scrollTop: (0, _jquery.default)("#questionForm").offset().top - ((0, _jquery.default)("#questionForm").height() + 80)
            }, 1000);
            scrolled = true;
          }
        } else {
          (0, _jquery.default)("#questionForm").removeClass('has-error');
          (0, _jquery.default)('#glyphQuestion').addClass('d-none');
          (0, _jquery.default)('#questionIsEmpty').addClass('d-none');
        }
      }

      if (this.get('create') && Ember.isEmpty(controller.get('groupCon'))) {
        (0, _jquery.default)("#groupForm").addClass("has-error");
        (0, _jquery.default)("#groupIsEmpty").removeClass("d-none");
        (0, _jquery.default)("#glyphGroup").removeClass("d-none");
        flag++;

        if (!scrolled) {
          (0, _jquery.default)('html, body').animate({
            scrollTop: (0, _jquery.default)("#groupForm").offset().top - ((0, _jquery.default)("#groupForm").height() + 80)
          }, 1000);
          scrolled = true;
        }
      } else {
        (0, _jquery.default)("#groupForm").removeClass("has-error");
        (0, _jquery.default)("#glyphGroup").addClass("d-none");
        (0, _jquery.default)("#groupIsEmpty").addClass("d-none");
      }

      (0, _jquery.default)('#firstNameForm input').blur(function () {
        if (this.value !== '') {
          (0, _jquery.default)("#firstNameForm").removeClass('has-error');
          (0, _jquery.default)('#glyphFirstName').addClass('d-none');
          (0, _jquery.default)('#firstNameIsEmpty').addClass('d-none');
        }
      });
      (0, _jquery.default)('#lastNameForm input').blur(function () {
        if (this.value !== '') {
          (0, _jquery.default)("#lastNameForm").removeClass('has-error');
          (0, _jquery.default)('#glyphLastName').addClass('d-none');
          (0, _jquery.default)('#lastNameIsEmpty').addClass('d-none');
        }
      });
      /*Ember.$('#countryCodeForm input').blur(function()
      {
          if( this.value !== '') {
              Ember.$("#countryCodeForm").removeClass('has-error');
              Ember.$('#glyphCountryCode').addClass('d-none');
              Ember.$('#countryCodeIsEmpty').addClass('d-none');
              Ember.$('#countryCodeIsNaN').addClass('d-none');
          }
      });*/

      (0, _jquery.default)('#postalCodeForm input').blur(function () {
        if (this.value !== '') {
          (0, _jquery.default)("#postalCodeForm").removeClass('has-error');
          (0, _jquery.default)('#glyphPostalCode').addClass("d-none");
          (0, _jquery.default)('#postalCodeIsEmpty').addClass("d-none");
        }
      });
      (0, _jquery.default)('#addressForm input').blur(function () {
        if (this.value !== '') {
          (0, _jquery.default)("#addressForm").removeClass('has-error');
          (0, _jquery.default)('#glyphAddress').addClass("d-none");
          (0, _jquery.default)('#addressIsEmpty').addClass("d-none");
        }
      });
      (0, _jquery.default)('#address1Form input').blur(function () {
        if (this.value !== '') {
          (0, _jquery.default)("#address1Form").removeClass('has-error');
          (0, _jquery.default)('#glyphAddress1').addClass("d-none");
          (0, _jquery.default)('#address1IsEmpty').addClass("d-none");
        }
      });
      (0, _jquery.default)('#cityForm input').blur(function () {
        if (this.value !== '') {
          (0, _jquery.default)("#cityForm").removeClass('has-error');
          (0, _jquery.default)('#glyphCity').addClass("d-none");
          (0, _jquery.default)('#cityIsEmpty').addClass("d-none");
        }
      });
      (0, _jquery.default)('#stateForm input').blur(function () {
        if (this.value !== '') {
          (0, _jquery.default)("#stateForm").removeClass('has-error');
          (0, _jquery.default)('#glyphState').addClass("d-none");
          (0, _jquery.default)('#stateIsEmpty').addClass("d-none");
        }
      });
      (0, _jquery.default)('#countryForm select').change(function () {
        if (this.value !== '') {
          (0, _jquery.default)("#countryForm").removeClass('has-error');
          (0, _jquery.default)('#glyphCountry').addClass("d-none");
          (0, _jquery.default)('#countryIsEmpty').addClass("d-none");
        }
      });
      (0, _jquery.default)('#phoneForm input').blur(function () {
        if (!isNaN(this.value)) {
          (0, _jquery.default)("#phoneForm").removeClass('has-error');
          (0, _jquery.default)('#glyphPhone').addClass('d-none');
          (0, _jquery.default)('#phoneIsEmpty').addClass('d-none');
          (0, _jquery.default)('#phoneIsNaN').addClass('d-none');
        }
      });
      (0, _jquery.default)('#emailForm input').blur(function () {
        if (this.value !== '') {
          (0, _jquery.default)("#emailForm").removeClass('has-error');
          (0, _jquery.default)('#glyphEmail').addClass('d-none');
          (0, _jquery.default)('#emailIsEmpty').addClass('d-none');
          (0, _jquery.default)('#emailRegexError').addClass('d-none');
        }
      });
      (0, _jquery.default)('#passwordForm input').blur(function () {
        if (this.value !== '') {
          (0, _jquery.default)("#passwordForm").removeClass('has-error');
          (0, _jquery.default)('#glyphPassword').addClass("d-none");
          (0, _jquery.default)('#passwordIsEmpty').addClass("d-none");
        }

        if (regexPass.test(this.value)) {
          (0, _jquery.default)("#passwordForm").removeClass('has-error');
          (0, _jquery.default)('#glyphPassword').addClass("d-none");
          (0, _jquery.default)('#passwordValidation').addClass("d-none");
        } else {
          (0, _jquery.default)("#passwordForm").addClass('has-error');
          (0, _jquery.default)('#glyphPassword').removeClass("d-none");
          (0, _jquery.default)('#passwordValidation').removeClass("d-none");
        }
      });
      (0, _jquery.default)('#passwordRepeatForm input').blur(function () {
        if (this.value !== '') {
          (0, _jquery.default)("#passwordRepeatForm").removeClass('has-error');
          (0, _jquery.default)('#glyphPasswordRepeat').addClass('d-none');
          (0, _jquery.default)('#passwordRepeatIsEmpty').addClass('d-none');
          (0, _jquery.default)('#passwordIsNotSame').addClass('d-none');
        }
      });
      (0, _jquery.default)('#questionForm select').change(function () {
        if (this.value !== '') {
          (0, _jquery.default)("#questionForm").removeClass('has-error');
          (0, _jquery.default)('#glyphQuestion').addClass('d-none');
          (0, _jquery.default)('#questionIsEmpty').addClass('d-none');
        }
      });
      (0, _jquery.default)('#answerForm input').blur(function () {
        if (this.value !== '') {
          (0, _jquery.default)("#answerForm").removeClass('has-error');
          (0, _jquery.default)('#glyphAnswer').addClass('d-none');
          (0, _jquery.default)('#answerIsEmpty').addClass('d-none');
        }
      });
      (0, _jquery.default)('#answerRepeatForm input').blur(function () {
        if (this.value !== '') {
          (0, _jquery.default)("#answerRepeatForm").removeClass('has-error');
          (0, _jquery.default)('#glyphAnswerRepeat').addClass('d-none');
          (0, _jquery.default)('#answerRepeatIsEmpty').addClass('d-none');
          (0, _jquery.default)('#answerIsNotSame').addClass('d-none');
        }
      });
      (0, _jquery.default)('#groupForm select').change(function () {
        if (this.value !== '') {
          (0, _jquery.default)("#groupForm").removeClass('has-error');
          (0, _jquery.default)('#glyphGroup').addClass('d-none');
          (0, _jquery.default)('#groupIsEmpty').addClass('d-none');
        }
      });

      if (flag === 0) {
        if (this.get('create')) {
          _jquery.default.ajax({
            url: '/api/v1/users',
            type: 'POST',
            data: JSON.stringify({
              user: {
                password: model.get('password'),
                firstName: model.get('firstName'),
                lastName: model.get('lastName'),
                username: model.get('username'),
                tagline: model.get('tagline'),
                email: model.get('email').toLowerCase(),
                website: model.get('website'),
                postalCode: model.get('postalCode'),
                address: model.get('address'),
                address1: model.get('address1'),
                city: model.get('city'),
                state: model.get('state'),
                country: model.get('country.content.id'),
                phone: model.get('phone'),
                answer: model.get('answer'),
                secretquestion: model.get('secretquestion.content.id'),
                authEnable: model.get('authEnable'),
                status: 'Active'
              }
            }),
            contentType: 'application/json'
          }).then(response => {
            self.store.findRecord('user', response.user.id).then(user => {
              var usergroup = self.store.createRecord('usergroup', {
                user: user,
                group: controller.get('groupCon')
              });
              usergroup.save().then(usergroup => {
                self.transitionTo('users-roles.users');
              });
            }); // _this.get('session').authenticate('authenticator:signup', response);
          }, (xhr, status, error) => {
            alert("Error: ".concat(xhr.responseText));
          });
        } else {
          model.save(); // .then(function(user){                    
          //     $.ajax({
          //         url: '/api/v1/users/reset/',
          //         type: 'POST',
          //         data: {
          //             id: model.id,
          //             siteAdmin: true, 
          //             email: model.get('email').toLowerCase(),
          //             sendPassToEmail: true,
          //             password: model.get('password'),
          //         },
          //         dataType:    'json',
          //         contentType: 'application/x-www-form-urlencoded',
          //     }).then((response) => {
          //         $.ajax({
          //             url: '/api/v1/users/resetans',
          //             type: 'POST',
          //             headers: requestHeaders,
          //             data: {
          //                 id: model.id,
          //                 siteAdmin: true, 
          //                 email: model.get('email').toLowerCase(),
          //                 sendAnswerToEmail: true,
          //                 newanswer: model.get('answer')
          //             },
          //             dataType:    'json',
          //             contentType: 'application/x-www-form-urlencoded',
          //         }).then((response) => {
          //             $.ajax({
          //                 url: '/api/v1/users/changequestion',
          //                 type: 'POST',
          //                 headers: requestHeaders,
          //                 data: {
          //                     id: model.id,
          //                     secretquestion: model.get('secretquestion.content.id')
          //                 },
          //                 dataType:    'json',
          //                 contentType: 'application/x-www-form-urlencoded',
          //             }).then((response) => {
          //                 self.transitionTo('users-roles.users');
          //             },(xhr, status, error) => {
          //             });
          //         });
          //     },(xhr, status, error) => {
          //         alert(status);
          //         alert(error);
          //         alert(`Error: ${xhr.responseText}`);
          //     });
          // });

          self.transitionTo('users-roles.users');
        }
      }
    }
  });

  _exports.default = _default;
});