define("web-map/components/ol-layer-tree", ["exports", "web-map/templates/components/ol-layer-tree"], function (_exports, _olLayerTree) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _olLayerTree.default,
    //classNames: ['ol-layer-tree']
    classNames: ['d-block tab-pane'],
    canInitLayers: false,
    canReInitLayers: false,
    canPropagateInitLayers: Ember.computed('canInitLayers', function () {
      return this.get('canInitLayers');
    }),

    didRender() {
      this._super(...arguments); //console.log('OL-LAYER-TREE component layers ...');
      //console.log(this.get('layers'));

    },

    observer: Ember.observer('canReInitLayers', function () {
      if (this.canReInitLayers) {
        console.log("Inside ol-layer-tree component canReInitLayers : " + this.canReInitLayers);
      } else {
        console.log("Inside ol-layer-tree component canReInitLayers : " + this.canReInitLayers);
      }
    })
  });

  _exports.default = _default;
});