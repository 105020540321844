define("web-map/mixins/w3o-users-roles/check-user-role", ["exports", "jquery", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _jquery, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_applicationRouteMixin.default, {
    renderFlag: false,
    setActions: Ember.A(),

    /*
    	Inject session service as authObject
    */
    authObject: Ember.inject.service('session'),

    /*
    	Check whether the current route access is allowed or not
    */
    beforeModel() {
      this._super(...arguments);
      /*
      	Initialize variables
      */


      let app = this.get('routeName').split('.');
      let dynamicValue = 'none';
      /*
      	Set dynamic param if the current route has dynamic param
      	else, set as 'none'
      */

      if (this.get('dynamicParam')) {
        dynamicValue = this.get('dynamicParam')[this.get('routeName')][this.get('dynamicKey')];

        if (!dynamicValue) {
          if (this.get('paramContainer')) {
            dynamicValue = this.get('dynamicParam')[this.get('paramContainer')][this.get('dynamicKey')];
          } else {
            dynamicValue = 'none';
          }
        }
      }
      /*
      	Check whether the route access is allowed via API call to server
      */


      _jquery.default.ajax({
        type: "POST",
        data: {
          userId: this.get('authObject.data.user.id'),
          routeName: this.get('routeName'),
          dynamicValue: dynamicValue,
          actionList: JSON.stringify(this.get('actionList'))
        },
        url: this.store.adapterFor('application').get('namespace') + '/allowedroutes/checkAccess',
        success: response => {
          this.setAllowedActions(response, dynamicValue, app);
        },
        error: (xhr, status, error) => {
          console.log('Error ' + error);
          this.transitionTo('login');
        }
      });
    },

    /*
    	While waiting for data, the template will render loading page
    	After data has been loaded, the template will render the route
    */
    renderTemplate() {
      if (this.get('renderFlag')) {
        try {
          this.render(this.get('routeName'));
        } catch (err) {// Ignore render controller error.
        }
      } else {
        this.render('loading');
      }
    },

    /*
    	Set allowed actions and send it back to route
    	If the status is false, redirect to unauthorized page
    */
    setAllowedActions: function (response, dynamicValue, app) {
      let getResponse = response;
      let temp = [];

      if (getResponse.status) {
        if (getResponse.setActions.length > 0) {
          let Variable = Ember.Object.extend({
            action: null,
            varName: null,
            status: false
          });
          response.setActions.forEach((action, index) => {
            temp.push(Variable.create({
              action: action.action,
              varName: action.varName,
              status: action.status,
              varValue: action.varValue
            }));
          });
          this.set('setActions', temp);
        }

        this.set('renderFlag', true);
        this.renderTemplate();
      } else {
        this.transitionTo('unauthorized');
      }
    }
  });

  _exports.default = _default;
});