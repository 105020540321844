define("web-map/mixins/w3o-users-roles/create-edit-route", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    appId: '',
    authObject: Ember.inject.service('session'),
    edit: false,
    setupController: function () {
      var self = this;
      var controller = this.get('controller');
      var model = this.get('currentModel');
      controller.set('model', model);

      _jquery.default.ajax({
        type: "POST",
        data: {
          application: self.get('appId')
        },
        url: self.store.adapterFor('application').get('namespace') + '/allowedroutes/getRouteModel',
        success: function (response) {
          controller.set('routeModel', response);
        },
        error: function (xhr, status, error) {
          console.log('Error ' + error);
          console.log(xhr);
          console.log(status);
        }
      });

      controller.set('appId', this.get('appId'));
      Ember.run.scheduleOnce('afterRender', this, function () {
        (0, _jquery.default)('#access').removeClass('active');
        (0, _jquery.default)('#assign').removeClass('active');
        (0, _jquery.default)('#role').removeClass('active');
        (0, _jquery.default)('#route').addClass('active');
      });
    },
    selectType: function (param) {
      var controller = this.get('controller');

      if (param) {
        if (param === 'group') {
          controller.set('parent', null);
          controller.set('model.routeName', null);
          controller.set('model.pageName', null);
        } else if (param === 'action') {
          controller.set('model.routeName', 'Action');
        } else {
          controller.set('model.routeName', null);
        }

        controller.setProperties({
          typeSelected: true,
          type: param
        });
      }
    },

    changeParent(param) {
      this.controller.set('parent', param);
    },

    saveRoute: function (model) {
      var flag = 0;
      var self = this;
      var controller = this.get('controller');
      var url;

      if (controller.get('type') !== 'group' && controller.get('type') !== 'form' && (!/\S/.test(model.get('routeName')) || !model.get('routeName'))) {
        // alert('You must enter album name!');
        (0, _jquery.default)("#routeForm").addClass("has-error");
        (0, _jquery.default)('#glyphRoute').removeClass('d-none');
        (0, _jquery.default)('#routeError').removeClass('d-none');
        flag++; // model.set('routeName', '');
      }

      if (!/\S/.test(model.get('pageName')) || !model.get('pageName')) {
        (0, _jquery.default)("#pageForm").addClass("has-error");
        (0, _jquery.default)('#glyphPage').removeClass('d-none');
        (0, _jquery.default)('#pageError').removeClass('d-none');
        flag++; // model.set('pageName', '');
      }

      if (controller.get('type') !== 'group' && controller.get('type') !== 'form' && (!/\S/.test(controller.get('parent')) || !controller.get('parent'))) {
        (0, _jquery.default)("#parentForm").addClass("has-error");
        (0, _jquery.default)('#glyphParent').removeClass('d-none');
        (0, _jquery.default)('#parentError').removeClass('d-none');
        flag++;
      }

      if (controller.get('type') === 'form' && (!/\S/.test(model.get('dynamicValue')) || !model.get('dynamicValue'))) {
        (0, _jquery.default)("#valueForm").addClass("has-error");
        (0, _jquery.default)("#glyphValue").removeClass("d-none");
        (0, _jquery.default)("#valueError").removeClass("d-none");
        flag++;
      }

      if (flag === 0) {
        controller.set('disableSubmit', true);

        if (Ember.isEmpty(model.get('dynamicValue'))) {
          model.set('dynamicValue', 'none');
        }

        if (this.get('edit')) {
          url = self.store.adapterFor('application').get('namespace') + '/allowedroutes/editRoute';
        } else {
          url = self.store.adapterFor('application').get('namespace') + '/allowedroutes/createRoute';
        }

        _jquery.default.ajax({
          type: "POST",
          data: {
            id: model.id,
            pageName: model.get('pageName'),
            routeName: model.get('routeName'),
            dynamicValue: model.get('dynamicValue'),
            parentName: controller.get('parent'),
            application: self.get('appId'),
            type: controller.get('type')
          },
          url: url,
          success: function (response) {
            controller.set('disableSubmit', false);

            if (self.get('edit')) {
              if (response.parent) {
                self.store.findRecord('allowedroute', response.parent).then(function (parent) {
                  model.set('parent', parent);
                  model.save().then(() => {
                    self.transitionTo('users-roles.role-manager.app.route', self.get('appId'));
                  });
                });
              } else {
                model.set('parent', response.parent);
                model.save().then(() => {
                  self.transitionTo('users-roles.role-manager.app.route', self.get('appId'));
                });
              }
            } else {
              self.transitionTo('users-roles.role-manager.app.route', self.get('appId'));
            }
          },
          error: function (xhr, status, error) {
            console.log('Error ' + error);
            console.log(xhr);
            console.log(status);
          }
        });
      }
    },
    goTo: function (param) {
      this.transitionTo('users-roles.role-manager.app.' + param, this.get('appId'));
    }
  });

  _exports.default = _default;
});