define("web-map/routes/foc", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "axios"], function (_exports, _authenticatedRouteMixin, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    authObject: Ember.inject.service('session'),

    beforeModel() {
      if (!this.get('authObject.data.user.is_foc')) {
        this.transitionTo("checkout");
      }
    },

    model() {
      return this.store.query('order', {
        where: {
          user: this.get('authObject.data.user.id'),
          isdelete: false,
          status: 'Process'
        }
      });
    },

    async setupController(controller, model) {
      model = model.objectAt(0);
      controller.set('model', model);
      controller.set('user', this.get('authObject.data.user'));

      let _country = await this.store.query("country", {
        id: controller.get("user.country")
      });

      controller.set("country_nicename", _country.get("firstObject").get("nicename"));
      controller.setProperties({
        disableFOCfields: false,
        foc_name: null,
        foc_department: null,
        foc_purpose: null,
        foc_remark: null,
        paymentSuccess: false
      });
    },

    actions: {
      showCancelOrder() {
        $("#cancel-order-modal-foc").modal("show");
      },

      cancelOrder() {
        let controller = this.get('controller');
        let model = controller.get('model');
        model.set('status', 'Cancelled');
        model.save().then(() => {
          $("#cancel-order-modal-foc").modal("hide");

          if (this.get('authObject.data.user.is_registered') === false) {
            window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/map"));
          } else {
            window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/stmap"));
          }
        });
      },

      submitFOC() {
        let controller = this.controller;
        controller.set("disableFOCfields", true);
        controller.get("model").setProperties({
          foc_name: controller.get("foc_name"),
          foc_department: controller.get("foc_department"),
          foc_purpose: controller.get("foc_purpose"),
          foc_remark: controller.get("foc_remark")
        });
        controller.get("model").save().then(result => {
          console.log("update order foc success");
          console.log(result);
          (0, _axios.default)({
            method: "POST",
            data: {
              order_number: controller.get("model.order_number"),
              order_type: controller.get("model.type"),
              total_price: controller.get("model.total_price"),
              user: controller.get("user")
            },
            url: this.store.adapterFor('application').get('host') + this.store.adapterFor('application').get('namespace') + '/orders/preparePdfAndSendEmail_GP_FOC'
          }).then(function (response) {
            controller.set("paymentSuccess", true);
          }).catch(function (error) {
            console.log('Error ' + error);
          });
        });
      },

      gotoHome() {
        //cannot use transitionTo because it wont load the map
        window.location.replace("".concat(this.store.adapterFor('application').get('host')));
      }

    }
  });

  _exports.default = _default;
});