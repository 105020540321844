define("web-map/services/ol-mapservice", ["exports", "axios", "ol/Observable", "ol/Overlay.js", "ol", "ol/layer/Tile", "ol/source/OSM", "ol/Feature", "ol/geom/Point", "ol/geom", "ol/sphere", "ol/source", "ol/format", "ol/format/GML2", "ol/format/GML3", "ol/format/GML32", "ol/loadingstrategy", "ol/layer/Vector.js", "ol/source/Vector.js", "ol/style.js", "ol/source/Stamen.js", "ol/layer.js", "ol/source/TileJSON.js", "ol/control.js", "ol/interaction.js", "ol/format/WKT.js", "ol/control/MousePosition.js", "ol/coordinate.js", "ol/proj.js", "proj4", "ol/proj/proj4.js", "ol/extent.js", "ol/source/Image", "ol/layer/Image", "ol/source/ImageWMS", "ol/tilegrid/WMTS", "ol/format/WMTSCapabilities", "ol/source/WMTS", "ol/size", "jquery", "web-map/config/environment"], function (_exports, _axios, _Observable, _Overlay, _ol, _Tile, _OSM, _Feature, _Point, _geom, _sphere, _source, _format, _GML, _GML2, _GML3, _loadingstrategy, _Vector, _Vector2, _style, _Stamen, _layer, _TileJSON, _control, _interaction, _WKT, _MousePosition, _coordinate, proj, _proj2, _proj3, _extent, _Image, _Image2, _ImageWMS, _WMTS, _WMTSCapabilities, _WMTS2, olSize, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import Polygon from 'ol/geom/Polygon';
  var _default = Ember.Service.extend({
    authObject: Ember.inject.service('session'),
    store: Ember.inject.service(),
    map: null,
    layers: null,
    initialExtent: null,
    initActiveLayers: null,
    defaultProjection: null,
    defaultCenter: null,
    wgs84Center: null,
    rsoCenter: null,
    initLayersG: null,
    initLayersT: null,
    initLayersW: null,
    vectorSource: null,
    featureVectorSource: null,
    vectorLayer: null,
    featureVectorLayer: null,
    testCoordinates: null,
    firstLoaded: false,
    // flag to indicate first time loaded by browser
    isLoading: false,
    // flag to indicate service is loading data for popup
    pointsArrayDab: null,
    // for app builder dashboard
    measureSource: null,
    // measuring tool array to store measuresource
    measureVectorLayer: null,
    // measuring tool array to store measurevectorlayer
    measureOutOverlay: null,
    // measuring tool array to store measureoutputoverlay
    isMobile: 'No',

    /**
     * init() now basically doing nothing
     */
    init() {
      console.log('ol map service initialized');

      this._super(...arguments);
    },

    /**
     * moved the code from init to promiseInit, because need to wait the returned data from API
     * default to Public (roleid = 1) if no parameter pass in
     */
    async promiseInit(roleid = 1, srs_init = '5247', layer_appid = "geoportal") {
      console.log('ol map service initialized');

      if (!this.cachedMaps) {
        Ember.set(this, 'cachedMaps', {});
      }

      this._super(...arguments); //console.log(proj4);
      // setup projections, register the short form with proj4
      // GDBD2009 proj4 definition has an error +no_uoff should be change to +no_off
      //proj4.defs("EPSG:5247","+proj=omerc +lat_0=4 +lonc=115 +alpha=53.31580995 +k=0.99984 +x_0=0 +y_0=0 +no_uoff +gamma=53.13010236111111 +ellps=GRS80 +units=m +no_defs");    


      _proj2.default.defs("EPSG:29873", "+proj=omerc +lat_0=4 +lonc=115 +alpha=53.31582047222222 +k=0.99984 +x_0=590476.87 +y_0=442857.65 +gamma=53.13010236111111 +ellps=evrstSS +towgs84=-679,669,-48,0,0,0,0 +units=m +no_defs");

      _proj2.default.defs("EPSG:5247", "+proj=omerc +lat_0=4 +lonc=115 +alpha=53.31580995 +k=0.99984 +x_0=0 +y_0=0 +no_off +gamma=53.13010236111111 +ellps=GRS80 +units=m +no_defs");

      _proj2.default.defs("EPSG:32650", "+proj=utm +zone=50 +datum=WGS84 +units=m +no_defs");

      _proj2.default.defs('EPSG:4326', "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees");

      (0, _proj3.register)(_proj2.default);
      const wgs84Projection = (0, proj.get)('EPSG:4326');
      const gdbd2009Projection = (0, proj.get)('EPSG:5247');
      const gdbd2009Center = (0, proj.transform)([114.7277, 4.5353], 'EPSG:4326', 'EPSG:5247');
      console.log('Projection test gdbd2009 114.7277, 4.5353 : ' + gdbd2009Center);
      console.log('Projection test gdbd2009 115, 4 : ' + (0, proj.transform)([115, 4], 'EPSG:4326', 'EPSG:5247'));
      const rsotimbalaiProjection = (0, proj.get)('EPSG:29873');
      const rsotimbalaiCenter = (0, proj.transform)([114.7277, 4.5353], 'EPSG:4326', 'EPSG:29873');
      console.log('Projection test rso timbalai 114.7277, 4.5353 : ' + rsotimbalaiCenter);
      console.log('Projection test rso timbalai 115, 4 : ' + (0, proj.transform)([115, 4], 'EPSG:4326', 'EPSG:29873'));
      this.set('defaultProjection', gdbd2009Projection);
      this.set('defaultCenter', gdbd2009Center);
      this.set('wgs84Center', [114.7277, 4.5353]);
      this.set('rsoCenter', rsotimbalaiCenter); //this.set('defaultProjection', rsotimbalaiProjection);
      //this.set('defaultCenter', rsotimbalaiCenter);    

      /* const layers = [
        new TileLayer({
          title: 'OpenStreetMap',
          exclusive: true,
          source: new OSM({
            projection: rsotimbalaiProjection,
            ration: 1
          })
        }),
        new TileLayer({
          title: 'Stamen',
          exclusive: true,
          visible: false,
          source: new Stamen({
            layer: 'watercolor'
          })
        })
      ]; */
      //to deep clean up javascript object

      function emptyObject(objRef) {
        for (var key in objRef) {
          if (objRef.hasOwnProperty(key)) {
            delete objRef[key];
          }
        }
      } //getting all the layers from database 


      let url_layersetup = this.store.adapterFor('application').get('gpserverHost') + this.store.adapterFor('application').get('namespace') + '/layersetupgroups/getLayerSetup';
      let response = await _axios.default.post(url_layersetup, {
        "roleid": roleid,
        "appid": layer_appid
      }).catch(error => {
        console.log("An error occured while loading map layers, ".concat(error));
        alert("An error occured while loading map layers, ".concat(error));
      }); // console.log(response);
      //to deep clone the javascript object (jquery)

      let results = _jquery.default.extend(true, {}, response); //console.log(results);


      let layers1 = [];
      let projections = ['EPSG:5247', 'EPSG:29873', 'EPSG:4326'];
      let layer_arr = [];
      let layergroup_arr = [];
      let layergroup_objarr = {};
      /**
       * to loop by projections, and create 3 sets of layers with different projection
       */

      projections.forEach((proj, i) => {
        results.data.layers_result.forEach(layer => {
          let layer_obj = _jquery.default.extend(true, {}, layer);

          let layer_source_obj = _jquery.default.extend(true, {}, layer.source);

          if (layer_obj.layer_type === 'image') {
            // delete layer_obj.layer_type;
            delete layer_obj.source; // console.log(proj);

            let meter_per_unit;
            layer_source_obj.projection = {};

            if (proj === 'EPSG:5247') {
              layer_source_obj.projection = gdbd2009Projection; // unit = gdbd2009Projection.getUnits();

              meter_per_unit = gdbd2009Projection.getMetersPerUnit(); // console.log('gdbd unit');
              // console.log(unit);
              // console.log(meter_per_unit);
            } else if (proj === 'EPSG:29873') {
              layer_source_obj.projection = rsotimbalaiProjection; // unit = rsotimbalaiProjection.getUnits();

              meter_per_unit = rsotimbalaiProjection.getMetersPerUnit(); // console.log('rso unit');
              // console.log(unit);
              // console.log(meter_per_unit);
            } else if (proj === 'EPSG:4326') {
              layer_source_obj.projection = wgs84Projection; // unit = wgs84Projection.getUnits();

              meter_per_unit = wgs84Projection.getMetersPerUnit(); // console.log('wgs unit');
              // console.log(unit);
              // console.log(meter_per_unit);
            }

            const inchesPerMetre = 39.37;
            const dpi = 96;

            if (layer_obj.minScale && layer_obj.maxScale) {
              layer_obj.minResolution = layer_obj.minScale / (meter_per_unit * inchesPerMetre * dpi);
              layer_obj.maxResolution = layer_obj.maxScale / (meter_per_unit * inchesPerMetre * dpi);
            }

            layer_obj.visiType = ''; //console.log('layer_obj.group_name: ' +layer_obj.group_name);

            if (typeof layer_obj.group_name == 'undefined' || layer_obj.group_name == null || layer_obj.group_name === '') {
              layer_obj.source = new _ImageWMS.default(layer_source_obj);
              layer_arr.push(new _Image2.default(layer_obj)); //console.log('Not inside a layer group');             
            } else {
              if (Boolean(layergroup_objarr[layer_obj.group_name])) {} else {
                layergroup_objarr[layer_obj.group_name] = [];
              }

              layer_obj.source = new _ImageWMS.default(layer_source_obj);
              layergroup_objarr[layer_obj.group_name].push(new _Image2.default(layer_obj)); //console.log('Debug iterating layer group');
              //console.log(layergroup_objarr[layer_obj.group_name]);

              if (layer_obj.group_seq === 'end') {
                let lg11 = new _layer.Group({
                  title: layer_obj.group_name,
                  visible: true,
                  visiType: '',
                  layers: layergroup_objarr[layer_obj.group_name]
                });
                layer_arr.push(lg11);
                layergroup_objarr[layer_obj.group_name] = [];
              }
            }
            /* new LayerGroup({
              title: 'Layer group',
              layers: [
                vector
              ]
            }) */


            emptyObject(layer_source_obj);
            emptyObject(layer_obj);
          } else if (layer_obj.layer_type === 'tile') {
            delete layer_obj.source;
            console.log('layer_obj.group_name: ' + layer_obj.group_name);
            console.log(layer.source);

            if (typeof layer_obj.group_name == 'undefined' || layer_obj.group_name == null || layer_obj.group_name === '') {
              layer_obj.source = new _OSM.default(layer.source); //new TileLayer(layer_obj);

              layer_arr.push(new _Tile.default(layer_obj)); //console.log('Not inside a layer group');
            } else {
              if (Boolean(layergroup_objarr[layer_obj.group_name])) {} else {
                layergroup_objarr[layer_obj.group_name] = [];
              }

              console.log('Check layer_obj');
              console.log(layer_obj);
              let osm_source = '';

              if (layer_obj.title == 'Google Map (WGS84)') {
                osm_source = {
                  url: 'https://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
                };
                layer_obj.source = new _OSM.default(osm_source);
                layergroup_objarr[layer_obj.group_name].push(new _Tile.default(layer_obj));
              } else if (layer_obj.title == 'Google Map (GDBD2009)') {
                let extent11 = [298469.22, 443468.67, 630678.53, 698468.74];
                gdbd2009Projection.setExtent(extent11);
                console.log('projection');
                console.log(gdbd2009Projection);
                let projectionExtent = gdbd2009Projection.getExtent();
                console.log(projectionExtent);
                let size = 5700; // size here is actually start resolution

                console.log((0, _extent.getWidth)(projectionExtent));
                console.log(size);
                let resolutions = new Array(20);
                let matrixIds = new Array(20);

                for (let z = 0; z < 20; ++z) {
                  // generate resolutions and matrixIds arrays for this WMTS
                  resolutions[z] = size / Math.pow(2, z);
                  matrixIds[z] = z;
                }

                console.log(resolutions);
                console.log(matrixIds); // NOTE: dynamic config of WMTS require the URL to the WMTS service, the starting resolution "5700", 
                // the number of zoom levels "20", the projection extent, the SRS
                // tileSize is not important, 

                layer_obj.source = new _WMTS2.default({
                  url: "https://gp2gmap.w3o.io/wmts/gm/{TileMatrixSet}/{TileMatrix}/{TileCol}/{TileRow}.png",
                  layer: 'gm',
                  matrixSet: 'gdbd',
                  format: 'image/png',
                  //projection: gdbd2009Projection,
                  tileGrid: new _WMTS.default({
                    origin: (0, _extent.getTopLeft)(projectionExtent),
                    resolutions: resolutions,
                    matrixIds: matrixIds
                  }),
                  style: 'default',
                  wrapX: false,
                  requestEncoding: "REST"
                });
                layergroup_objarr[layer_obj.group_name].push(new _Tile.default(layer_obj));
              } else if (layer_obj.title == 'OpenStreetMap (GDBD2009)') {
                let extent11 = [298469.22, 443468.67, 630678.53, 698468.74];
                gdbd2009Projection.setExtent(extent11);
                console.log('projection');
                console.log(gdbd2009Projection);
                let projectionExtent = gdbd2009Projection.getExtent();
                console.log(projectionExtent); //looks like this size formula to get resolutions is not correct
                //let size = getWidth(projectionExtent) / 256;

                let size = 5700; // size here is actually start resolution

                console.log((0, _extent.getWidth)(projectionExtent));
                console.log(size);
                let resolutions = new Array(20);
                let matrixIds = new Array(20);

                for (let z = 0; z < 20; ++z) {
                  // generate resolutions and matrixIds arrays for this WMTS
                  resolutions[z] = size / Math.pow(2, z);
                  matrixIds[z] = z;
                }

                console.log(resolutions);
                console.log(matrixIds); // NOTE: dynamic config of WMTS require the URL to the WMTS service, the starting resolution "5700", 
                // the number of zoom levels "20", the projection extent, the SRS
                // tileSize is not important, 

                layer_obj.source = new _WMTS2.default({
                  url: "https://gp2mp2.w3o.io/wmts/osm/{TileMatrixSet}/{TileMatrix}/{TileCol}/{TileRow}.png",
                  layer: 'osm',
                  matrixSet: 'gdbd',
                  format: 'image/png',
                  //projection: gdbd2009Projection,
                  tileGrid: new _WMTS.default({
                    origin: (0, _extent.getTopLeft)(projectionExtent),
                    resolutions: resolutions,
                    matrixIds: matrixIds
                  }),
                  style: 'default',
                  wrapX: false,
                  requestEncoding: "REST"
                });
                /**  This method of setting up WMTS layer by querying the WMTSCapabilities.xml is not suitable here 
                 *   as the WMTS setup happened in a promise, that execute after certain objects (layer_obj) are emptied. 
                 * 
                let parser = new WMTSCapabilities();
                fetch('http://gp2mp2.w3o.io/wmts/1.0.0/WMTSCapabilities.xml',{mode: 'cors'}).then(function(response) {
                  return response.text();
                }).then(function(text) {
                  let result = parser.read(text);
                  console.log('WMTS options from getcapabilities...');
                  console.log(text);
                  console.log(result);
                  let options = optionsFromCapabilities(result, {
                    layer: 'osm',
                    matrixSet: 'gdbd'
                  });
                  console.log(options);
                  //delete options.projection;
                  //delete options.tileGrid.tileSizes_;
                  //options.tileGrid.resolutions = options.tileGrid.resolutions_;
                  //options.tileGrid.matrixIds = options.tileGrid.matrixIds_;
                  //options.tileGrid.origins = options.tileGrid.origins_;
                  //options.url = options.urls[0];
                  layer_obj.source = new WMTS(options);
                  //layergroup_objarr[layer_obj.group_name].push(new TileLayer(layer_obj)); 
                 });
                */

                layergroup_objarr[layer_obj.group_name].push(new _Tile.default(layer_obj));
              } else {
                osm_source = layer.source;
                layer_obj.source = new _OSM.default(osm_source);
                layergroup_objarr[layer_obj.group_name].push(new _Tile.default(layer_obj));
              } //console.log('Debug iterating layer group');


              if (layer_obj.group_seq === 'end') {
                let lg11 = new _layer.Group({
                  title: layer_obj.group_name,
                  layers: layergroup_objarr[layer_obj.group_name]
                });
                layer_arr.push(lg11);
                layergroup_objarr[layer_obj.group_name] = [];
              }
            }

            emptyObject(layer_obj);
          }
        });

        if (proj === 'EPSG:5247') {
          this.set('initLayersG', layer_arr);
        } else if (proj === 'EPSG:29873') {
          this.set('initLayersT', layer_arr);
        } else if (proj === 'EPSG:4326') {
          this.set('initLayersW', layer_arr);
        }

        layer_arr = [];
        layergroup_objarr = {};
      }); //console.log(this.get('initLayersG'));
      //console.log(this.get('initLayersT'));
      //console.log(this.get('initLayersW'));
      //console.log('finally!!!!!!');
      //TODO

      if (srs_init === '5247') {
        this.set('defaultProjection', gdbd2009Projection);
        this.set('defaultCenter', gdbd2009Center);
        this.set('initActiveLayers', this.get('initLayersG'));
      } else if (srs_init === '29873') {
        this.set('defaultProjection', rsotimbalaiProjection);
        this.set('defaultCenter', rsotimbalaiCenter);
        this.set('initActiveLayers', this.get('initLayersT'));
      } else if (srs_init === '4326') {
        this.set('defaultProjection', wgs84Projection);
        this.set('defaultCenter', [114.7277, 4.5353]);
        this.set('initActiveLayers', this.get('initLayersW'));
      }
    },

    _setProjection(code, name, proj4def, bbox) {
      console.log("Inside ol-mapservice set projection = " + code);
      console.log("Inside ol-mapservice set projection = " + name);
      console.log(proj4def);
      console.log(bbox);
      let map = this.get('map');

      if (code === null || name === null || proj4def === null || bbox === null) {
        map.setView(new _ol.View({
          projection: 'EPSG:3857',
          center: [0, 0],
          zoom: 1
        }));
        return 0;
      }

      var newProjCode = 'EPSG:' + code;

      _proj2.default.defs(newProjCode, proj4def);

      (0, _proj3.register)(_proj2.default);
      var newProj = (0, proj.get)(newProjCode);
      var fromLonLat = (0, proj.getTransform)('EPSG:4326', newProj); // very approximate calculation of projection extent

      var extent = (0, _extent.applyTransform)([bbox[1], bbox[2], bbox[3], bbox[0]], fromLonLat);
      console.log(extent);
      newProj.setExtent(extent);
      console.log(newProj);
      let newView = new _ol.View({
        projection: newProj
      });
      console.log(map.getView().getProjection());
      /* let clayer1 = map.getLayers();
      clayer1.forEach(layer => {
        console.log(layer);
        map.removeLayer(layer);
      });*/
      // wrong way as map rebuild layer index after each removal

      map.setView(newView); //newView.fit(extent);

      if (code == '4326') {
        newView.setCenter(this.get('wgs84Center'));
        newView.setZoom(10);
      } else if (code == '29873') {
        newView.setCenter(this.get('rsoCenter'));
        newView.setZoom(5);
      } else if (code == '5247') {
        newView.setCenter(this.get('defaultCenter'));
        newView.setZoom(3);
      }
      /* newView.fit(extent, map.getSize(), {
        constrainResolution: false
      }); */


      return 1;
    },

    searchEPSG(self, query) {
      console.log("Inside ol-mapservice srs query = " + query);
      fetch('https://epsg.io/?format=json&q=' + query).then(function (response) {
        return response.json();
      }).then(function (json) {
        var results = json['results'];
        console.log("Inside ol-mapservice srs query result = " + results);

        if (results && results.length > 0) {
          for (var i = 0, ii = results.length; i < ii; i++) {
            var result = results[i];

            if (result) {
              var code = result['code'];
              var name = result['name'];
              var proj4def = result['proj4'];
              var bbox = result['bbox'];

              if (code && code.length > 0 && proj4def && proj4def.length > 0 && bbox && bbox.length == 4) {
                let abc = self._setProjection(code, name, proj4def, bbox);

                return;
              }
            }
          }
        }

        let abc = this._setProjection(null, null, null, null);
      });
    },

    // 1 inch = 2.54 cm, 1 cm = 0.39 inch
    // according to css3 and css 2.1, browsers fix to 96 dpi
    // need to write detail comments on how we get scale from resolutions and dpi
    // need to code the scale and resolutions conversion values 
    // base on the testing code below, the getResolution value and calc resolution value are the same, 
    // it show that dpi value not relevant as calcScale dpi net off against calc res dpi and net off meters_per_unit , net off inches per meter
    // The resolution is the size of 1 pixel in map units. Let's say your map projection is EPSG:3857 whose unit is meters. 
    // For example if the current resolution is 20000 m/px, this means that 1 px represents 20000m.
    // convert scale to resolution 
    // scale = 10000 
    // res = 10000 / ( 1 * 39.37 * 96) = 2.645838625010583354500042333418
    // scale = 5000 
    // res = 5000 / ( 1 * 39.37 * 96) = 1.322919312505291677250021166709
    // scale = 2500 
    // res = 2500 / ( 1 * 39.37 * 96) = 0.6614596562526458386250105833545
    calcMapScale(map, dpi) {
      var unit = map.getView().getProjection().getUnits();
      var resolution = map.getView().getResolution();
      var inchesPerMetre = 39.37;
      return resolution * proj.METERS_PER_UNIT[unit] * inchesPerMetre * dpi;
    },

    debugMapScale() {
      let map = this.get('map');
      var extent = map.getView().calculateExtent(map.getSize());
      var bottomLeft = (0, proj.toLonLat)((0, _extent.getBottomLeft)(extent));
      var topRight = (0, proj.toLonLat)((0, _extent.getTopRight)(extent));
      var res1 = map.getView().getResolution();
      var zoom = map.getView().getZoom(); //var dpi = 25.4 / 0.28;

      var dpi = 96;
      var cScale = this.calcMapScale(map, dpi);
      var units = map.getView().getProjection().getUnits();
      var mpu = proj.METERS_PER_UNIT[units];
      var res = cScale / (mpu * 39.37 * dpi);
      console.log("Map resolution = " + res1);
      console.log("Map zoom = " + zoom);
      console.log("Map units = " + units);
      console.log("Map mpu = " + mpu);
      console.log("Map calc res = " + res);
      console.log("Map calc scale = " + cScale);
    },

    _calcMapResolution(map, scaleVal, dpiVal) {
      var units = map.getView().getProjection().getUnits();
      var mpu = proj.METERS_PER_UNIT[units];
      var res = scaleVal / (mpu * 39.37 * dpiVal);
      return res;
    },

    changeMapScale(self, scvalue) {
      let map = this.get('map');

      let res1 = self._calcMapResolution(map, scvalue, 96);

      console.log("Scale Input the resolution = " + res1);
      let view1 = map.getView();
      view1.setResolution(res1);
    },

    resetPointsArrayDab() {
      this.set('pointsArrayDab', null);
    },

    setPointsArrayDab(coord) {
      let parrdab = this.get('pointsArrayDab');

      if (parrdab == null) {
        parrdab = [];
      } else {
        parrdab.push(coord);
      }

      this.set('pointsArrayDab', parrdab);
      console.log('hoichong setPointsArrayDab...');
      console.log(this.get('pointsArrayDab'));
    },

    getPointsArrayDab() {
      return this.get('pointsArrayDab');
    },

    rePlotPointsDab(self) {
      let map = this.get('map');
      console.log('ol-map-panel call rePlotPointsDab...');
      console.log('ol-map-panel' + map);
      let markerVectorLayer = this.get('vectorLayer');
      console.log('ol-map-panel' + markerVectorLayer);
      map.addLayer(markerVectorLayer);
      const vectorSource = this.get('vectorSource');
      console.log('ol-map-panel' + vectorSource);
      vectorSource.refresh();
      /* let parrdab = this.getPointsArrayDab();
      if (parrdab != null && typeof parrdab != 'undefined') {
        parrdab.map(coord => {
          this.plotOnePoint(map, coord);
        });  
      } */
    },

    zoomToCenter(marker) {
      let map = this.get('map');
      let currproj = map.getView().getProjection();
      let [lon, lat] = marker.reverse();
      console.log('ol-map-panel call zoomToCenter...');
      console.log('ol-map-panel' + map);
      console.log('ol-map-panel' + currproj);
      console.log('ol-map-panel' + marker);
      map.setView(new _ol.View({
        projection: currproj,
        center: marker,
        zoom: 17
      }));
    },

    initVectorSource(map) {
      // coordinate is just an array of lon, lat
      const testMarker = (0, proj.transform)([114.867325, 4.901469], 'EPSG:4326', 'EPSG:29873'); //const testMarker = projTransform([114.912709, 4.959161], 'EPSG:4326', 'EPSG:29873');
      //console.log('hoichong testMarker is : ' + testMarker);

      const marker = new _Feature.default({
        geometry: new _Point.default(testMarker) // Coordinates of a point in 0,0 or at Rimba

      }); //console.log('hoichong marker is at: '+marker);

      var vectorSource = new _Vector2.default({
        features: [marker]
      });
      this.set('vectorSource', vectorSource);
      /*
      vectorSource.addFeature(moremarker);    
      */

      let geojsonObject = {
        'type': 'FeatureCollection',
        'crs': {
          'type': 'name',
          'properties': {
            'name': 'EPSG:29873'
          }
        },
        'features': [{
          'type': 'Feature',
          'geometry': {
            'type': 'Polygon',
            'coordinates': [[[579408.7605180747, 548938.8769024849], [579370.854808159, 548938.899177656], [579368.050886337, 548937.2240620262], [579368.3047903304, 548934.3189382957], [579367.4148947786, 548916.5328913837], [579370.7069234853, 548913.8207712442], [579406.4270153721, 548914.0410966236], [579408.7605180747, 548938.8769024849]]]
          }
        }]
      }; //console.log(geojsonObject);

      /*let feGeoJson = new GeoJSON({
        dataProjection: 'EPSG:29873',
        featureProjection: 'EPSG:29873'
      }); */
      //console.log(feGeoJson);
      //console.log(feGeoJson.readProjection(geojsonObject));

      /* 
      let feVectorSource = new VectorSource({
        features: feGeoJson.readFeatures(geojsonObject),
        useSpatialIndex: false
      });
      console.log(feVectorSource);    
      */

      var markerVectorLayer = new _Vector.default({
        title: 'Points',
        visible: false,
        zIndex: 100,
        source: vectorSource,
        style: new _style.Style({
          stroke: new _style.Stroke({
            color: 'rgba(255, 0, 0, 1.0)',
            width: 2
          })
        })
      });

      if (_environment.default.W3O_openlayers.deployment == 'RAD') {
        map.addLayer(markerVectorLayer); // add this vectorlayer so can plot points for gis app builder, comment this out for GP
      }

      this.set('vectorLayer', markerVectorLayer);
      var feVectorLayer = new _Vector.default({
        title: 'Features',
        visible: false,
        //source: feVectorSource,
        zIndex: 100,
        style: new _style.Style({
          stroke: new _style.Stroke({
            color: '#0000ff',
            width: 3
          }),
          fill: new _style.Fill({
            color: 'rgba(0, 0, 255, 0.2)'
          })
        })
      }); //map.addLayer(feVectorLayer);

      this.set('featureVectorLayer', feVectorLayer);
    },

    processGMLFromFeature(arrayFeatures) {
      console.log("processGMLFromFeature Starting");
      const map = this.get('map');
      let current_srs = map.getView().getProjection().getCode();
      let initOptions = {
        featureNS: "http://www.opengis.net/gml",
        featureType: "featureMember",
        srsName: current_srs
      };
      let gml3Object = new _GML3.default(initOptions); //let gml3Object = new GML3(initOptions); 

      let gmlOptions = {
        dataProjection: current_srs,
        featureProjection: current_srs
      }; //console.log(map);

      console.log(current_srs);
      console.log(gml3Object);
      console.log(gmlOptions);
      let gmlString = gml3Object.writeFeatures(arrayFeatures, gmlOptions);
      return gmlString;
    },

    sort_by_key(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },

    drawPolygonByIndex(polyPoints) {
      // to be fixed and enhance further
      // polyPoints will be an array of objects, each object has 2 key value
      // index key is to indicate sequence of vertice/point in the polygon 
      // coordinate key is the actual coordinate of the vertice/point, coordinate is X, Y
      const sortedPoints = this.sort_by_key(polyPoints, 'index');
      let pointsArray = []; // outer poly ring

      for (let i = 0; i < sortedPoints.length; i++) {
        pointsArray.push(sortedPoints[i].coordinate);
      }

      ;
      pointsArray.push(sortedPoints[0].coordinate); // final push to close the outer polygon (ring)    

      console.log('drawPolygonByIndex prepared pointsArray');
      console.log(pointsArray); // prepare additional attributes for Feature object
      // the first point of the polygon will be used

      const lotnum = sortedPoints[0].attributes.lot_number;
      const bookcode = sortedPoints[0].attributes.book_code;
      const distcode = sortedPoints[0].attributes.distcode; // array of an array of polygon linear rings of an array of coordinate array, complete definition of polygon 
      // most outer array is Polygon, first inner array is Outer poly ring, subsequent inner arrays are holes in the outer polygon
      // the most inner arrays are the coordinate array x,y  

      let properPolyArray = [pointsArray];
      let poly1 = new _geom.Polygon(properPolyArray);
      let feature1 = new _Feature.default({
        geometry: poly1,
        LOTNUM: lotnum,
        BOOKCODE: bookcode,
        DISTCODE: distcode
      });
      let style1 = new _style.Style({
        //fill: new Fill({color: '#666666'}),
        stroke: new _style.Stroke({
          color: '#ffffff',
          width: 3
        })
      });
      console.log(poly1);
      console.log(feature1);
      feature1.setStyle(style1);
      let vs1 = this.get('vectorSource');
      vs1.addFeature(feature1);
      vs1.refresh();
      return feature1;
    },

    drawPolygon(map, poly1) {// to be fixed and enhance further

      /* const f1 = new Feature({
        geometry: new Polygon([
          [
            [2, 2],
            [98, 2],
            [2, 98],
            [2, 2]
          ]
        ])
      })
       const source = new VectorSource({
        features: [
          f1
        ]
      })
       const sourceRecord = this.get('store').createRecord('ol-source-vector', {
        source
      })
       this.set('sourceRecord', sourceRecord)
       const vector = new VectorLayer({
        title: 'Vector',
        source: source
      }) */
      //map.getView().fit(vectorSource.getExtent());

      /* const draw = new Draw({ vectorSource, type: 'Point' });
      map.addInteraction(draw); */
    },

    plotManyPoints(map, markers) {
      console.log('hoichong plotmanypoints markers param: ');
      console.log(markers);
      const vectorSource = this.get('vectorSource');
      console.log(markers.popObject());
      console.log(markers.popObject());
      markers.forEach(function (marker) {
        console.log("Yeah");
      });
      /*
      markers.forEach(function(marker) {
        console.log(marker);
         let [lon, lat] = marker.reverse(); // marker permanently change by reverse
        //console.log('hoichong lat is: '+lat);
        //console.log('hoichong lon is: '+lon);
         const point3 = new Feature({
          geometry: new Point(marker),
        });      
        vectorSource.addFeature(point3);
        //console.log('hoichong point3: ' + point3);
        vectorSource.refresh();      
       }); */
    },

    clearVectorSource() {
      const vectorSource = this.get('vectorSource');
      vectorSource.clear();
    },

    plotOnePoint(map, marker) {
      console.log('hoichong marker param: ' + marker);
      let [lon, lat] = marker.reverse(); // marker permanently change by reverse

      console.log('hoichong plotonepoint lat is: ' + lat);
      console.log('hoichong plotonepoint lon is: ' + lon);
      console.log('hoichong plotonepoint map param: ' + map);
      console.log(marker);
      const point2 = new _Feature.default({
        geometry: new _Point.default(marker)
      });
      point2.setStyle(new _style.Style({
        image: new _style.Circle({
          radius: 7,
          //fill: new Fill({color: '#666666'}),
          stroke: new _style.Stroke({
            color: '#ff0000',
            width: 3
          })
        })
      }));
      const vectorSource = this.get('vectorSource');
      vectorSource.addFeature(point2);
      console.log('hoichong plotonepoint point2: ' + point2); //this.set('initialExtent', this.get('vectorLayer').getSource().getExtent())

      vectorSource.refresh();
      /*
      const vectorSource2 = new VectorSource({
        features: [point2]
      });
      console.log('hoichong vectorSource2: '+vectorSource2);    
       const markerVectorLayer2 = new VectorLayer({
        title: 'Points2',
        source: vectorSource2,
      });
      console.log('hoichong markerVectorLayer2: '+markerVectorLayer2);
       let map2 = this.get('map');
      map2.addLayer(markerVectorLayer2); 
      */
    },

    plotMultiPoint(markers) {
      let features = [];
      markers.forEach(marker => {
        let newmarker = [parseFloat(marker.lat), parseFloat(marker.lon)];
        newmarker.reverse();
        let point2 = new _Feature.default({
          geometry: new _Point.default(newmarker),
          ...marker.attributes // STNNUM: marker.attributes.stnnum

        });
        point2.setStyle(new _style.Style({
          image: new _style.Circle({
            radius: 7,
            stroke: new _style.Stroke({
              color: '#ff0000',
              width: 3
            })
          })
        }));
        let vectorSource = this.get('vectorSource');
        vectorSource.addFeature(point2);
        vectorSource.refresh();
        features.push(point2);
      });
      return features;
    },

    getOmap() {
      console.log('hoichong ol-mapservice getOmap');
      const store = this.get('store');
      let self = this;
      /* let pos = fromLonLat([16.3725, 48.208889]);
        const mapOverlay1 = new Overlay({
        stopEvent: false,
        position: pos,
      }); */

      let PointerControl = function (Control) {
        function PointerControl(opt_options) {
          var options = opt_options || {};
          var button = document.createElement('button');
          button.innerHTML = '<i class="fas fa-mouse-pointer"></i>';
          button.setAttribute('title', 'Use Pointer');
          var element = document.createElement('div');
          element.className = 'change-pointer ol-unselectable ol-control';
          element.appendChild(button);
          Control.call(this, {
            element: element,
            target: options.target
          });
          button.addEventListener('click', this.handlePointerControl.bind(this), false);
        }

        if (Control) PointerControl.__proto__ = Control;
        PointerControl.prototype = Object.create(Control && Control.prototype);
        PointerControl.prototype.constructor = PointerControl;

        PointerControl.prototype.handlePointerControl = function handlePointerControl() {
          //this.getMap().getView().setRotation(0);
          let map_panel_arr = document.getElementsByClassName(_environment.default.W3O_openlayers.ol_map_panel);
          map_panel_arr[0].style.cursor = "default";
          console.log('Just change the mouse cursor to default...');
        };

        return PointerControl;
      }(_control.Control);

      let LayersInfoControl = function (Control) {
        function LayersInfoControl(opt_options) {
          var options = opt_options || {};
          var button = document.createElement('button');
          button.innerHTML = '<i class="fas fa-info"></i>';
          button.setAttribute('title', 'View Feature Info');
          var element = document.createElement('div');
          element.className = 'change-infop ol-unselectable ol-control';
          element.appendChild(button);
          Control.call(this, {
            element: element,
            target: options.target
          });
          button.addEventListener('click', this.handleLayersInfo.bind(this), false);
        }

        if (Control) LayersInfoControl.__proto__ = Control;
        LayersInfoControl.prototype = Object.create(Control && Control.prototype);
        LayersInfoControl.prototype.constructor = LayersInfoControl;

        LayersInfoControl.prototype.handleLayersInfo = function handleLayersInfo() {
          //this.getMap().getView().setRotation(0);
          let map_panel_arr = document.getElementsByClassName(_environment.default.W3O_openlayers.ol_map_panel);
          map_panel_arr[0].style.cursor = "pointer";
          console.log('Just change the mouse cursor to info...');
        };

        return LayersInfoControl;
      }(_control.Control);

      let PurchaseControl = function (Control) {
        function PurchaseControl(opt_options) {
          var options = opt_options || {};
          var button = document.createElement('button');
          button.innerHTML = '<img src="/images/buy.png">';
          button.setAttribute('title', 'Purchase');
          var element = document.createElement('div');
          element.className = 'change-purchasep ol-unselectable ol-control';
          element.appendChild(button);
          Control.call(this, {
            element: element,
            target: options.target
          });
          button.addEventListener('click', this.handleLayersInfo.bind(this), false);
        }

        if (Control) PurchaseControl.__proto__ = Control;
        PurchaseControl.prototype = Object.create(Control && Control.prototype);
        PurchaseControl.prototype.constructor = PurchaseControl;

        PurchaseControl.prototype.handleLayersInfo = function handleLayersInfo() {
          //this.getMap().getView().setRotation(0);
          let map_panel_arr = document.getElementsByClassName(_environment.default.W3O_openlayers.ol_map_panel);
          map_panel_arr[0].style.cursor = "grab";
          console.log('Just change the mouse cursor to purchase...');
        };

        return PurchaseControl;
      }(_control.Control);

      let MeasureInfoControl = function (Control) {
        function MeasureInfoControl(opt_options) {
          var options = opt_options || {};
          var button = document.createElement('button');
          button.innerHTML = '<i class="fas fa-ruler"></i>';
          button.setAttribute('title', 'Length Measure');
          var element = document.createElement('div');
          element.className = 'change-measurep ol-unselectable ol-control';
          element.appendChild(button);
          Control.call(this, {
            element: element,
            target: options.target
          });
          button.addEventListener('click', this.handleMeasureInfo.bind(this), false);
        }

        if (Control) MeasureInfoControl.__proto__ = Control;
        MeasureInfoControl.prototype = Object.create(Control && Control.prototype);
        MeasureInfoControl.prototype.constructor = MeasureInfoControl;

        MeasureInfoControl.prototype.handleMeasureInfo = function handleMeasureInfo() {
          //this.getMap().getView().setRotation(0);
          let map_panel_arr = document.getElementsByClassName(_environment.default.W3O_openlayers.ol_map_panel);
          map_panel_arr[0].style.cursor = "crosshair";
          console.log('Just change the mouse cursor to Measure...');
        };

        return MeasureInfoControl;
      }(_control.Control);

      let ClearMeasureInfoControl = function (Control) {
        function ClearMeasureInfoControl(opt_options) {
          var options = opt_options || {};
          var button = document.createElement('button');
          button.innerHTML = '<i class="fas fa-eraser"></i>';
          button.setAttribute('title', 'Erase All Measures');
          var element = document.createElement('div');
          element.className = 'change-erasemeasurep ol-unselectable ol-control';
          element.appendChild(button);
          Control.call(this, {
            element: element,
            target: options.target
          });
          button.addEventListener('click', this.handleClearMeasureInfo.bind(this), false);
        }

        if (Control) ClearMeasureInfoControl.__proto__ = Control;
        ClearMeasureInfoControl.prototype = Object.create(Control && Control.prototype);
        ClearMeasureInfoControl.prototype.constructor = ClearMeasureInfoControl;

        ClearMeasureInfoControl.prototype.handleClearMeasureInfo = function handleClearMeasureInfo() {
          //this.getMap().getView().setRotation(0);
          let map_panel_arr = document.getElementsByClassName(_environment.default.W3O_openlayers.ol_map_panel);
          map_panel_arr[0].style.cursor = "not-allowed";
          console.log('Just change the mouse cursor to Not Allowed...'); // measureSource and measureVectorLayer not setup yet.
          //console.log(this.get('measureSource'));
          //console.log(this.get('measureVectorLayer'));

          let map1 = this.getMap();
          map1.dispatchEvent('singleclick');
        };

        return ClearMeasureInfoControl;
      }(_control.Control);

      let OpenNLUMPLegendControl = function (Control) {
        function OpenNLUMPLegendControl(opt_options) {
          var options = opt_options || {};
          var button = document.createElement('button');
          button.innerHTML = '<i class="fas fa-book"></i>';
          button.setAttribute('title', 'Master Plan Legends');
          var element = document.createElement('div');
          element.className = 'change-nlumplegendp ol-unselectable ol-control';
          element.appendChild(button);
          Control.call(this, {
            element: element,
            target: options.target
          });
          button.addEventListener('click', this.handleClearMeasureInfo.bind(this), false);
        }

        if (Control) OpenNLUMPLegendControl.__proto__ = Control;
        OpenNLUMPLegendControl.prototype = Object.create(Control && Control.prototype);
        OpenNLUMPLegendControl.prototype.constructor = OpenNLUMPLegendControl;

        OpenNLUMPLegendControl.prototype.handleClearMeasureInfo = function handleClearMeasureInfo() {
          //this.getMap().getView().setRotation(0);
          let map_panel_arr = document.getElementsByClassName(_environment.default.W3O_openlayers.ol_map_panel);
          map_panel_arr[0].style.cursor = "help";
          console.log('Just change the mouse cursor to help...'); // measureSource and measureVectorLayer not setup yet.
          //console.log(this.get('measureSource'));
          //console.log(this.get('measureVectorLayer'));

          let map1 = this.getMap();
          map1.dispatchEvent('singleclick');
          map_panel_arr[0].style.cursor = "default";
        };

        return OpenNLUMPLegendControl;
      }(_control.Control);

      let OpenScaleControl = function (Control) {
        function OpenScaleControl(opt_options) {
          var options = opt_options || {};
          var button = document.createElement('button');
          button.innerHTML = '<i class="fas fa-eye"></i>';
          button.setAttribute('title', 'Select scale');
          var element = document.createElement('div');
          element.className = 'change-scalep ol-unselectable ol-control';
          element.appendChild(button);
          Control.call(this, {
            element: element,
            target: options.target
          });
          button.addEventListener('click', this.handleClearMeasureInfo.bind(this), false);
        }

        if (Control) OpenScaleControl.__proto__ = Control;
        OpenScaleControl.prototype = Object.create(Control && Control.prototype);
        OpenScaleControl.prototype.constructor = OpenScaleControl;

        OpenScaleControl.prototype.handleClearMeasureInfo = function handleClearMeasureInfo() {
          //this.getMap().getView().setRotation(0);
          let map_panel_arr = document.getElementsByClassName(_environment.default.W3O_openlayers.ol_map_panel);
          map_panel_arr[0].style.cursor = "text";
          console.log('Just change the mouse cursor to text...'); // measureSource and measureVectorLayer not setup yet.
          //console.log(this.get('measureSource'));
          //console.log(this.get('measureVectorLayer'));

          let map1 = this.getMap();
          map1.dispatchEvent('singleclick');
          map_panel_arr[0].style.cursor = "default";
        };

        return OpenScaleControl;
      }(_control.Control);

      let MobileGuideControl = function (Control) {
        function MobileGuideControl(opt_options) {
          var options = opt_options || {};
          var button = document.createElement('button');
          button.innerHTML = '<i class="fas fa-question-circle"></i>';
          button.setAttribute('title', 'Open Mobile Guide');
          var element = document.createElement('div');
          element.className = 'change-guidep ol-unselectable ol-control';
          element.appendChild(button);
          Control.call(this, {
            element: element,
            target: options.target
          });
          button.addEventListener('click', this.handleClearMeasureInfo.bind(this), false);
        }

        if (Control) MobileGuideControl.__proto__ = Control;
        MobileGuideControl.prototype = Object.create(Control && Control.prototype);
        MobileGuideControl.prototype.constructor = MobileGuideControl;

        MobileGuideControl.prototype.handleClearMeasureInfo = function handleClearMeasureInfo() {
          //this.getMap().getView().setRotation(0);
          let map_panel_arr = document.getElementsByClassName(_environment.default.W3O_openlayers.ol_map_panel);
          map_panel_arr[0].style.cursor = "wait";
          console.log('Just change the mouse cursor to wait...'); // measureSource and measureVectorLayer not setup yet.
          //console.log(this.get('measureSource'));
          //console.log(this.get('measureVectorLayer'));

          let map1 = this.getMap();
          map1.dispatchEvent('singleclick');
          map_panel_arr[0].style.cursor = "default";
        };

        return MobileGuideControl;
      }(_control.Control);

      let defaultMapZoom;

      if (_environment.default.W3O_openlayers.deployment == 'RAD') {
        defaultMapZoom = 10;
      } else {
        defaultMapZoom = 3; // Change default map zoom when it open from mobile device

        if (this.get('isMobile') === 'Yes') {
          defaultMapZoom = 1.5;
        }
      }

      let pointerControls = [new LayersInfoControl(), new MeasureInfoControl(), new PurchaseControl(), new PointerControl(), new ClearMeasureInfoControl() // new OpenNLUMPLegendControl()     //NLUMP legend disabled (request by Survey)
      ];

      if (this.get('isMobile') === 'Yes') {
        pointerControls.push(new OpenScaleControl());
        pointerControls.push(new MobileGuideControl());
      } else {
        if (_environment.default.W3O_openlayers.deployment === 'GEOPORTAL') {
          pointerControls.push(new OpenNLUMPLegendControl());
          pointerControls.push(new PurchaseControl());
        }
      }

      const map = new _ol.Map({
        controls: (0, _control.defaults)().extend(pointerControls),
        target: 'map',
        layers: this.get('initActiveLayers'),
        view: new _ol.View({
          projection: this.get('defaultProjection'),
          center: this.get('defaultCenter'),
          zoom: defaultMapZoom,
          // need to have different zoom for Geoportal or RAD TODO
          rotation: 0,
          enableRotation: false
        })
      }); //map.addOverlay(mapOverlay1);

      map.addControl(new _control.ScaleLine({
        units: 'metric'
      }));
      /* map.addControl(new MousePosition({
        coordinateFormat: c => parseInt(c[0], 10) + ', ' + parseInt(c[1], 10),
        undefinedHTML: ''
      })); */

      map.addControl(new _MousePosition.default({
        coordinateFormat: (0, _coordinate.createStringXY)(4),
        undefinedHTML: ''
      }));
      const mousePositionControl = new _MousePosition.default({
        coordinateFormat: (0, _coordinate.createStringXY)(4),
        // projection: 'EPSG:4326',
        // comment the following two lines to have the mouse position
        // be placed within the map.
        className: 'custom-mouse-position',
        target: document.getElementById('mouse-position'),
        undefinedHTML: '&nbsp;'
      }); //map.addControl(mousePositionControl);

      this.set('map', map);
      const initialExtent = null;
      this.set('initialExtent', initialExtent);
      console.log('hoichong ol-mapservice getOmap map initialextent done');
      this.initVectorSource(map);
      console.log('hoichong ol-mapservice getOmap map initVectorSource done');
      var currZoom = map.getView().getZoom();
      console.log('hoichong ol-mapservice getOmap map currZoom: ' + currZoom);
      map.on('moveend', function (e) {
        var newZoom = map.getView().getZoom();

        if (currZoom != newZoom) {
          console.log('Inside ol-mapservice moveend event, zoom end, new zoom: ' + newZoom);
          currZoom = newZoom; //var dpi = 25.4 / 0.28;

          const dpi = 96; // var cScale = this.calcMapScale(map,dpi);  // calcMapScale is not a function (maybe because in an event callback)

          let unit = map.getView().getProjection().getUnits();
          let resolution = map.getView().getResolution();
          const inchesPerMetre = 39.37;
          let cScale = resolution * proj.METERS_PER_UNIT[unit] * inchesPerMetre * dpi;
          console.log('Inside ol-mapservice moveend event, zoom end, new scale & resolution : ' + cScale + '  ' + resolution);
          console.log(proj.METERS_PER_UNIT[unit] + '    ' + inchesPerMetre); //let zoom1 = map.getView().getZoom();
          //console.log(zoom1);
          //console.log(map.getView().getResolutionForZoom(zoom1));

          let scaleRoundVal = Math.ceil(cScale); // scaleElement = document.getElementById('inputScale');

          document.getElementById('inputScale').value = scaleRoundVal.toString();

          let _c_srs = map.getView().getProjection().getCode();

          console.log('Get SRS ' + _c_srs);

          function controlLayerEye(layer, currentScale) {
            let ltitle = 'Canvas';

            if (layer.values_.title !== undefined) {
              ltitle = layer.values_.title.replace(/\s+/g, '');
            }

            let elementId = "layer-item-id-".concat(ltitle); // id of the layer input element (not the eye icon) 

            let eyeId = "layer-item-eye-id-".concat(ltitle); // id of the layer eye icon 

            console.log('elementId ' + elementId);

            if (layer.values_.minScale !== undefined && layer.values_.minScale >= 0) {
              if (currentScale > layer.values_.maxScale || currentScale < layer.values_.minScale) {
                document.getElementById(elementId).disabled = true;
                document.getElementById(eyeId).classList.add("right-icon-lock");
                console.log('just disable this checkbox');
              } else {
                document.getElementById(elementId).disabled = false;
                document.getElementById(eyeId).classList.remove("right-icon-lock");
                console.log('just enable this checkbox');
              }
            }
          }

          if (_c_srs == 'EPSG:4326') {
            const layers1 = map.getLayers().getArray().concat().reverse().map(layer => {
              //console.log(layer);
              controlLayerEye(layer, cScale);

              if (layer.get('exclusive') == true) {
                console.log('Inside ol-mapservice moveend event, EXCLUSIVE OVERRIDE');
              } else {
                if (layer.get('visiType') == 'Manual') {
                  console.log('Inside ol-mapservice moveend event, MANUAL OVERRIDE');
                  console.log(layer.values_.title);
                } else {
                  let maxScale = layer.get('maxScale');
                  let minScale = layer.get('minScale');
                  console.log('Inside ol-mapservice moveend event, zoom end, layer visibility for 4326 : ' + layer.getVisible() + '  ' + minScale + '  ' + maxScale);

                  if (Boolean(maxScale)) {
                    if (minScale <= cScale && cScale < maxScale) {
                      layer.setVisible(true);
                    } else {
                      layer.setVisible(false);
                    }
                  } else {
                    layer.setVisible(true);
                  }
                }
              }
            });
          } else {
            const layers = map.getLayers().getArray().concat().reverse().map(layer => {
              controlLayerEye(layer, cScale);

              if (layer.get('exclusive') == true) {
                console.log('Inside ol-mapservice moveend event, EXCLUSIVE OVERRIDE');
              } else {
                if (layer.get('visiType') == 'Manual') {
                  console.log('Inside ol-mapservice moveend event, MANUAL OVERRIDE');
                  console.log(layer.values_.title);
                } else {
                  let maxRes = layer.getMaxResolution();
                  let minRes = layer.getMinResolution();
                  console.log('Inside ol-mapservice moveend event, zoom end, layer visibility : ' + layer.getVisible() + '  ' + minRes + '  ' + maxRes);
                  console.log(layer);

                  if (minRes <= resolution && resolution < maxRes) {
                    layer.setVisible(true);
                  } else {
                    layer.setVisible(false);
                  }
                }
              }
            });
          }
        }
      });
      _jquery.default.fn.popover.Constructor.Default.whiteList.table = [];
      _jquery.default.fn.popover.Constructor.Default.whiteList.tr = [];
      _jquery.default.fn.popover.Constructor.Default.whiteList.td = [];
      _jquery.default.fn.popover.Constructor.Default.whiteList.th = [];
      _jquery.default.fn.popover.Constructor.Default.whiteList.div = [];
      _jquery.default.fn.popover.Constructor.Default.whiteList.tbody = [];
      _jquery.default.fn.popover.Constructor.Default.whiteList.thead = [];
      _jquery.default.fn.popover.Constructor.Default.whiteList.h2 = [];
      _jquery.default.fn.popover.Constructor.Default.whiteList.h3 = [];
      _jquery.default.fn.popover.Constructor.Default.whiteList.button = ['data-toggle', 'data-target'];
      _jquery.default.fn.popover.Constructor.Default.whiteList.ul = [];
      _jquery.default.fn.popover.Constructor.Default.whiteList.li = [];
      _jquery.default.fn.popover.Constructor.Default.whiteList.div = ['data-parent'];
      _jquery.default.fn.popover.Constructor.Default.whiteList.a = ['target', 'href', 'title', 'rel', 'data-toggle'];
      console.log('hoichong ol-mapservice getOmap map popover setup done'); // Popup showing the position the user clicked

      let popup = new _Overlay.default({
        element: document.getElementById('popup'),
        stopEvent: false
      });
      map.addOverlay(popup);
      console.log('hoichong ol-mapservice getOmap map addOverlay popup done');
      map.getViewport().addEventListener('contextmenu', async function (evt) {
        evt.preventDefault();
        console.log('Captured RIGHT CLICK ...');
        console.log(map.getEventCoordinate(evt));
        console.log(evt.coordinate); // work only for left single click or left dbl click

        let tcoordinate = map.getEventCoordinate(evt); // Remove code that are duplicate in the "left single click" event method.
        // See below.
      });
      let MEASURE_ACTIVE_FLAG = false;
      let measureSrcArray = [];
      let measureVLayerArray = [];
      let measureOutOverlayArray = [];
      map.on('singleclick', async function (evt) {
        evt.preventDefault();
        console.log('Captured SINGLE CLICK TOP LINE ...');
        let map_panel_arr = document.getElementsByClassName(_environment.default.W3O_openlayers.ol_map_panel);
        console.log(map_panel_arr[0].style.cursor);
        let mouse_pointer_type = map_panel_arr[0].style.cursor;
        console.log(mouse_pointer_type);

        if (mouse_pointer_type === 'grab' && !self.isLoading) {
          console.log('Captured SINGLE CLICK for POINTER...');
          console.log(map.getEventCoordinate(evt));
          console.log(evt.coordinate); // work only for left single click or left dbl click

          let tcoordinate = evt.coordinate;
          let view1 = map.getView();
          var viewResolution = view1.getResolution();
          let layers1 = map.getLayers();
          let layerfilterArr = [];
          let geodetic_layer = [];
          layers1.forEach(layer => {
            console.log('ol-mapservice map single click');
            console.log(layer.get('title'));
            console.log(layer);
            let layerkeys = {};

            if (layer.get('layer_info')) {
              let wms_url = layer.get('source').url_;
              let wms_arr = wms_url.split("/");
              let wfs_url = "/".concat(wms_arr[3], "/").concat(wms_arr[4], "/").concat(wms_arr[5]);
              layerfilterArr.push({
                type: layer.get('layer_info').type,
                fields: layer.get('layer_info').fields,
                layer_info: layer.get('layer_info'),
                wms_url: "".concat(self.store.adapterFor('application').get('erdasHost')).concat(wfs_url),
                target_layer: layer.get('source').params_.LAYERS
              });
            }

            if (layer.get('title') === 'Control Point') {
              let wms_url = layer.get('source').url_;
              let wms_arr = wms_url.split("/");
              let wfs_url = "/".concat(wms_arr[3], "/").concat(wms_arr[4], "/").concat(wms_arr[5]);
              geodetic_layer.push({
                title: layer.get('title'),
                type: layer.get('layer_info').type,
                fields: layer.get('layer_info').fields,
                layer_info: layer.get('layer_info'),
                wms_url: "".concat(self.store.adapterFor('application').get('erdasHost')).concat(wfs_url),
                target_layer: layer.get('source').params_.LAYERS
              });
            }
          });
          let wfs1 = '';
          let layer1 = '';
          let scoord1 = '';
          console.log('Coordinate of  click: ' + tcoordinate);
          let arr = ["one", "two", "three", "four", "five"];
          arr.includes("two"); //returns   true      

          let st1 = 'Lots'; // let fetchfeatureArr = ["Lots", "TOL", "Gazette", "Kampung"];

          let fetchResultArr = [];

          async function fetchFeatureInLayer(st1) {
            wfs1 = st1.wms_url;
            layer1 = st1.target_layer;
            scoord1 = tcoordinate;
            console.log({
              wfs1,
              layer1,
              scoord1
            }); //Show popover with loading

            self.isLoading = true;
            let element = popup.getElement();
            (0, _jquery.default)(element).popover('dispose');
            popup.setPosition(tcoordinate);
            (0, _jquery.default)(element).popover({
              placement: 'top',
              animation: false,
              html: true,
              title: 'Purchase Info',
              content: "Loading Data..."
            });
            (0, _jquery.default)(element).popover('show');
            let fetchRes1 = await self.fetchNearbyWfsObjects(wfs1, layer1, scoord1, st1);
            self.isLoading = false;
            console.log('Fetch results from fetchNearbyWfsObjects');
            console.log(fetchRes1);
            return fetchRes1;
          }

          console.log('---layerfilterArr---');
          console.log(layerfilterArr);

          for (const st1 of layerfilterArr) {
            let ffr1 = await fetchFeatureInLayer(st1);
            fetchResultArr.push(ffr1);
          }

          ;
          console.log('All Layers feature that need to fetch...');
          console.log(fetchResultArr); //Fetch geodetic layer info selected

          let wfs1_geodetic = geodetic_layer[0].wms_url;
          let layer1_geodetic = geodetic_layer[0].target_layer;
          let st1_geodetic = geodetic_layer[0];
          let fetchRes1_within = await self.fetchWithinWfsObjects(wfs1_geodetic, layer1_geodetic, tcoordinate, st1_geodetic);
          console.log('fetchRes1_within');
          console.log(fetchRes1_within);
          console.log('fetchRes1_within length');
          console.log(fetchRes1_within.length);
          let tablebodystart1 = '<tbody>';
          let tablebodyend1 = '</tbody>';
          let tablebody1 = '';
          let tablebody2 = '';
          let tablebody3 = '';
          let navstart1 = '<div class="row"><div class="col-12 col-sm-4 px-1 overflow-auto"><ul class="nav flex-column" role="tablist">';
          let navend1 = '</ul></div>';
          let navbody1 = '';
          let nav11 = '';
          nav11 += '<li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#buy_rso_a3">RSO Digital (A3)</a></li>';
          nav11 += '<li class="nav-item"><a class="nav-link" data-toggle="tab" href="#buy_rso_a1">RSO Digital (A1)</a></li>';
          nav11 += '<li class="nav-item"><a class="nav-link" data-toggle="tab" href="#buy_cp">Certified Plan</a></li>';
          nav11 += '<li class="nav-item"><a class="nav-link" data-toggle="tab" href="#buy_gdt">Geodetic Station</a></li>';
          navbody1 = navbody1 + nav11;
          let tabstart1 = '<div class="col-12 col-sm-8 px-1"><div class="tab-content">';
          let tabend1 = '</div></div></div>';
          let tabbody1 = '';
          let lot_info = [],
              rso_info = [],
              gdt_info = [];
          fetchResultArr.forEach(async function (arr1) {
            let result = arr1[0];
            console.log('CHECK RESULTS FROM FETCH NEARBY...');
            console.log(result);

            if (result === undefined || result.length == 0) {// array empty or does not exist
            } else {
              let navh1 = result.objectfield0;
              let targetLayerFields = layerfilterArr.find(x => x.type === navh1).fields;
              console.log('--targetLayerFields--');
              console.log(targetLayerFields);

              if (navh1 === "Lots" || navh1 === 'Gazette' || navh1 === 'TOL') {
                lot_info.push({
                  key: 'type',
                  value: navh1
                });
                Object.keys(result.objectfield1).forEach(function (key, index) {
                  if (targetLayerFields.includes(key)) {
                    lot_info.push({
                      key: key,
                      value: result.objectfield1[key]
                    });
                  }
                });
              } else if (navh1 === 'Keysheet') {
                Object.keys(result.objectfield1).forEach(function (key, index) {
                  if (targetLayerFields.includes(key)) {
                    rso_info.push({
                      key: key,
                      value: result.objectfield1[key]
                    });
                  }
                });
              }
            }
          });

          if (fetchRes1_within.length !== 0) {
            let result = fetchRes1_within[0];
            let targetLayerFields = geodetic_layer.find(x => x.type === 'Geodetic').fields;
            Object.keys(result.objectfield1).forEach(function (key, index) {
              if (targetLayerFields.includes(key)) {
                gdt_info.push({
                  key: key,
                  value: result.objectfield1[key]
                });
              }
            });
          }

          let str1 = '';
          let lot_type,
              lot_num,
              book_code,
              lot_exist = true;
          lot_info.forEach(lot => {
            str1 += '<tr>' + '<td>' + lot.key + '</td>' + '<td>' + lot.value + '</td>' + '</tr>';

            if (lot.key === 'type') {
              lot_type = lot.value;
            } else if (lot.key === 'LOTNUM' || lot.key === 'TOLNUM' || lot.key === 'GR_NUM') {
              lot_num = lot.value;
            } else if (lot.key === 'BOOKCODE') {
              book_code = lot.value;
            }
          });

          if (lot_info.length === 0) {
            lot_exist = false;
            str1 += '<tr><td>Nothing Selected</td><td></td></tr>';
          }

          tablebody1 = tablebody1 + str1;
          let str2 = '';
          let rso_ref,
              rso_exist = true;
          rso_info.forEach(rso => {
            str2 += '<tr>' + '<td>' + rso.key + '</td>' + '<td>' + rso.value + '</td>' + '</tr>';

            if (rso.key === 'RSOREF') {
              rso_ref = rso.value;
            }
          });

          if (rso_info.length === 0) {
            rso_exist = false;
            str1 += '<tr><td>Nothing Selected</td><td></td></tr>';
          }

          tablebody2 = tablebody2 + str2;
          let str3 = '';
          let ctl_pnt,
              gdt_exist = true;
          gdt_info.forEach(gdt => {
            str3 += '<tr>' + '<td>' + gdt.key + '</td>' + '<td>' + gdt.value + '</td>' + '</tr>';

            if (gdt.key === 'STN_NAME') {
              ctl_pnt = gdt.value;
            }
          });

          if (fetchRes1_within.length === 0) {
            gdt_exist = false;
            str3 += '<tr><td>Nothing Selected</td><td></td></tr>';
          }

          tablebody3 = tablebody3 + str3;
          let table11 = tablebodystart1 + tablebody1 + tablebodyend1;
          let table12 = tablebodystart1 + tablebody2 + tablebodyend1;
          let table13 = tablebodystart1 + tablebody3 + tablebodyend1;
          let completethead = '<thead><tr><th scope="col">Key</th><th scope="col">Value</th></tr></thead>';
          let completeTableLOT = '<div class="popovbody">' + '<table class="table table-striped popover-table">' + completethead + table11 + '</table>' + '</div>';
          let completeTableRSOA1 = '<div class="popovbody">' + '<table class="table table-striped popover-table">' + completethead + table12 + '</table>' + '</div>';
          let completeTableRSOA3 = '<div class="popovbody">' + '<table class="table table-striped popover-table">' + completethead + table11 + '</table>' + '</div>';
          let completeTableGDT = '<div class="popovbody">' + '<table class="table table-striped popover-table">' + completethead + table13 + '</table>' + '</div>'; //Purchase CP button logic

          const cpButton = async (lot_exist, lot_num, book_code) => {
            let url, result;

            if (lot_exist) {
              url = "".concat(self.store.adapterFor('application').get('ecomWeb')).concat(self.store.adapterFor('application').get('namespace'), "/items/getCPItem?");

              switch (lot_type) {
                case "Lots":
                  url += "cp_type=Lot&cp_lot_number=".concat(lot_num, "&cp_book_code=").concat(book_code);
                  break;

                case "Gazette":
                  url += "cp_type=Gazette&cp_lot_number=".concat(lot_num);
                  break;

                case "TOL":
                  url += "cp_type=Tol&cp_lot_number=".concat(lot_num, "&cp_book_code=").concat(book_code);
                  break;
              }

              result = await (0, _axios.default)({
                method: 'GET',
                url
              });
            }

            let buy_cp = '<div class="text-center mt-2">CP unavailable</div>';

            if (result) {
              if (result.data.id !== null) {
                let product_id = result.data.id;
                buy_cp = "<div class=\"text-center mt-2\"><a href=".concat(self.store.adapterFor('application').get('ecomWebsite'), "/product/").concat(product_id, " target=\"_blank\"><div class=\"btn btn-sm btn-primary popup-btn\">Buy CP</div></a></div>");
              }
            }

            return buy_cp;
          };

          const getRSOPrice = async () => {
            let url, result;
            url = "".concat(self.store.adapterFor('application').get('serverHost')).concat(self.store.adapterFor('application').get('namespace'), "/geoportalprices");
            result = await (0, _axios.default)({
              method: 'GET',
              url
            });
            let result_a1 = result.data.geoportalprices.filter(obj => {
              return obj.price_name === 'RSO Sheet (A1)';
            });
            let result_a3 = result.data.geoportalprices.filter(obj => {
              return obj.price_name === 'RSO Sheet (A3)';
            });
            result = {
              a1_with_rso: result_a1[0].price_total_with,
              a1_without_rso: result_a1[0].price_total,
              a3_with_rso: result_a3[0].price_total_with,
              a3_without_rso: result_a3[0].price_total
            };
            return result;
          };

          const getGDTPrice = async () => {
            let url, result;
            url = "".concat(self.store.adapterFor('application').get('serverHost')).concat(self.store.adapterFor('application').get('namespace'), "/geoportalgeodeticprices");
            result = await (0, _axios.default)({
              method: 'GET',
              url
            });
            let result_gdt = result.data.geoportalgeodeticprices.filter(obj => {
              return obj.price_name === 'Geodetic Sheet (A4)';
            });
            result = {
              a4_with_gdt: result_gdt[0].price_total_with,
              a4_without_gdt: result_gdt[0].price_total
            };
            return result;
          };

          let buy_cp = await cpButton(lot_exist, lot_num, book_code);
          let rso_price = await getRSOPrice();
          let gdt_price = await getGDTPrice();
          let buy_a1 = '<div id="purchase_rso_a1" class="text-center mt-2"></div>';
          let buy_a3 = '<div id="purchase_rso_a3" class="text-center mt-2"></div>';
          let buy_gdt = '<div id="purchase_gdt" class="text-center mt-2"></div>';
          let tab11 = '';
          tab11 += '<div id="buy_rso_a3" class="container1 tab-pane active"><br>' + completeTableRSOA3 + '<br>' + buy_a3 + '</div>';
          tab11 += '<div id="buy_rso_a1" class="container1 tab-pane"><br>' + completeTableRSOA1 + '<br>' + buy_a1 + '</div>';
          tab11 += '<div id="buy_cp" class="container1 tab-pane"><br>' + completeTableLOT + '<br>' + buy_cp + '</div>';
          tab11 += '<div id="buy_gdt" class="container1 tab-pane"><br>' + completeTableGDT + '<br>' + buy_gdt + '</div>';
          tabbody1 = tabbody1 + tab11;
          let completenav = navstart1 + navbody1 + navend1;
          let completetabcontent = tabstart1 + tabbody1 + tabend1;
          let completepopcontent = completenav + completetabcontent;
          let element = popup.getElement();
          (0, _jquery.default)(element).popover('dispose');
          popup.setPosition(tcoordinate);
          (0, _jquery.default)(element).popover({
            placement: 'top',
            animation: false,
            template: '<div class="popover gp-popup" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
            html: true,
            title: 'Purchase Info <a href="#" class="close" data-dismiss="alert">&times;</a>',
            content: completepopcontent
          });
          (0, _jquery.default)(element).popover('show');

          const rsoButtonLogic = async (rso_type, rso_params, rso_button, rsoButtonName) => {
            //Disable button (to prevent accident multiple clicks)
            (0, _jquery.default)(rso_button).off('click');
            (0, _jquery.default)(rso_button).addClass("disabled");
            (0, _jquery.default)(rso_button).prepend('<img src="/images/ajax-loader.gif" alt="Loading">');

            if (!self.get('authObject').get('isAuthenticated')) {
              window.location.replace("".concat(self.store.adapterFor('application').get('host'), "/login"));
              return;
            }

            let is_public = "No";
            let is_internal = self.get('authObject.data.user.is_internal');
            let is_cash = self.get('authObject.data.user.receive_cash_payment');
            let is_mobile = this.get('isMobile') === 'Yes' ? true : false;

            if (is_internal === false && is_cash === false) {
              is_public = "Yes";
            }

            if (is_mobile === true) {
              is_public = "Yes";
            }
            /*
            If not internal user, then just proceed to the shopping card page for payment process
            If internal user, generate RSO directly without going through payment
            */


            if (!is_internal) {
              let url_rsoexport = "".concat(self.store.adapterFor('application').get('serverHost')).concat(self.store.adapterFor('application').get('namespace'), "/rsosheets/doRSOExport");
              let response = await _axios.default.post(url_rsoexport, {
                rso_type,
                ...rso_params,
                user: self.get("authObject.data.user.id"),
                is_public
              }).catch(error => {
                alert("An error occured while preparing RSO Sheet's order, ".concat(error.response.data));
                return null;
              });

              if (response) {
                window.location.replace("".concat(self.store.adapterFor('application').get('host'), "/shopping-cart"));
              }
            } else {
              let randomDigit = Math.random().toString().substring(2, 7);
              let order_number = "SSDI-STAFF-".concat(moment().format("YYYY-MM-DD"), "-").concat(randomDigit, "-RSO");
              let image_name = Math.random().toString().substring(2, 12);
              let generate_type = "pdf";
              let is_internal = true;
              let url_rsogenerate = "".concat(self.store.adapterFor('application').get('serverHost')).concat(self.store.adapterFor('application').get('namespace'), "/rsosheets/generateRSOSheet");
              let response = await _axios.default.post(url_rsogenerate, {
                rso_type,
                ...rso_params,
                order_number,
                image_name,
                generate_type,
                is_internal,
                erdashost_url: self.store.adapterFor('application').get('erdasHost'),
                is_public
              }).catch(error => {
                alert("An error occured while generating RSO Sheet, ".concat(error.response.data));
                return null;
              });

              if (response.data) {
                let {
                  base64,
                  fileName,
                  type
                } = response.data;
                let blob;

                try {
                  blob = this.dataURItoBlob(base64);
                } catch (e) {
                  // source: https://stackoverflow.com/a/21797381
                  let binary_string = window.atob(base64);
                  let len = binary_string.length;
                  let bytes = new Uint8Array(len);

                  for (let i = 0; i < len; i++) {
                    bytes[i] = binary_string.charCodeAt(i);
                  }

                  let arrayBuffer = bytes.buffer;
                  blob = new Blob([arrayBuffer], {
                    type
                  });
                }

                let blobUrl = URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = blobUrl;
                a.download = fileName || blobUrl.split('/').pop();
                a.click();
              } //Enable button


              (0, _jquery.default)(rso_button).html("".concat(rsoButtonName));
            }
          };

          const gdtButtonLogic = async (gdt_type, gdt_params, gdt_button, gdtButtonName) => {
            //Disable button (to prevent accident multiple clicks)
            (0, _jquery.default)(gdt_button).off('click');
            (0, _jquery.default)(gdt_button).addClass("disabled");
            (0, _jquery.default)(gdt_button).prepend('<img src="/images/ajax-loader.gif" alt="Loading">');

            if (!self.get('authObject').get('isAuthenticated')) {
              window.location.replace("".concat(self.store.adapterFor('application').get('host'), "/login"));
              return;
            }

            let is_public = "No";
            let is_internal = self.get('authObject.data.user.is_internal');
            let is_cash = self.get('authObject.data.user.receive_cash_payment');
            let is_mobile = this.get('isMobile') === 'Yes' ? true : false;

            if (is_internal === false && is_cash === false) {
              is_public = "Yes";
            }

            if (is_mobile === true) {
              is_public = "Yes";
            }
            /*
            If not internal user, then just proceed to the shopping card page for payment process
            If internal user, generate RSO directly without going through payment
            */


            if (!is_internal) {
              let url_gdtexport = "".concat(self.store.adapterFor('application').get('serverHost')).concat(self.store.adapterFor('application').get('namespace'), "/geodetics/doGeodeticExport");
              let response = await _axios.default.post(url_gdtexport, { ...gdt_params,
                user: self.get("authObject.data.user.id"),
                is_public
              }).catch(error => {
                alert("An error occured while preparing Geodetic Sheet's order, ".concat(error.response.data));
                return null;
              });

              if (response) {
                window.location.replace("".concat(self.store.adapterFor('application').get('host'), "/shopping-cart"));
              }
            } else {
              let randomDigit = Math.random().toString().substring(2, 7);
              let order_number = "SSDI-STAFF-".concat(moment().format("YYYY-MM-DD"), "-").concat(randomDigit, "-GDT");
              let image_name = Math.random().toString().substring(2, 12);
              let generate_type = "pdf";
              let is_internal = true;
              let url_gdtgenerate = "".concat(self.store.adapterFor('application').get('serverHost')).concat(self.store.adapterFor('application').get('namespace'), "/geodetics/generateGeodeticSheet");
              let response = await _axios.default.post(url_gdtgenerate, { ...gdt_params,
                order_number,
                image_name,
                generate_type,
                is_internal,
                erdashost_url: self.store.adapterFor('application').get('erdasHost'),
                is_public
              }).catch(error => {
                alert("An error occured while generating Geodetic Sheet, ".concat(error.response.data));
                return null;
              });

              if (response.data) {
                let {
                  base64,
                  fileName,
                  type
                } = response.data;
                let blob;

                try {
                  blob = this.dataURItoBlob(base64);
                } catch (e) {
                  // source: https://stackoverflow.com/a/21797381
                  let binary_string = window.atob(base64);
                  let len = binary_string.length;
                  let bytes = new Uint8Array(len);

                  for (let i = 0; i < len; i++) {
                    bytes[i] = binary_string.charCodeAt(i);
                  }

                  let arrayBuffer = bytes.buffer;
                  blob = new Blob([arrayBuffer], {
                    type
                  });
                }

                let blobUrl = URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = blobUrl;
                a.download = fileName || blobUrl.split('/').pop();
                a.click();
              } //Enable button


              (0, _jquery.default)(gdt_button).html("".concat(gdtButtonName));
            }
          }; // Adding click action logic to RSO buttons (only can be run after the button rendered)


          if (_environment.default.W3O_openlayers.deployment == 'GEOPORTAL') {
            const rsoButtonDivLogic = async () => {
              let rso_button_a1 = document.createElement('div');
              let rso_button_a1_ophoto = document.createElement('div');
              let rso_button_a1_internal = document.createElement('div');
              let rso_button_na = document.createElement('div');
              let rsoButtonName;
              (0, _jquery.default)(rso_button_na).addClass("text-center mt-2").html("RSO unavailable");
              (0, _jquery.default)(rso_button_a1).addClass("col-11 col-sm-8 col-lg-5 mb-1 btn mx-lg-1 btn-sm btn-secondary").html("Without Orthophoto (A1) B$".concat(rso_price.a1_without_rso)).on("click", async function () {
                let rso_params = {
                  rso_ref
                };
                rso_params.with_orthophoto = false;
                rsoButtonName = "Without Orthophoto (A1) B$".concat(rso_price.a1_without_rso);
                await rsoButtonLogic('A1', rso_params, rso_button_a1, rsoButtonName);
              });
              (0, _jquery.default)(rso_button_a1_ophoto).addClass("col-11 col-sm-8 col-lg-5 btn btn-sm mb-1 mx-lg-1 btn-primary").html("With Orthophoto (A1) B$".concat(rso_price.a1_with_rso)).on("click", async function () {
                let rso_params = {
                  rso_ref
                };
                rso_params.with_orthophoto = true;
                rsoButtonName = "With Orthophoto (A1) B$".concat(rso_price.a1_with_rso);
                await rsoButtonLogic('A1', rso_params, rso_button_a1_ophoto, rsoButtonName);
              });
              (0, _jquery.default)(rso_button_a1_internal).addClass("col-11 col-sm-8 col-lg-5 btn btn-sm mb-1 mx-lg-1 btn-primary").html("Generate RSO (A1)").on("click", async function () {
                let rso_params = {
                  rso_ref
                };
                rso_params.with_orthophoto = false;
                rsoButtonName = 'Generate RSO (A1)';
                await rsoButtonLogic('A1', rso_params, rso_button_a1_internal, rsoButtonName);
              });

              if (rso_exist) {
                (0, _jquery.default)(rso_button_a1).appendTo((0, _jquery.default)("#purchase_rso_a1"));
                (0, _jquery.default)(rso_button_a1_ophoto).appendTo((0, _jquery.default)("#purchase_rso_a1"));
              } else {
                (0, _jquery.default)(rso_button_na).appendTo((0, _jquery.default)("#purchase_rso_a1"));
              }

              let rso_button_a3 = document.createElement('div');
              let rso_button_a3_ophoto = document.createElement('div');
              let rso_button_a3_internal = document.createElement('div');
              (0, _jquery.default)(rso_button_a3).addClass("col-11 col-sm-8 col-lg-5 mb-1 btn mx-lg-1 btn-sm btn-secondary").html("Without Orthophoto (A3) B$".concat(rso_price.a3_without_rso)).on("click", async function () {
                let rso_params = {
                  lot_type,
                  lot_num,
                  book_code
                };
                rso_params.with_orthophoto = false;
                rsoButtonName = "Without Orthophoto (A3) B$".concat(rso_price.a3_without_rso);
                await rsoButtonLogic('A3', rso_params, rso_button_a3, rsoButtonName);
              });
              (0, _jquery.default)(rso_button_a3_ophoto).addClass("col-11 col-sm-8 col-lg-5 mb-1 btn mx-lg-1 btn-sm btn-primary").html("With Orthophoto (A3) B$".concat(rso_price.a3_with_rso)).on("click", async function () {
                let rso_params = {
                  lot_type,
                  lot_num,
                  book_code
                };
                rso_params.with_orthophoto = true;
                rsoButtonName = "With Orthophoto (A3) B$".concat(rso_price.a3_with_rso);
                await rsoButtonLogic('A3', rso_params, rso_button_a3_ophoto, rsoButtonName);
              });
              (0, _jquery.default)(rso_button_a3_internal).addClass("col-11 col-sm-8 col-lg-5 mb-1 btn mx-lg-1 btn-sm btn-primary").html("Generate RSO (A3)").on("click", async function () {
                let rso_params = {
                  lot_type,
                  lot_num,
                  book_code
                };
                rso_params.with_orthophoto = false;
                rsoButtonName = 'Generate RSO (A3)';
                await rsoButtonLogic('A3', rso_params, rso_button_a3_internal, rsoButtonName);
              });

              if (lot_exist) {
                (0, _jquery.default)(rso_button_a3).appendTo((0, _jquery.default)("#purchase_rso_a3"));
                (0, _jquery.default)(rso_button_a3_ophoto).appendTo((0, _jquery.default)("#purchase_rso_a3"));
              } else {
                (0, _jquery.default)(rso_button_na).appendTo((0, _jquery.default)("#purchase_rso_a3"));
              }
            };

            const gdtButtonDivLogic = async () => {
              let gdt_button = document.createElement('div');
              let gdt_button_ophoto = document.createElement('div');
              let gdt_button_internal = document.createElement('div');
              let gdt_button_na = document.createElement('div');
              let gdtButtonName;
              (0, _jquery.default)(gdt_button_na).addClass("text-center mt-2").html("Geodetic unavailable");
              (0, _jquery.default)(gdt_button).addClass("col-11 col-sm-8 col-lg-5 mb-1 btn mx-lg-1 btn-sm btn-secondary").html("Coordinates with height B$".concat(gdt_price.a4_with_gdt)).on("click", async function () {
                let gdt_params = {
                  ctl_pnt
                };
                gdt_params.with_height = true;
                gdtButtonName = "Coordinates with height B$".concat(gdt_price.a4_without_gdt);
                await gdtButtonLogic('A4', gdt_params, gdt_button, gdtButtonName);
              });
              (0, _jquery.default)(gdt_button_ophoto).addClass("col-11 col-sm-8 col-lg-5 btn btn-sm mb-1 mx-lg-1 btn-primary").html("Coordinates only B$".concat(gdt_price.a4_without_gdt)).on("click", async function () {
                let gdt_params = {
                  ctl_pnt
                };
                gdt_params.with_height = false;
                gdtButtonName = "Coordinates only B$".concat(gdt_price.a4_with_gdt);
                await gdtButtonLogic('A4', gdt_params, gdt_button_ophoto, gdtButtonName);
              }); // $(gdt_button_internal).addClass("col-11 col-sm-8 col-lg-5 btn btn-sm mb-1 mx-lg-1 btn-primary")
              //     .html(`Generate Geodetic (A4)`)
              //     .on("click", async function(){
              //         let gdt_params = {ctl_pnt}
              //         gdt_params.with_height = false;
              //         gdtButtonName = 'Generate Geodetic (A4)';
              //         await gdtButtonLogic('A4', gdt_params, gdt_button_internal, gdtButtonName);
              //     });

              if (gdt_exist) {
                (0, _jquery.default)(gdt_button).appendTo((0, _jquery.default)("#purchase_gdt"));
                (0, _jquery.default)(gdt_button_ophoto).appendTo((0, _jquery.default)("#purchase_gdt"));
              } else {
                (0, _jquery.default)(gdt_button_na).appendTo((0, _jquery.default)("#purchase_gdt"));
              }
            };

            await rsoButtonDivLogic();
            await gdtButtonDivLogic();
          }
        }

        if (mouse_pointer_type === 'pointer' && !self.isLoading) {
          console.log('Captured SINGLE CLICK for POINTER...');
          console.log(map.getEventCoordinate(evt));
          console.log(evt.coordinate); // work only for left single click or left dbl click

          let tcoordinate = evt.coordinate;
          let view1 = map.getView();
          var viewResolution = view1.getResolution();
          let layers1 = map.getLayers();
          let layerfilterArr = [];
          layers1.forEach(layer => {
            console.log('ol-mapservice map single click');
            console.log(layer.get('title'));
            console.log(layer); //let layerkeys = layer.values_.info;

            let layerkeys = {}; //match the type and names, then push the layer array into layerfilterArr
            // if (layer.get('title') === 'Lots'){
            //   layerfilterArr.push({type: 'Lots', fields: layer.get('layer_info')});
            // } else if (layer.get('title') === 'Kampung Names'){
            //   layerfilterArr.push({type: 'Kampung', fields: layer.get('layer_info')});
            // }
            //just push layer_info into the array

            if (layer.get('layer_info')) {
              let wms_url = layer.get('source').url_;
              let wms_arr = wms_url.split("/");
              let wfs_url = "/".concat(wms_arr[3], "/").concat(wms_arr[4], "/").concat(wms_arr[5]);
              layerfilterArr.push({
                type: layer.get('layer_info').type,
                fields: layer.get('layer_info').fields,
                layer_info: layer.get('layer_info'),
                wms_url: "".concat(self.store.adapterFor('application').get('erdasHost')).concat(wfs_url),
                target_layer: layer.get('source').params_.LAYERS
              });
            } // layerfilterArr.push(layerkeys);

            /* if (layer.get('title') == 'Lots') {
              let wmsSource1 = layer.get('source');
              console.log(wmsSource1);
              let url = wmsSource1.getGetFeatureInfoUrl(
                tcoordinate, viewResolution, 'EPSG:5247', {
                  'INFO_FORMAT': 'text/xml'
                });
              console.log(url);
            } */

          }); // layerfilterArr = [
          //   {type: 'Lots', fields: ['OBJECTID','LOTNUM','BOOKCODE','DISTCODE','VC_RSO_NUM']}, 
          //   {type: 'Kampung', fields: ['OBJECTID','DISTCODE','KGCODE','KG_NAME','MUKIM_NAME']}
          // ];
          // console.log('debug layerfilterArr');
          // console.log(layerfilterArr);

          let wfs1 = '';
          let layer1 = '';
          let scoord1 = '';
          console.log('Coordinate of  click: ' + tcoordinate);
          let arr = ["one", "two", "three", "four", "five"];
          arr.includes("two"); //returns   true      

          let st1 = 'Lots'; // let fetchfeatureArr = ["Lots", "TOL", "Gazette", "Kampung"];

          let fetchResultArr = [];

          async function fetchFeatureInLayer(st1) {
            // switch (st1) {
            //   case 'Lots': //swfs1,slayer1,ssrs1,sattr1,sstr1
            //     wfs1 = `${self.store.adapterFor('application').get('erdasHost')}/erdas-apollo/vector/LOTS3`;
            //     layer1 = 'MV_LOTS3';
            //     scoord1 = tcoordinate;
            //     break;
            //   case 'TOL': //swfs1,slayer1,ssrs1,sattr1,sstr1
            //     wfs1 = `${self.store.adapterFor('application').get('erdasHost')}/erdas-apollo/vector/GDBD`;
            //     layer1 = 'TOL';
            //     scoord1 = tcoordinate;
            //     break;
            //   case 'Gazette': //swfs1,slayer1,ssrs1,sattr1,sstr1
            //     wfs1 = `${self.store.adapterFor('application').get('erdasHost')}/erdas-apollo/vector/GAZETTE3`;
            //     layer1 = 'MV_GAZETTE1';
            //     scoord1 = tcoordinate;
            //     break;
            //   case 'Kampung': //swfs1,slayer1,ssrs1,sattr1,sstr1
            //     wfs1 = `${self.store.adapterFor('application').get('erdasHost')}/erdas-apollo/vector/GDBD_BR10`;
            //     layer1 = 'KAMPUNG_P';
            //     scoord1 = tcoordinate;
            //     break;
            //   default:
            //     console.log('Error Encountered in Fetch Feature Case Switch');
            // }
            wfs1 = st1.wms_url;
            layer1 = st1.target_layer;
            scoord1 = tcoordinate;
            console.log({
              wfs1,
              layer1,
              scoord1
            }); //Show popover with loading

            self.isLoading = true;
            let element = popup.getElement();
            (0, _jquery.default)(element).popover('dispose');
            popup.setPosition(tcoordinate);
            (0, _jquery.default)(element).popover({
              placement: 'top',
              animation: false,
              html: true,
              title: 'Geo Info',
              content: "Loading Data..."
            });
            (0, _jquery.default)(element).popover('show');
            let fetchRes1 = await self.fetchNearbyWfsObjects(wfs1, layer1, scoord1, st1);
            self.isLoading = false;
            /* try {
              fetchRes1 = await self.fetchNearbyWfsObjects(wfs1, layer1, scoord1, st1);
            } catch(err) {
              console.error(err); 
            } */

            console.log('Fetch results from fetchNearbyWfsObjects');
            console.log(fetchRes1);
            return fetchRes1;
          }

          console.log('---layerfilterArr---');
          console.log(layerfilterArr);

          for (const st1 of layerfilterArr) {
            let ffr1 = await fetchFeatureInLayer(st1);
            fetchResultArr.push(ffr1);
          }

          ;
          console.log('All Layers feature that need to fetch...');
          console.log(fetchResultArr);
          let tablebodystart1 = '<tbody>';
          let tablebodyend1 = '</tbody>';
          let tablebody1 = '';
          let navstart1 = '<div class="row"><div class="col-12 col-sm-4 px-1 overflow-auto"><ul class="nav flex-column" role="tablist">';
          let navend1 = '</ul></div>';
          let navbody1 = '';
          let tabstart1 = '<div class="col-12 col-sm-8 px-1"><div class="tab-content">';
          let tabend1 = '</div></div></div>';
          let tabbody1 = '';
          let firstTabFlag = true;
          let product;
          let rso_type, rso_ref, lot_type, lot_num, book_code;
          let url,
              buyButton = '';
          fetchResultArr.forEach(async function (arr1) {
            let result = arr1[0];
            console.log('CHECK RESULTS FROM FETCH NEARBY...');
            console.log(result);

            if (result === undefined || result.length == 0) {// array empty or does not exist
            } else {
              let navh1 = result.objectfield0;
              let targetLayerFields = layerfilterArr.find(x => x.type === navh1).fields;
              console.log('--targetLayerFields--');
              console.log(targetLayerFields);
              let nav11 = '';

              if (_environment.default.W3O_openlayers.deployment === 'GEOPORTAL') {
                // rso_type = self.get('rsosize');
                // console.log('rso_type here');
                // console.log(rso_type);
                if (navh1 === "Lots") {
                  // lot_type = "LOT";
                  // lot_num = result.objectfield1.LOTNUM;
                  // book_code = result.objectfield1.BOOKCODE;
                  url = "".concat(self.store.adapterFor('application').get('ecomWeb')).concat(self.store.adapterFor('application').get('namespace'), "/items/getCPItem?cp_type=Lot&cp_lot_number=").concat(result.objectfield1.LOTNUM, "&cp_book_code=").concat(result.objectfield1.BOOKCODE);
                } else if (navh1 === "Gazette") {
                  // lot_type = "GR";
                  // lot_num = result.objectfield1.GR_NUM;
                  url = "".concat(self.store.adapterFor('application').get('ecomWeb')).concat(self.store.adapterFor('application').get('namespace'), "/items/getCPItem?cp_type=Gazette&cp_lot_number=").concat(result.objectfield1.GR_NUM);
                } else if (navh1 === "TOL") {
                  // lot_type = "TOL";
                  // lot_num = result.objectfield1.TOLNUM;
                  // book_code = result.objectfield1.BOOKCODE;
                  url = "".concat(self.store.adapterFor('application').get('ecomWeb')).concat(self.store.adapterFor('application').get('namespace'), "/items/getCPItem?cp_type=Tol&cp_lot_number=").concat(result.objectfield1.TOLNUM, "&cp_book_code=").concat(result.objectfield1.BOOKCODE);
                } // else if (navh1 === "Keysheet") {
                //     rso_ref = result.objectfield1.RSOREF;
                // }

              } else {
                product = new Promise((resolve, reject) => {
                  resolve(buyButton);
                });
              }

              if (firstTabFlag == true) {
                nav11 = '<li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#' + navh1 + '">' + navh1 + '</a></li>';
              } else {
                nav11 = '<li class="nav-item"><a class="nav-link" data-toggle="tab" href="#' + navh1 + '">' + navh1 + '</a></li>';
              }

              navbody1 = navbody1 + nav11;
              Object.keys(result.objectfield1).forEach(function (key, index) {
                // key: the name of the object key
                //console.log(index + '   ' + key + '  ' + result.objectfield1[key]);
                // index: the ordinal position of the key within the object
                if (targetLayerFields.includes(key)) {
                  let str1 = '<tr>' + '<td>' + key + '</td>' + '<td>' + result.objectfield1[key] + '</td>' + '</tr>';
                  tablebody1 = tablebody1 + str1;
                }
              });
              let table11 = tablebodystart1 + tablebody1 + tablebodyend1;
              let completethead = '<thead><tr><th scope="col">Key</th><th scope="col">Value</th></tr></thead>';
              let completeTable = '<div class="popovbody">' + '<table class="table table-striped popover-table">' + completethead + table11 + '</table>' + '</div>';
              let tab11 = '';

              if (firstTabFlag == true) {
                tab11 = '<div id="' + navh1 + '" class="container1 tab-pane active"><br>' + completeTable + '</div>';
              } else {
                tab11 = '<div id="' + navh1 + '" class="container1 tab-pane"><br>' + completeTable + '</div>';
              }

              tabbody1 = tabbody1 + tab11; //console.log(tab11);

              tablebody1 = '';
              firstTabFlag = false;
            }
          });

          if (url) {
            product = new Promise(async (resolve, reject) => {
              let result = await (0, _axios.default)({
                method: 'GET',
                url
              });

              if (result.data.id !== null) {
                result = result.data.id;
                buyButton = "<div class=\"text-center mt-2\"><a href=".concat(self.store.adapterFor('application').get('ecomWebsite'), "/product/").concat(result, " target=\"_blank\"><div class=\"btn btn-sm btn-primary popup-btn\">Buy CP</div></a></div>");
                resolve(buyButton);
              } else {
                buyButton = '<div class="text-center mt-2">CP unavailable</div>';
                resolve(buyButton);
              }
            });
          } else {
            product = new Promise((resolve, reject) => {
              buyButton = '<div class="text-center mt-2">CP unavailable</div>';
              resolve(buyButton);
            });
          }

          product.then(cp_button => {
            let completenav = navstart1 + navbody1 + navend1;
            let completetabcontent = tabstart1 + tabbody1 + tabend1; // To disable Buy CP from info windows
            // let completepopcontent = completenav + completetabcontent + cp_button;

            let completepopcontent = completenav + completetabcontent;

            if (_environment.default.W3O_openlayers.deployment == 'GEOPORTAL') {
              completepopcontent += "<div id=\"purchase_rso\" class=\"text-center mt-2\"></div>";
            }

            console.log('completepopcontent');
            console.log(completepopcontent); //let cpt1 = '<ul class="nav nav-tabs" role="tablist"><li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#home">Home</a></li><li class="nav-item"><a class="nav-link" data-toggle="tab" href="#menu1">Menu 1</a></li><li class="nav-item"><a class="nav-link" data-toggle="tab" href="#menu2">Menu 2</a></li></ul>';
            //let cpt2 = '<div class="tab-content"><div id="home" class="container1 tab-pane active"><br><h3>HOME</h3>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div><div id="menu1" class="container tab-pane fade"><br><h3>Menu 1</h3>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div><div id="menu2" class="container tab-pane fade"><br><h3>Menu 2</h3>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</div></div>';
            //let completeTable = cpt1 + cpt2;

            let element = popup.getElement(); //let closeContent = '<div><a href="#" onclick="function() {$(element).popover("dispose")}">X</a></div>';
            //let content1 = '<div><table class="table"> <thead> <tr> <th scope="col">#</th> <th scope="col">First</th> <th scope="col">Last</th> <th scope="col">Handle</th> </tr> </thead> <tbody> <tr> <th scope="row">1</th> <td>Mark</td> <td>Otto</td> <td>@mdo</td> </tr> <tr> <th scope="row">2</th> <td>Jacob</td> <td>Thornton</td> <td>@fat</td> </tr> <tr> <th scope="row">3</th> <td>Larry</td> <td>the Bird</td> <td>@twitter</td> </tr> </tbody> </table></div>';
            //let content3 = '<div class="rTable"> <div class="rTableRow"> <div class="rTableHead"><strong>Name</strong></div> <div class="rTableHead"><span style="font-weight: bold;">Telephone</span></div> <div class="rTableHead">&nbsp;</div> </div> <div class="rTableRow"> <div class="rTableCell">John</div> <div class="rTableCell"><a href="tel:0123456785">0123 456 785</a></div> <div class="rTableCell">CHECK</div> </div> <div class="rTableRow"> <div class="rTableCell">Cassie</div> <div class="rTableCell"><a href="tel:9876532432">9876 532 432</a></div> <div class="rTableCell">CHECK</div> </div> </div>';

            (0, _jquery.default)(element).popover('dispose');
            popup.setPosition(tcoordinate);
            (0, _jquery.default)(element).popover({
              placement: 'top',
              animation: false,
              template: '<div class="popover gp-popup" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
              html: true,
              title: 'Geo Info <a href="#" class="close" data-dismiss="alert">&times;</a>',
              content: completepopcontent
            });
            (0, _jquery.default)(element).popover('show'); // if (ENV.W3O_openlayers.deployment == 'GEOPORTAL') {
            //     let rso_button = document.createElement('div');
            //     $(rso_button).addClass("btn btn-sm btn-primary")
            //         .html(`Purchase RSO (${rso_type})`)
            //         .on("click", async function(){
            //             let url = `${self.store.adapterFor('application').get('host')}/shopping-cart`;
            //             let url_featureexport = `${self.store.adapterFor('application').get('serverHost')}${self.store.adapterFor('application').get('namespace')}/rsosheets/doRSOExport`;
            //             let response = await axios.post(
            //                 url_featureexport,
            //                 {
            //                     rso_ref,
            //                     rso_type,
            //                     lot_type,
            //                     lot_num,
            //                     book_code,
            //                     user: self.get("authObject.data.user.id")
            //                 }
            //                 ).catch((error) => {
            //                     alert(`An error occured while preparing RSO Sheet's order, ${error.response.data}`);
            //                     return null;
            //             });
            //             if (response) {
            //                 window.location.replace(`${self.store.adapterFor('application').get('host')}/shopping-cart`);
            //             }
            //         })
            //         .appendTo($("#purchase_rso"));
            // }
          });
        } // if pointer 


        if (mouse_pointer_type === 'crosshair' && !self.isLoading) {
          console.log('Captured SINGLE CLICK for MEASURE ...');

          if (MEASURE_ACTIVE_FLAG === false) {
            console.log(map.getEventCoordinate(evt));
            console.log(evt.coordinate); // work only for left single click or left dbl click

            let tcoordinate = evt.coordinate;
            let view1 = map.getView();
            var viewResolution = view1.getResolution();
            let layers1 = map.getLayers();
            let layerfilterArr = [];
            let measureSource = new _Vector2.default();
            let measureVectorLayer = new _Vector.default({
              source: measureSource,
              zIndex: 150,
              style: new _style.Style({
                fill: new _style.Fill({
                  color: 'rgba(255, 255, 255, 0.2)'
                }),
                stroke: new _style.Stroke({
                  color: '#ffcc33',
                  width: 2
                }),
                image: new _style.Circle({
                  radius: 7,
                  fill: new _style.Fill({
                    color: '#ffcc33'
                  })
                })
              })
            });
            measureSrcArray.push(measureSource);
            measureVLayerArray.push(measureVectorLayer);
            self.set('measureSource', measureSrcArray);
            self.set('measureVectorLayer', measureVLayerArray);
            /**
             * Currently drawn feature.
             * @type {import("../src/ol/Feature.js").default}
             */

            let sketch;
            /**
             * The measure tooltip element.
             * @type {HTMLElement}
             */

            let measureTooltipElement;
            /**
             * Overlay to show the measurement.
             * @type {Overlay}
             */

            let measureTooltip;
            /**
             * Message to show when the user is drawing a line.
             * @type {string}
             */

            let continueLineMsg = 'Click to continue drawing the line';
            /**
             * Message to show when the user is drawing a polygon.
             * @type {string}
             */

            let continuePolygonMsg = 'Click to continue drawing the polygon';
            /**
             * Handle pointer move.
             * @param {import("../src/ol/MapBrowserEvent").default} evt The event.
             */

            /* var pointerMoveHandler = function(evt) {
              if (evt.dragging) {
                console.log('Measure tool, pointerMoveHandler evt dragging...');
                return;
              }
              var helpMsg = 'Click to start drawing';
                 if (sketch) {
                console.log('Measure tool, pointerMoveHandler if sketch...');
                var geom = sketch.getGeometry();
                if (geom instanceof Polygon) {
                  helpMsg = continuePolygonMsg;
                } else if (geom instanceof LineString) {
                  helpMsg = continueLineMsg;
                }
              }
              helpTooltipElement.innerHTML = helpMsg;
              helpTooltip.setPosition(evt.coordinate);
               helpTooltipElement.classList.remove('hidden');
             }; */
            //map.on('pointermove', pointerMoveHandler);
            //maybe not needed as we do not want to show tooltips

            var draw; // global so we can remove it later

            /**
             * Format length output.
             * @param {LineString} line The line.
             * @return {string} The formatted length.
             */

            let formatLength = function (line) {
              var length = (0, _sphere.getLength)(line);
              var output;

              if (length > 100) {
                output = Math.round(length / 1000 * 100) / 100 + ' ' + 'km';
              } else {
                output = Math.round(length * 100) / 100 + ' ' + 'm';
              }

              return output;
            };
            /**
             * Format area output.
             * @param {Polygon} polygon The polygon.
             * @return {string} Formatted area.
             */


            let formatArea = function (polygon) {
              var area = (0, _sphere.getArea)(polygon);
              var output;

              if (area > 10000) {
                output = Math.round(area / 1000000 * 100) / 100 + ' ' + 'km<sup>2</sup>';
              } else {
                output = Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>';
              }

              return output;
            };

            function addInteractionMeasure() {
              console.log('MEASURE - addInteractionMeasure ...'); //let type = (typeSelect.value == 'area' ? 'Polygon' : 'LineString');

              let type = 'LineString';
              draw = new _interaction.Draw({
                source: measureSource,
                type: type,
                style: new _style.Style({
                  fill: new _style.Fill({
                    color: 'rgba(255, 255, 255, 0.2)'
                  }),
                  stroke: new _style.Stroke({
                    color: 'rgba(255, 255, 255, 0.5)',
                    lineDash: [10, 10],
                    width: 2
                  }),
                  image: new _style.Circle({
                    radius: 5,
                    stroke: new _style.Stroke({
                      color: 'rgba(255, 255, 255, 0.7)'
                    }),
                    fill: new _style.Fill({
                      color: 'rgba(255, 255, 255, 0.2)'
                    })
                  })
                })
              });
              map.addInteraction(draw);
              map.addLayer(measureVectorLayer);
              let listener;
              let drawstartListenerKey = draw.once('drawstart', function (evt) {
                let c_srs = view1.getProjection().getCode(); // set sketch

                console.log('Measure tool, drawstart handler...');
                sketch = evt.feature;
                console.log(sketch);
                /** @type {import("../src/ol/coordinate.js").Coordinate|undefined} */

                var tooltipCoord = evt.coordinate;
                listener = sketch.getGeometry().on('change', function (evt) {
                  var geom = evt.target;
                  var output;

                  if (geom instanceof _geom.Polygon) {
                    output = formatArea(geom);
                    tooltipCoord = geom.getInteriorPoint().getCoordinates();
                  } else if (geom instanceof _geom.LineString) {
                    console.log('Measure LineString object');
                    console.log(geom);
                    let clone_geom = null;

                    if (c_srs == "EPSG:4326") {
                      clone_geom = geom.clone();
                      clone_geom.transform(c_srs, 'EPSG:32650');
                      console.log(clone_geom);
                      output = formatLength(clone_geom);
                    } else {
                      output = formatLength(geom);
                    }

                    console.log('Measure tool, drawstart handler, output is: ' + output);
                    tooltipCoord = geom.getLastCoordinate();
                  }

                  measureTooltipElement.innerHTML = output;
                  measureTooltip.setPosition(tooltipCoord);
                });
              });
              let drawendListenerKey = draw.once('drawend', function () {
                console.log('Measure tool, drawend handler...');
                measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
                measureTooltip.setOffset([0, -7]); // unset sketch

                sketch = null; // unset tooltip so that a new one can be created

                measureTooltipElement = null;
                createMeasureTooltip();
                (0, _Observable.unByKey)(listener);
                (0, _Observable.unByKey)(drawstartListenerKey);
                (0, _Observable.unByKey)(drawendListenerKey);
                map.removeInteraction(draw);
                mouse_pointer_type = 'default';
                let map_panel_arr2 = document.getElementsByClassName(_environment.default.W3O_openlayers.ol_map_panel);
                map_panel_arr2[0].style.cursor = "default"; //draw = null;

                MEASURE_ACTIVE_FLAG = false;
                console.log('Measure tool, drawend handler COMPLETED...');
              });
            } // func addInteraction 


            addInteractionMeasure();
            createMeasureTooltip();
            MEASURE_ACTIVE_FLAG = true; // to prevent multiple setup of draw event 

            /**
             * Creates a new measure tooltip
             */

            function createMeasureTooltip() {
              if (measureTooltipElement) {
                measureTooltipElement.parentNode.removeChild(measureTooltipElement);
              }

              measureTooltipElement = document.createElement('div');
              measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
              measureTooltip = new _Overlay.default({
                id: 'measure_overlay_2',
                element: measureTooltipElement,
                offset: [0, -15],
                positioning: 'bottom-center'
              });
              map.addOverlay(measureTooltip);
              measureOutOverlayArray.push(measureTooltip);
              self.set('measureOutputOverlay', measureOutOverlayArray);
            }
          } // if MEASURE_ACTIVE_FLAG

        } // if crosshair      


        if (mouse_pointer_type === 'not-allowed' && !self.isLoading) {
          console.log("Clear Measure Info tool ...");
          console.log(self.get('measureSource'));
          console.log(self.get('measureVectorLayer'));
          console.log(self.get('measureOutputOverlay'));
          let ms2 = self.get('measureSource');
          let outovly2 = self.get('measureOutputOverlay');
          ms2.forEach(source1 => source1.clear());
          outovly2.forEach(overlay1 => map.removeOverlay(overlay1));
          console.log("is there a map here..."); //console.log(map);
          //console.log(map.getOverlays());

          measureSrcArray.splice(0, measureSrcArray.length);
          measureVLayerArray.splice(0, measureVLayerArray.length);
          measureOutOverlayArray.splice(0, measureOutOverlayArray.length);
          mouse_pointer_type = 'default';
          let map_panel_arr2 = document.getElementsByClassName(_environment.default.W3O_openlayers.ol_map_panel);
          map_panel_arr2[0].style.cursor = "default";
        } // if not-allowed


        if (mouse_pointer_type === 'help' && !self.isLoading) {
          console.log("Open or close Master Plan legends ..."); // Get the modal

          let modal = document.getElementById("nlumpModal"); // Get the button that closes the modal

          let closebtn = document.getElementById("nlumpCloseBtn");

          if ((0, _jquery.default)(modal).css('display') === 'none') {
            (0, _jquery.default)(modal).css("display", "block");
          } else {
            (0, _jquery.default)(modal).css("display", "none");
          }

          (0, _jquery.default)(closebtn).on('click', function () {
            (0, _jquery.default)(modal).css("display", "none");
          });
        } // if not-allowed


        if (mouse_pointer_type === 'text' && !self.isLoading) {
          console.log("Open or close Scales selection ..."); // Get the modal

          let modal = document.getElementById("scaleModal"); // Get the button that closes the modal

          let closebtn = document.getElementById("scaleCloseBtn");

          if ((0, _jquery.default)(modal).css('display') === 'none') {
            (0, _jquery.default)(modal).css("display", "block");
          } else {
            (0, _jquery.default)(modal).css("display", "none");
          }

          (0, _jquery.default)(closebtn).on('click', function () {
            (0, _jquery.default)(modal).css("display", "none");
          });
        } // if not-allowed


        if (mouse_pointer_type === 'wait' && !self.isLoading) {
          console.log("Open or close Mobile Guide selection ...");
          window.open("".concat(self.store.adapterFor('application').get('host'), "/pdf/GeoportalMobileGuide.pdf"), '_blank'); // // Get the modal
          // let modal = document.getElementById("scaleModal");
          // // Get the button that closes the modal
          // let closebtn = document.getElementById("scaleCloseBtn");
          // if($(modal).css('display') === 'none'){
          //     $(modal).css("display","block");
          // } else {
          //     $(modal).css("display","none");
          // }
          // $(closebtn).on('click', function(){
          //     $(modal).css("display","none");
          // });
        } // if not-allowed

      });
      (0, _jquery.default)(document).on("click", ".popover .close", function () {
        (0, _jquery.default)(this).parents(".popover").popover('hide');
      });
      this.set('firstLoaded', true);
      return map;
    },

    getInitLayers(code) {
      let res1 = null;

      if (code == '5247') {
        res1 = this.get('initLayersG');
      } else if (code == '4326') {
        res1 = this.get('initLayersW');
      } else {
        res1 = this.get('initLayersT');
      }

      return res1;
    },

    getOinitialextent(map) {
      const initextent = this.get('initialExtent');
      return initextent;
    },

    getOlayers(map) {
      const store = this.get('store');
      console.log('ol-mapservice getOlayers...'); // this is likely the cause of the error - cannot fetch layers inside LG

      console.log(map.getLayers().getArray().concat());
      const layers = map.getLayers().getArray().concat().reverse().map(function (layer) {
        if (layer.getLayers) {
          let layerC = store.createRecord('ol-layer', {
            layer
          });
          const layersArray = layer.getLayers().getArray();
          const layersCArr = layersArray.map(layer => store.createRecord('ol-layer', {
            layer
          })); //const layersCArr = [];

          let compositeLayer = layerC;
          compositeLayer.group_array = layersCArr; //compositeLayer.group_class = layerC;
          //return {group_class: layerC, group_array: layersCArr};

          return compositeLayer;
        } else {
          return store.createRecord('ol-layer', {
            layer
          });
        }
      });
      console.log('ol-mapservice getOlayers... after process by...');
      console.log(layers);
      this.set('layers', layers);
      return layers;
    },

    clearSearchResults() {
      console.log('Clear Search Results');
      const map = this.get('map');
      let layers1 = map.getLayers();
      layers1.forEach(element => {//console.log(element);
        //console.log(element.getProperties());
        //console.log(element.get('title'));
      });
    },

    prepSearchResults(features) {
      console.log('Prep Search Results');
      console.log('Each feature properties...');
      features.forEach(element => {
        console.log(element.getProperties());
        console.log(element.getGeometry());
        console.log(element.getStyle());
        console.log('feature lot num: ' + element.get('LOTNUM'));
      });
    },

    async fetchNearbyWfsObjects(swfs1, slayer1, scoord1, stype) {
      let fetchresults = null;
      const map = this.get('map');

      let _s_srs = map.getView().getProjection().getCode();

      console.log('ol-mapservice fetchwfsobjects srs found : ' + _s_srs);
      let searchCoordCal = '';

      if (_s_srs == 'EPSG:4326') {
        //const carray = scoord1.split(",");
        console.log(scoord1);
        const carray5247 = (0, proj.transform)(scoord1, 'EPSG:4326', 'EPSG:5247');
        console.log(carray5247);
        let x1 = carray5247[0] - 1772.3470206865; // hack to fix proj4.js bug in handling omerc

        let y1 = carray5247[1] + 1901.6041742575;
        searchCoordCal = [x1, y1];
        _s_srs = 'EPSG:5247';
      } else if (_s_srs == 'EPSG:29873') {
        console.log(scoord1);
        const carray5247 = (0, proj.transform)(scoord1, 'EPSG:29873', 'EPSG:5247');
        console.log(carray5247);
        let x1 = carray5247[0] - 1432.1054073741; // hack to fix proj4.js bug in handling omerc

        let y1 = carray5247[1] + 1917.4047292559;
        searchCoordCal = [x1, y1];
        _s_srs = 'EPSG:5247';
      } else {
        searchCoordCal = scoord1;
      }

      let fetchxmlStr = '<ogc:Filter xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" xmlns="http://www.opengis.net/ogc"><ogc:DWithin><ogc:PropertyName xmlns:prp="http://www.erdas.com/wfs">SHAPE</ogc:PropertyName><gml:Point xmlns:gml="http://www.opengis.net/gml"><gml:coordinates>' + searchCoordCal + '</gml:coordinates></gml:Point><ogc:Distance units="m">1</ogc:Distance></ogc:DWithin></ogc:Filter>';
      console.log('ol-mapservice fetchwfsobjects fetchstr : ' + fetchxmlStr); //url = new URL("http://survey.gov.bn/erdas-apollo/vector/LOTS3")

      let url = new URL(swfs1),
          params = {
        SERVICE: 'WFS',
        VERSION: '1.1.0',
        REQUEST: 'GetFeature',
        SRS: _s_srs,
        typename: slayer1,
        Filter: fetchxmlStr
      };
      Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

      const getFilterWfs = async () => {
        try {
          let url11 = url;
          return await _axios.default.get(url11);
        } catch (error) {
          console.error(error);
        }
      };

      const queryWfs = async () => {
        const response = await getFilterWfs();
        console.log('getFilterWfs from WFS API .. result is'); //console.log(response.data);

        if (Boolean(response.data)) {
          return response.data;
        } else {
          return '';
        }
      };

      console.log('TEST AXIOS OUT 2');
      let taxaa = queryWfs(); //console.log(taxaa);

      fetchresults = queryWfs().then(function (xml) {
        var format = new _GML.default({
          srsName: _s_srs,
          surface: false,
          curve: false
        });
        console.log('hoichong ol-mapservice fetchWfsObjects WFS GET response'); //console.log(xml);
        //try to parse data

        var features = format.readFeatures(xml, {
          dataProjection: _s_srs,
          featureProjection: _s_srs
        });
        console.log(features);
        console.log(format.readProjection(xml)); //GML2 from OL cannot read the projection info from Apollo GML
        //self.prepSearchResults(features);

        let fres2 = []; // switch (stype) {
        //   case 'Lots':
        //     fres2 = features.map(function (feature1) {
        //       let cf1 = feature1.getProperties();
        //       delete cf1['SHAPE'];
        //       let mt1 = {
        //         objectnum: feature1.get('OBJECTID'),
        //         objectfield1: cf1,
        //         objectfield0: 'Lots'
        //       };
        //       return mt1;
        //     });
        //     break;
        //   case 'TOL':
        //     fres2 = features.map(function (feature1) {
        //       let cf1 = feature1.getProperties();
        //       delete cf1['SHAPE'];
        //       let mt1 = {
        //         objectnum: feature1.get('OBJECTID'),
        //         objectfield1: cf1,
        //         objectfield0: 'TOL'
        //       };
        //       return mt1;
        //     });
        //     break;
        //   case 'Gazette':
        //     fres2 = features.map(function (feature1) {
        //       let cf1 = feature1.getProperties();
        //       delete cf1['SHAPE'];
        //       let mt1 = {
        //         objectnum: feature1.get('OBJECTID'),
        //         objectfield1: cf1,
        //         objectfield0: 'Gazette'
        //       };
        //       return mt1;
        //     });
        //     break;
        //   case 'Kampung':
        //     fres2 = features.map(function (feature1) {
        //       let cf1 = feature1.getProperties();
        //       delete cf1['SHAPE'];
        //       let mt1 = {
        //         objectnum: feature1.get('OBJECTID'),
        //         objectfield1: cf1,
        //         objectfield0: 'Kampung'
        //       };
        //       return mt1;
        //     });
        //     break;          
        //   default:
        //     console.log('Wrong Fetch Type');
        // }

        fres2 = features.map(function (feature1) {
          let cf1 = feature1.getProperties();
          delete cf1['SHAPE'];
          let mt1 = {
            objectnum: feature1.get('OBJECTID'),
            objectfield1: cf1,
            objectfield0: stype.type
          };
          return mt1;
        });
        return fres2;
      });
      console.log('Fetch Results');
      console.log(fetchresults);
      return fetchresults;
    },

    async fetchWithinWfsObjects(swfs1, slayer1, scoord1, stype) {
      console.log('fetchWithinWfsObjects');
      console.log(swfs1, slayer1, scoord1, stype);
      let fetchresults = null;
      const map = this.get('map');

      let _s_srs = map.getView().getProjection().getCode();

      console.log('ol-mapservice fetchwfsobjects srs found - within: ' + _s_srs);
      let searchCoordCal = '',
          searchCoordCal_min = '',
          searchCoordCal_max = '';
      let CoordCal_min = [],
          CoordCal_max = [];
      scoord1.forEach(coord => {
        CoordCal_min.push(coord - 7);
        CoordCal_max.push(coord + 7);
      });
      console.log('min max coords');
      console.log(CoordCal_min);
      console.log(CoordCal_max);

      if (_s_srs == 'EPSG:4326') {
        //const carray = scoord1.split(",");
        //   console.log(scoord1);
        const carray5247_min = (0, proj.transform)(CoordCal_min, 'EPSG:4326', 'EPSG:5247');
        const carray5247_max = (0, proj.transform)(CoordCal_max, 'EPSG:4326', 'EPSG:5247');
        console.log(carray5247_min);
        console.log(carray5247_max);
        let x1_min = carray5247_min[0] - 1772.3470206865; // hack to fix proj4.js bug in handling omerc

        let y1_min = carray5247_min[1] + 1901.6041742575;
        let x1_max = carray5247_max[0] - 1772.3470206865; // hack to fix proj4.js bug in handling omerc

        let y1_max = carray5247_max[1] + 1901.6041742575;
        searchCoordCal_min = [x1_min, y1_min];
        searchCoordCal_max = [x1_max, y1_max];
        _s_srs = 'EPSG:5247';
      } else if (_s_srs == 'EPSG:29873') {
        //   console.log(scoord1);
        const carray5247_min = (0, proj.transform)(CoordCal_min, 'EPSG:29873', 'EPSG:5247');
        const carray5247_max = (0, proj.transform)(CoordCal_max, 'EPSG:29873', 'EPSG:5247');
        console.log(carray5247_min);
        console.log(carray5247_max);
        let x1_min = carray5247_min[0] - 1432.1054073741; // hack to fix proj4.js bug in handling omerc

        let y1_min = carray5247_min[1] + 1917.4047292559;
        let x1_max = carray5247_max[0] - 1432.1054073741; // hack to fix proj4.js bug in handling omerc

        let y1_max = carray5247_max[1] + 1917.4047292559;
        searchCoordCal_min = [x1_min, y1_min];
        searchCoordCal_max = [x1_max, y1_max];
        _s_srs = 'EPSG:5247';
      } else {
        searchCoordCal_min = CoordCal_min;
        searchCoordCal_max = CoordCal_max;
      } // let fetchxmlStr = '<ogc:Filter xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" xmlns="http://www.opengis.net/ogc"><ogc:DWithin><ogc:PropertyName xmlns:prp="http://www.erdas.com/wfs">SHAPE</ogc:PropertyName><gml:Point xmlns:gml="http://www.opengis.net/gml"><gml:coordinates>' + searchCoordCal + '</gml:coordinates></gml:Point><ogc:Distance units="m">1</ogc:Distance></ogc:DWithin></ogc:Filter>';


      let fetchxmlStr = "<ogc:Filter xmlns:gml=\"http://www.opengis.net/gml\" xmlns:ogc=\"http://www.opengis.net/ogc\" xmlns=\"http://www.opengis.net/ogc\"><ogc:BBOX><ogc:PropertyName xmlns:prp=\"http://www.erdas.com/wfs\">SHAPE</ogc:PropertyName><gml:Box srsName=\"urn:x-ogc:def:crs:".concat(_s_srs, "\"><gml:coordinates>").concat(searchCoordCal_min, " ").concat(searchCoordCal_max, "</gml:coordinates></gml:Box></ogc:BBOX></ogc:Filter>");
      console.log('ol-mapservice fetchwfsobjects fetchstr : ' + fetchxmlStr); //url = new URL("http://survey.gov.bn/erdas-apollo/vector/LOTS3")

      let url = new URL(swfs1),
          params = {
        SERVICE: 'WFS',
        VERSION: '1.1.0',
        REQUEST: 'GetFeature',
        SRS: _s_srs,
        typename: slayer1,
        Filter: fetchxmlStr
      };
      Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

      const getFilterWfs = async () => {
        try {
          let url11 = url;
          return await _axios.default.get(url11);
        } catch (error) {
          console.error(error);
        }
      };

      const queryWfs = async () => {
        const response = await getFilterWfs();
        console.log('getFilterWfs from WFS API .. result is within');
        console.log(response.data);

        if (Boolean(response.data)) {
          return response.data;
        } else {
          return '';
        }
      };

      console.log('TEST AXIOS OUT 2');
      let taxaa = queryWfs(); //console.log(taxaa);

      fetchresults = queryWfs().then(function (xml) {
        var format = new _GML.default({
          srsName: _s_srs,
          surface: false,
          curve: false
        });
        console.log('hoichong ol-mapservice fetchWfsObjects WFS GET response'); //console.log(xml);
        //try to parse data

        var features = format.readFeatures(xml, {
          dataProjection: _s_srs,
          featureProjection: _s_srs
        });
        console.log(features);
        console.log(format.readProjection(xml)); //GML2 from OL cannot read the projection info from Apollo GML
        //self.prepSearchResults(features);

        let fres2 = [];
        fres2 = features.map(function (feature1) {
          let cf1 = feature1.getProperties();
          delete cf1['SHAPE'];
          let mt1 = {
            objectnum: feature1.get('OBJECTID'),
            objectfield1: cf1,
            objectfield0: stype.type
          };
          return mt1;
        });
        return fres2;
      });
      console.log('Fetch Results within');
      console.log(fetchresults);
      return fetchresults;
    },

    async findWfsObjects(self, swfs1, slayer1, sattr1, sstr1, stype) {
      let foundresults = null;
      const map = this.get('map');

      let _s_srs = map.getView().getProjection().getCode();

      console.log('ol-mapservice findwfsobjects srs found : ' + _s_srs);
      let filterxmlStr = '<ogc:Filter xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" xmlns="http://www.opengis.net/ogc"><ogc:PropertyIsLike singleChar="_" wildCard="%" matchCase="false"><ogc:PropertyName xmlns:prp="http://www.erdas.com/wfs">' + sattr1 + '</ogc:PropertyName><ogc:Literal>' + sstr1 + '</ogc:Literal></ogc:PropertyIsLike></ogc:Filter>';
      console.log('ol-mapservice findwfsobjects filterstr : ' + filterxmlStr);
      let searchFilterStr = ''; // hack needed for Edge browser 

      if (window.navigator.userAgent.indexOf("Edge") > -1) {
        let fStr11 = filterxmlStr.replace(/%/g, "%25");
        let fStr12 = fStr11.replace(/=/g, "%3D");
        let fStr13 = fStr12.replace(/\//g, "%2F");
        let fStr14 = fStr13.replace(/:/g, "%3A");
        searchFilterStr = fStr14;
      } else {
        searchFilterStr = filterxmlStr;
      } //url = new URL("http://survey.gov.bn/erdas-apollo/vector/LOTS3")


      let url = new URL(swfs1),
          params = {
        SERVICE: 'WFS',
        VERSION: '1.1.0',
        REQUEST: 'GetFeature',
        SRS: _s_srs,
        typename: slayer1,
        Filter: searchFilterStr
      };
      Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
      /* let filterxmlStr2 = '<ogc:Filter xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" xmlns="http://www.opengis.net/ogc"><ogc:PropertyIsLike singleChar="_" wildCard="%" matchCase="false"><ogc:PropertyName xmlns:prp="http://www.erdas.com/wfs">VC_LOTNUM</ogc:PropertyName><ogc:Literal>%3854%</ogc:Literal></ogc:PropertyIsLike></ogc:Filter>';
       let url2 = new URL("http://localhost/erdas-apollo/vector/LOTS3"),
        params2 = {
          SERVICE: 'WFS',
          VERSION: '1.1.0',
          REQUEST: 'GetFeature',
          SRS: 'EPSG:29873',
          typename: 'MV_LOTS3',
          Filter: filterxmlStr2,
        }
      Object.keys(params2).forEach(key => url2.searchParams.append(key, params2[key])) */
      //hardcode for testing

      const getFilterWfs = async () => {
        try {
          let url11 = url;
          return await _axios.default.get(url11);
        } catch (error) {
          console.error(error);
        }
      };

      const queryWfs = async () => {
        const response = await getFilterWfs();
        console.log('getFilterWfs from WFS API .. result is'); //console.log(response.data);

        if (Boolean(response.data)) {
          return response.data;
        } else {
          return '';
        }
      };

      console.log('TEST AXIOS OUT'); //let taxaa = queryWfs();
      //console.log(taxaa);

      foundresults = queryWfs().then(function (xml) {
        var format = new _GML.default({
          srsName: _s_srs,
          surface: false,
          curve: false
        });
        console.log('hoichong ol-mapservice findWfsObjects WFS GET response'); //console.log(xml);
        //try to parse data

        var features = format.readFeatures(xml, {
          dataProjection: _s_srs,
          featureProjection: _s_srs
        });
        console.log(features);
        console.log(format.readProjection(xml)); //self.prepSearchResults(features);

        let fres2 = [];

        switch (stype) {
          case 'Geodetic':
          case 'Geodetic_exact':
            fres2 = features.map(function (feature1) {
              let mt1 = {
                objectnum: feature1.get('STN_NAME'),
                objectfield1: feature1.get('JOB_NUM') + ' : HT: ' + feature1.get('ELL_HT') + ', ELEVATION: ' + feature1.get('ELEVATION') + ', LOCATION: ' + feature1.get('LOCATION'),
                objectfield2: 'Place',
                geom: feature1.get('SHAPE') //feature: feature1 // no need to return the entire feature, just the geometry/shape will do

              };
              return mt1;
            });
            break;

          case 'Lots':
          case 'Lots_exact':
            fres2 = features.map(function (feature1) {
              let mt1 = {
                objectnum: feature1.get('LOTNUM'),
                objectfield1: feature1.get('DISTCODE') + ' : ' + feature1.get('BOOKCODE') + ', ' + feature1.get('VC_RSO_NUM') + ',  Kg.' + feature1.get('DESCRIPTION') + ', EDR: ' + feature1.get('VC_EDRNUM'),
                objectfield2: '',
                geom: feature1.get('SHAPE') //feature: feature1 // no need to return the entire feature, just the geometry/shape will do

              };
              return mt1;
            });
            break;

          case 'Lots_EDR':
            fres2 = features.map(function (feature1) {
              let mt1 = {
                objectnum: feature1.get('EDRNUM'),
                objectfield1: feature1.get('DISTCODE') + ' : ' + feature1.get('BOOKCODE') + ', ' + feature1.get('VC_RSO_NUM') + ',  Kg.' + feature1.get('DESCRIPTION') + ', LOT: ' + feature1.get('VC_LOTNUM'),
                objectfield2: '',
                geom: feature1.get('SHAPE') //feature: feature1 // no need to return the entire feature, just the geometry/shape will do

              };
              return mt1;
            });
            break;

          case 'TOL':
            fres2 = features.map(function (feature1) {
              let mt1 = {
                objectnum: feature1.get('TOLNUM'),
                objectfield1: feature1.get('DISTCODE') + ' : ' + feature1.get('BOOKCODE'),
                objectfield2: '',
                geom: feature1.get('SHAPE') //feature: feature1 // no need to return the entire feature, just the geometry/shape will do

              };
              return mt1;
            });
            break;

          case 'Gazette':
          case 'Gazette_exact':
            fres2 = features.map(function (feature1) {
              let mt1 = {
                objectnum: feature1.get('GR_NUM'),
                objectfield1: feature1.get('MINISTRIES_NAME') + ' : ' + feature1.get('DEPARTMENTS_NAME') + ' ' + feature1.get('VC_GR_TYPE'),
                objectfield2: '',
                geom: feature1.get('SHAPE') //feature: feature1 // no need to return the entire feature, just the geometry/shape will do

              };
              return mt1;
            });
            break;

          case 'Place':
            fres2 = features.map(function (feature1) {
              let mt1 = {
                objectnum: feature1.get('DESCR') ? feature1.get('DESCR').replace(/ .*/, '') : ' ',
                objectfield1: feature1.get('PLACENAME'),
                objectfield2: 'Place',
                geom: feature1.get('SHAPE') //feature: feature1 // no need to return the entire feature, just the geometry/shape will do

              };
              return mt1;
            });
            break;

          case 'Kampung':
            fres2 = features.map(function (feature1) {
              let mt1 = {
                objectnum: feature1.get('KG_NAME'),
                objectfield1: feature1.get('MUKIM_NAME'),
                objectfield2: 'Kampung',
                geom: feature1.get('SHAPE') //feature: feature1 // no need to return the entire feature, just the geometry/shape will do

              };
              return mt1;
            });
            break;

          case 'Mukim':
            fres2 = features.map(function (feature1) {
              let mt1 = {
                objectnum: feature1.get('MUKIM_NAME'),
                objectfield1: feature1.get('DISTCODE'),
                objectfield2: 'Mukim',
                geom: feature1.get('SHAPE') //feature: feature1 // no need to return the entire feature, just the geometry/shape will do

              };
              return mt1;
            });
            break;

          case 'RSO':
            fres2 = features.map(function (feature1) {
              let mt1 = {
                objectnum: feature1.get('RSOREF'),
                objectfield1: '',
                objectfield2: '',
                geom: feature1.get('SHAPE') //feature: feature1 // no need to return the entire feature, just the geometry/shape will do

              };
              return mt1;
            });
            break;

          default:
            console.log('Wrong Search, Did not find any features');
        } //const vectorSource = this.get('vectorSource');
        //vectorSource.addFeatures(features); // won't work because OL do not process Apollo GML correctly , need to enhance OL 
        //console.log(vectorSource.getProjection());
        //vectorSource.refresh();
        //console.log('ol-mapservice map layers are: ');
        //console.log(map.getLayers());


        return fres2;
      }); //console.log(foundresults);

      return foundresults;
    },

    animateCSS(element, animationName, callback) {
      const node = document.querySelector(element);
      node.classList.add('animated', animationName);

      function handleAnimationEnd() {
        node.classList.remove('animated', animationName);
        node.removeEventListener('animationend', handleAnimationEnd);
        if (typeof callback === 'function') callback();
      }

      node.addEventListener('animationend', handleAnimationEnd);
    },

    setLayerVisType(layer, checkval) {
      console.log('ol-mapservice setLayerVisType...');
      let imgLayer = null;

      if ('group_array' in layer) {
        //imgLayer = layer.group_class.get('layer');
        imgLayer = layer.get('layer');
      } else {
        imgLayer = layer.get('layer');
      }

      console.log(layer);
      console.log(checkval);
      console.log(imgLayer.values_.minScale + '  ' + imgLayer.values_.maxScale);
      imgLayer.values_.visiType = 'Manual'; //console.log(layer.visiType);
    },

    setRSOSize(rsosize) {
      this.set('rsosize', rsosize);
    },

    setMobileMode(value) {
      this.set('isMobile', value);
    }

  });

  _exports.default = _default;
});