define("web-map/templates/unauthorized", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ct+zty7D",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"loading-pane\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card\"],[8],[0,\"\\n\\t\\t\"],[7,\"h3\",true],[8],[0,\"You are not authorised to access this page.\"],[7,\"br\",true],[8],[9],[0,\"\\n\\t\\tPlease contact your application administrator to resolve this issue.\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-map/templates/unauthorized.hbs"
    }
  });

  _exports.default = _default;
});