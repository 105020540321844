define("web-map/routes/disclaimermap-mobile", ["exports", "web-map/utils/get-cookie"], function (_exports, _getCookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      gotoHome() {
        //do not transition if user choose to not accept the conditions on mobile
        if ((0, _getCookie.default)('isMobile') === "No") {
          //cannot use transitionTo because it wont load the map
          window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/start-gp"));
        }
      },

      gotoPublic() {
        if ((0, _getCookie.default)('isMobile') === "No") {
          //cannot use transitionTo because it wont load the map
          window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/map"));
        } else {
          //set to yes so it won't display the disclaimer again
          document.cookie = "public_disclaimer=Yes";
          window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/momap"));
        }
      }

    }
  });

  _exports.default = _default;
});