define("web-map/models/company", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    companyid: _emberData.default.attr('string'),
    companyname: _emberData.default.attr('string'),
    companylegalname: _emberData.default.attr('string'),
    companycontact: _emberData.default.attr('string'),
    companyphone: _emberData.default.attr('string'),
    companylogopath: _emberData.default.attr('string'),
    companylogotype: _emberData.default.attr('string'),

    /*cmpyfax: DS.attr('string'),*/

    /*addressnum: DS.attr('number'),*/
    currencydecimalplace: _emberData.default.attr('number'),
    isseparatoruse: _emberData.default.attr('boolean'),
    dateformat: _emberData.default.attr('string'),
    companylogo: _emberData.default.attr('string'),
    isdelete: _emberData.default.attr('boolean'),
    filladdress: _emberData.default.attr('string'),
    co_registration: _emberData.default.attr('string'),
    companyregistrationnumber: _emberData.default.attr('string'),
    co_bank_name: _emberData.default.attr('string'),
    co_bank_acc: _emberData.default.attr('string'),
    co_bank_acc_name: _emberData.default.attr('string'),
    BIC_code: _emberData.default.attr('string'),
    dbs_organizationid: _emberData.default.attr('string'),
    company_director_name: _emberData.default.attr('string'),
    company_director_file: _emberData.default.attr('string'),

    /*Computed Property*/
    companynum: Ember.computed('id', function () {
      return {
        id: this.get('id'),
        isCompany: true
      };
    }),
    //relation
    // addressnum: DS.belongsTo('address', {async: true}),
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    // addresses: DS.hasMany('address', {async: true, inverse: 'companynum'}),
    // basecurrencynum: DS.belongsTo('currency', {async: true}),
    // divisions: DS.hasMany("division",{async: true}),
    // kpis: DS.hasMany("kpi",{async: true}),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
    /*plantnums: DS.hasMany('base0plant')*/

    /*basecurrnum:
    currdecplace:
    issepartoruse:
    dateformat*/

  });

  _exports.default = _default;
});