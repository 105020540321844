define("web-map/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function () {
      // this.transitionTo('start-gp');
      if (window.location.hostname === 'geoportal.survey.gov.bn') {
        window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/start-gp"));
      } else {
        window.location.replace("".concat(this.store.adapterFor('application').get('hostSurvey'), "/start"));
      }
    }
  });

  _exports.default = _default;
});