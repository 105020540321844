define("web-map/mixins/w3o-users-roles/modal-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      openModal(modalName, currentController) {
        this.render(modalName, {
          into: 'application',
          outlet: 'modal',
          controller: currentController
        });
      },

      closeModal() {
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });
      }

    }
  });

  _exports.default = _default;
});