define("web-map/templates/components/function-panel-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AKOXb9gt",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[7,\"button\",true],[10,\"class\",\"btn nav-button\"],[10,\"id\",\"cFPButton\"],[10,\"type\",\"button\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"style\",\"color: white\"],[8],[1,[28,\"fa-icon\",[\"caret-left\"],null],false],[9],[0,\"\\n  \"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-map/templates/components/function-panel-button.hbs"
    }
  });

  _exports.default = _default;
});