define("web-map/authenticators/authy", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant"], function (_exports, _oauth2PasswordGrant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    refreshAccessTokens: true,
    serverTokenEndpoint: '/api/v1/auths/login2',
    // serverTokenRevocationEndpoint: '/revoke'
    routing: Ember.inject.service('-routing'),
    authObject: Ember.inject.service('session'),

    authenticate(identification, password, authCode, scope) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        const data = {
          'grant_type': 'password',
          username: identification,
          password,
          authCode
        };
        const serverTokenEndpoint = this.get('serverTokenEndpoint');
        const scopesString = Ember.makeArray(scope).join(' ');

        if (!Ember.isEmpty(scopesString)) {
          data.scope = scopesString;
        }

        this.makeRequest(serverTokenEndpoint, data).then(response => {
          Ember.run(() => {
            const expiresAt = this._absolutizeExpirationTime(response['expires_in']);

            this._scheduleAccessTokenRefresh(response['expires_in'], expiresAt, response['refresh_token']);

            if (!Ember.isEmpty(expiresAt)) {
              response = Ember.merge(response, {
                'expires_at': expiresAt
              });
            }

            resolve(response);
          });
        }, xhr => {
          Ember.run(null, reject, xhr.responseJSON || xhr.responseText);
        });
      });
    },

    _refreshAccessToken(expiresIn, refreshToken) {
      var self = this;
      const data = {
        'grant_type': 'refresh_token',
        'refresh_token': refreshToken
      };
      const serverTokenEndpoint = this.get('serverTokenEndpoint');
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.makeRequest(serverTokenEndpoint, data).then(response => {
          Ember.run(() => {
            expiresIn = response['expires_in'] || expiresIn;
            refreshToken = response['refresh_token'] || refreshToken;

            const expiresAt = this._absolutizeExpirationTime(expiresIn);

            const data = Ember.merge(response, {
              'expires_in': expiresIn,
              'expires_at': expiresAt,
              'refresh_token': refreshToken
            });

            this._scheduleAccessTokenRefresh(expiresIn, null, refreshToken);

            this.trigger('sessionDataUpdated', data);
            resolve(data);
          });
        }, (xhr, status, error) => {
          Ember.Logger.warn("Access token could not be refreshed - server responded with ".concat(error, "."));
          reject(self.get('routing').transitionTo('users-roles.session-expired'));
        });
      });
    }

  });

  _exports.default = _default;
});