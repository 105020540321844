define("web-map/controllers/stmap", ["exports", "web-map/mixins/w3o-users-roles/invalidate-session", "axios"], function (_exports, _invalidateSession, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_invalidateSession.default, {
    olmap2svc: Ember.inject.service('ol-mapservice'),
    authObject: Ember.inject.service('session'),
    componentRef: null,
    canInitLayers: false,
    canReInitLayers: false,
    // not being used now
    canClearResults: false,
    geoRoleId: null,

    async init() {
      this._super(...arguments);

      console.log('authObject');
      console.log(this.get('authObject'));
      let userid = this.get('authObject.data.user.id');
      console.log(userid); //getting the user role from API

      if (this.get('authObject.isAuthenticated')) {
        let url_userrole = this.store.adapterFor('application').get('serverHost') + this.store.adapterFor('application').get('namespace') + '/layersetups/getUserRole';
        let response = await _axios.default.post(url_userrole, {
          userid
        }).catch(error => {
          console.log("An error occured while getting user role, ".concat(error));
          alert("An error occured while getting user role, ".concat(error));
        }); // console.log('user role');
        // console.log(response);
        //the login user is not part of registered user of Geoportal

        if (response.data.registered === false) {
          alert('Sorry, you are not allowed to view Registered User contents');
          window.location.replace("".concat(this.store.adapterFor('application').get('host'), "/start-gp"));
        } else {
          this.set('geoRoleId', response.data.roleid);
        } // console.log('end init');

      }
    },

    actions: {
      async initMap() {
        // console.log('start init map');
        const olmap2svc = this.get('olmap2svc');
        await olmap2svc.promiseInit(this.get('geoRoleId'));
        const map = olmap2svc.getOmap();
        const initialExtent = olmap2svc.getOinitialextent(map);
        const layers = olmap2svc.getOlayers(map);
        this.setProperties({
          map: map,
          initialExtent: initialExtent,
          layers: layers,
          isInitialized: true
        });
        console.log('hoichong stmap controller initMap done');
      },

      closePopOver() {
        let elem = document.getElementById('popup');
        $(elem).popover('dispose');
      },

      finishMapSetup(element) {
        const map = this.get('map');
        console.log("Inside stmap controller, map is: " + map);
        map.setTarget(element); //const initialExtent = this.get('initialExtent')
        //if (initialExtent) map.getView().fit(initialExtent, map.getSize())

        this.set('canInitLayers', true);
      },

      reloadMapLayers(code) {
        const map = this.get('map');
        const olmap2svc = this.get('olmap2svc');
        console.log("Inside stmap controller, doing a reloadMapLayers");
        this.set('canReInitLayers', true); // correct way to remove all layers from map

        let clayers1 = [...map.getLayers().getArray()];
        clayers1.forEach(layer => map.removeLayer(layer));
        let alayer1 = olmap2svc.getInitLayers(code);
        alayer1.forEach(layer => {
          //console.log(layer);
          map.addLayer(layer);
        });
        const layers = olmap2svc.getOlayers(map);
        this.set('layers', layers);
        this.set('canClearResults', true); //console.log("Inside stmap controller, canClearResults: " + this.get('canClearResults')); 
      },

      resetClearResults() {
        this.set('canClearResults', false);
      },

      registerComponent(componentRef) {
        this.set('componentRef', componentRef);
      },

      deregisterComponent() {
        this.set('componentRef', null);
      },

      callCompleteMapSetup() {
        this.get('componentRef').completeMapSetup();
      },

      /* Above code finishMapSetup is to fix the problem of synching the Map object to be available for setTarget to element of the ol-map-panel,  
      in a simplistic scenario, where all map visual elements are within the map then ol-map-panel can setTarget, but because we need to set the 
      X, Y coordinates to outside of the map, the code to trigger the initMap has to be in the map-info-bar component, and therefore a timing issue arise, 
      so this finishMapSetup method (map.setTarget) is here, so that this controller can do initMap and finishMapSetup in correct sequence. 
      All this in order to make OpenLayers and Emberjs work well together. */

      /* registerComponent and deregisterComponent is an attempt to setup something where One component can call Another component */
      invalidateSession() {
        this.invalidateSession();
      },

      authorize() {
        this.store.adapterFor('application').authorizeToken();
      }

    }
  });

  _exports.default = _default;
});