define("web-map/templates/components/feature-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ztC2dyz7",
    "block": "{\"symbols\":[\"t\",\"body\",\"row\"],\"statements\":[[4,\"light-table\",[[24,[\"table\"]]],null,{\"statements\":[[0,\"\\n  \"],[1,[23,1,[\"head\"]],false],[0,\"\\n\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"body\"]],\"expected `t.body` to be a contextual component but found a string. Did you mean `(component t.body)`? ('web-map/templates/components/feature-table.hbs' @ L5:C5) \"],null]],null,{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,2,[\"expanded-row\"]],\"expected `body.expanded-row` to be a contextual component but found a string. Did you mean `(component body.expanded-row)`? ('web-map/templates/components/feature-table.hbs' @ L6:C7) \"],null]],null,{\"statements\":[[0,\"      Hello \"],[7,\"b\",true],[8],[1,[23,3,[\"firstName\"]],false],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,2,[\"loader\"]],\"expected `body.loader` to be a contextual component but found a string. Did you mean `(component body.loader)`? ('web-map/templates/components/feature-table.hbs' @ L11:C9) \"],null]],null,{\"statements\":[[0,\"        Loading...\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"table\",\"isEmpty\"]]],null,{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,2,[\"no-data\"]],\"expected `body.no-data` to be a contextual component but found a string. Did you mean `(component body.no-data)`? ('web-map/templates/components/feature-table.hbs' @ L17:C9) \"],null]],null,{\"statements\":[[0,\"        No users found.\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[2]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-map/templates/components/feature-table.hbs"
    }
  });

  _exports.default = _default;
});