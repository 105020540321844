define("web-map/templates/components/ol-layer-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/h6s8a8D",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ol-layer-tree-group\",null,[[\"layers\",\"canInitLayers\",\"isInitLayerGroup\"],[[24,[\"layers\"]],[24,[\"canInitLayers\"]],false]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-map/templates/components/ol-layer-tree.hbs"
    }
  });

  _exports.default = _default;
});