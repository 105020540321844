define("web-map/routes/change-password", ["exports", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin", "web-map/mixins/w3o-users-roles/invalidate-session", "web-map/mixins/w3o-users-roles/set-session-user", "axios"], function (_exports, _applicationRouteMixin, _authenticatedRouteMixin, _invalidateSession, _setSessionUser, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, _invalidateSession.default, _setSessionUser.default, {
    authObject: Ember.inject.service('session'),

    async beforeModel() {
      this._super();

      if (this.get('authObject.data.user')) {
        let user = await this.store.findRecord('user', this.get('authObject.data.user.id'));

        if (!user.get('forceChangePassword')) {
          this.transitionTo('start');
        }
      } else {
        this.transitionTo('start');
      }
    },

    model() {
      return this.store.findRecord('user', this.get('authObject.data.user.id'));
    },

    setupController(controller, model) {
      controller.setProperties({
        authObject: this.get("authObject"),
        model,
        newPassword: null,
        repeatNewPassword: null
      });
    },

    actions: {
      changePassword() {
        let controller = this.get('controller');
        let model = controller.get('model');
        let flag = 0;

        if (Ember.isEmpty(controller.get("newPassword"))) {
          controller.set("errorMessage", 'Please input password!');
          flag++;
          return;
        } else {
          /*
          	Regex for password
          	at least 8 characters
          	at least contains 1 uppercase, 1 lowercase, 1 number, and 1 special character( !"#$%&'()*+,-.:;<=>/?@\^[]_`{}|~ no space currently) 
          */
          let regexPass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,-.:;<=>/?@\\^[\]_`{}|~])[a-zA-Z\d!"#$%&'()*+,-.:;<=>/?@\\^[\]_`{}|~]{8,}$/;

          if (!regexPass.test(controller.get("newPassword"))) {
            controller.set("errorMessage", 'Password must be at least 8 characters and contains 1 uppercase, 1 lowercase, 1 number, and 1 special character!');
            flag++;
            return;
          }
        }

        if (controller.get("newPassword") !== controller.get("repeatNewPassword")) {
          controller.set("errorMessage", 'Password is not same!');
          flag++;
          return;
        }

        if (flag === 0) {
          controller.set("errorMessage", null);
          this.controllerFor("application").set("showAppLoading", true);
          (0, _axios.default)({
            url: '/api/v1/users/reset/',
            method: 'POST',
            data: {
              id: model.get('id'),
              siteAdmin: true,
              password: controller.get("newPassword"),
              forceChangePassword: true
            }
          }).then(async () => {
            let response = await this.setSessionUser();

            if (response) {
              controller.set('success', true);
              this.controllerFor("application").set("showAppLoading", false);
              this.transitionTo('start');
            }
          }).catch(err => {
            controller.set('message', err.response.data);
          });
        }
      },

      invalidateSession() {
        this.invalidateSession();
      }

    }
  });

  _exports.default = _default;
});