define("web-map/routes/privacy-policy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      gotoHome() {
        //cannot use transitionTo because it wont load the map
        window.location.replace("https://".concat(window.location.hostname));
      },

      conditionsOfUse() {
        window.location.replace("https://".concat(window.location.hostname, "/conditions-of-use"));
      },

      privacyPolicy() {
        window.location.replace("https://".concat(window.location.hostname, "/privacy-policy"));
      },

      disclaimer() {
        window.location.replace("https://".concat(window.location.hostname, "/disclaimer"));
      }

    }
  });

  _exports.default = _default;
});