define("web-map/models/order", ["exports", "ember-data", "ember-data/attr"], function (_exports, _emberData, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    createdAt: (0, _attr.default)('date', {
      defaultValue() {
        return new Date();
      }

    }),
    isdelete: _emberData.default.attr('boolean'),
    order_number: _emberData.default.attr('string'),
    order_description: _emberData.default.attr('string'),
    total_price: _emberData.default.attr('number'),
    total_data_size: _emberData.default.attr('number'),
    type: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    foc_name: _emberData.default.attr('string'),
    foc_department: _emberData.default.attr('string'),
    foc_purpose: _emberData.default.attr('string'),
    foc_remark: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user')
  });

  _exports.default = _default;
});