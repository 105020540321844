define("web-map/helpers/plus-one", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function plusOne(params
  /*, hash*/
  ) {
    if (isNaN(params[0])) {
      return params;
    }

    return parseInt(params[0] + 1);
  });

  _exports.default = _default;
});