define("web-map/templates/under-construction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "50UUnPHP",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\n\\n\"],[7,\"div\",true],[10,\"class\",\"container under-cons\"],[8],[0,\"\\n    \"],[7,\"p\",true],[8],[7,\"i\",true],[10,\"class\",\"fa fa-wrench\"],[8],[9],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"desc\"],[8],[0,\"Coming Soon\"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"desc2\"],[8],[7,\"i\",true],[8],[0,\"We are still working on it\"],[9],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"backhome\"],[8],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"/start\"],[8],[0,\"Back to Home\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-map/templates/under-construction.hbs"
    }
  });

  _exports.default = _default;
});