define("web-map/mixins/checkout/checkout-list", ["exports", "jquery", "axios"], function (_exports, _jquery, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    authObject: Ember.inject.service('session'),

    async getItemList() {
      return new Promise(async (resolve, reject) => {
        let self = this,
            controller = self.get('controller'),
            temp = Ember.A(),
            temptrans = Ember.A(),
            totalprice = 0,
            url,
            flag = 0;
        let Variable = Ember.Object.extend({
          id: null,
          item_name: null,
          itemnum: null,
          variantnotes: null,
          notes: null,
          price: null,
          qty: null,
          subtotal: null,
          filepath: null
        }),
            Transaction = Ember.Object.extend({
          id: null,
          address_1: null,
          postal_code: null,
          country: null,
          subtotal: null,
          discount: null,
          paidtotal: null,
          pricediscount: null,
          status: null
        });

        if (controller.get('model').product && controller.get('model').qty) {
          url = self.store.adapterFor('application').get('namespace') + '/cart/getCartlistinProduct';
        } else if (controller.get('model').tr) {
          flag = 1;
          url = self.store.adapterFor('application').get('namespace') + '/cart/getTransactionlistinProduct';
        } else {
          controller.set('model', null);
          url = self.store.adapterFor('application').get('namespace') + '/cart/getCartlistinCheckout';
        }

        await (0, _axios.default)({
          method: "POST",
          data: {
            isdelete: false,
            user: this.get('authObject.data.authenticated.user').id,
            model: controller.get('model')
          },
          url
        }).then(function (response) {
          if (flag === 1) {
            let item = response.data.data.item,
                transactions = response.data.data.transaction;
            item.forEach(items => {
              let itemcontent = Variable.create({
                id: items.id,
                item_name: items.item_name,
                itemnum: items.itemnum,
                variantnotes: items.variantnotes,
                notes: items.notes,
                qty: items.item_qty,
                filepath: items.filepath,
                price: items.price,
                subtotal: parseInt(items.price) * parseInt(items.qty)
              });
              temp.pushObject(itemcontent);
              controller.set('item', temp);
            });
            transactions.forEach(trans => {
              let transcontent = Transaction.create({
                id: trans.id,
                address_1: trans.address_1,
                postal_code: trans.postal_code,
                country: trans.country,
                subtotal: trans.subtotal,
                discount: trans.discount,
                paidtotal: trans.paidtotal,
                pricediscount: parseFloat(trans.subtotal * parseFloat(trans.discount / 100).toFixed(1)).toFixed(1),
                status: trans.status
              });
              temptrans.pushObject(transcontent);
              controller.set('transaction', temptrans[0]);
            });
          } else {
            let item = response.data.carts;
            item.forEach(items => {
              let itemcontent = Variable.create({
                id: items.id,
                item_name: items.item_name,
                itemnum: items.itemnum,
                variantnotes: items.variantnotes,
                notes: items.notes,
                qty: items.qty,
                filepath: items.filepath,
                price: items.price,
                subtotal: parseFloat(items.price) * parseFloat(items.qty)
              });
              temp.pushObject(itemcontent);
              controller.set('item', temp);
            });
            controller.get('item').forEach(items => {
              totalprice += items.subtotal;
            });
            controller.set('totalprice', totalprice);
          }
        }).catch(function (error) {
          console.log('Error ' + error);
          reject();
        });
        await (0, _axios.default)({
          method: "POST",
          data: {
            status: 'New',
            isdelete: false,
            user: this.get('authObject.data.authenticated.user').id
          },
          url: self.store.adapterFor('application').get('namespace') + '/transaction/destroyTransaction'
        }).then(function (response) {// console.log('Erase transaction');
        }).catch(function (error) {
          console.log('Error ' + error);
          reject();
        });
        controller.set('loadingPage', false);
        resolve(true);
      });
    },

    getInfo: function () {
      let self = this,
          controller = self.get('controller'),
          temp = Ember.A(),
          tempaddress = Ember.A();
      let Address = Ember.Object.extend({
        id: null,
        name: null,
        address_1: null,
        address_2: null,
        city: null,
        state: null,
        country: null,
        postal_code: null,
        isactive: null
      });
      (0, _axios.default)({
        method: "POST",
        data: {
          isdelete: false,
          user: this.get('authObject.data.authenticated.user').id
        },
        url: self.store.adapterFor('application').get('namespace') + '/customer/getInfo'
      }).then(function (response) {
        let customer = response.data.customer;
        customer.forEach(cus => {
          controller.set('user', cus);
        });
      }).catch(function (error) {
        console.log('Error ' + error);
      });
      (0, _axios.default)({
        method: "POST",
        data: {
          isdelete: false,
          user: this.get('authObject.data.authenticated.user').id
        },
        url: self.store.adapterFor('application').get('namespace') + '/useraddress/getAddress'
      }).then(function (response) {
        let activeaddress = response.data.address.firstaddress;

        if (activeaddress.length === 0) {
          controller.set('choosenaddress', null);
        } else {
          activeaddress.forEach(first => {
            controller.set('choosenaddress', first);
          });
        }

        let addresses = response.data.address.updateaddress;

        if (addresses.length === 0) {
          controller.set('address', null);
        } else {
          addresses.forEach(add => {
            let contentaddress = Address.create({
              id: add.id,
              name: add.address_name,
              address_1: add.address_1,
              address_2: add.address_2,
              city: add.city,
              state: add.state,
              country: add.country,
              postal_code: add.postal_code,
              isactive: add.isactive
            });
            tempaddress.pushObject(contentaddress);
            controller.set('address', tempaddress);
          });
        }
      }).catch(function (error) {
        console.log('Error ' + error);
      });
    },
    createTransaction: function (paymentData) {
      let self = this,
          // controller = self.get('controller'),
      totalprice = paymentData.total_price,
          discount = 0,
          grandtotal = paymentData.total_price; // if (controller.get('grandtotal')) {
      //     grandtotal = parseFloat(controller.get('grandtotal'));
      //     discount = controller.get('discountpercentage');
      // }

      return new Promise(async (resolve, reject) => {
        //if (controller.get('choosenaddress') && controller.get('user').phone)
        //why need phone number to create transaction?
        // if (controller.get('user').phone) {
        (0, _axios.default)({
          method: "POST",
          data: {
            totalprice,
            grandtotal,
            discount,
            item: paymentData.application_id,

            /*
            useraddress: controller.get('choosenaddress'),
            */
            user: self.get('authObject.data.authenticated.user').id
          },
          url: self.store.adapterFor('application').get('ecomWeb') + self.store.adapterFor('application').get('namespace') + '/transaction/createTransactionFromCheckout'
        }).then(function (response) {
          console.log(response);
          resolve(response.data.transaction);
        }).catch(function (error) {
          console.log('Error ' + error);
          reject();
        }); // } else {
        //     controller.set('indicator', 'novariant');
        //     //console.log('Please Choose Your Address and Phone');
        //     reject();
        // }
      });
    },
    readypay: async function (paymentData) {
      console.log('start payment process'); // return;

      let self = this,
          controller = self.get('controller'),
          totalprice = paymentData.total_price,
          baseUrl = this.store.adapterFor('application').get('host'),
          ecomUrl = this.store.adapterFor('application').get('ecomWeb'),
          // let transaction = await self.createTransaction(paymentData),
      paymentDescription = "".concat(self.get('authObject.data.authenticated.user').id, " pay for application id ").concat(paymentData.order_number),
          paymentID = "TP".concat(self.get('authObject.data.authenticated.user').id, "00").concat(paymentData.order_number, "00"),
          transID = "SSDI:".concat(moment().format('YYYY-MM-DD'), ":").concat(Math.floor(Math.random() * 89999 + 10000), ":HK");
      console.log('Ready to Pay');
      console.log({
        paymentDescription,
        paymentID,
        transID
      });
      (0, _axios.default)({
        method: "POST",
        data: {
          transID: paymentData.order_number,
          moduleID: paymentData.order_number,
          paymentDescription,
          returnURL: "".concat(baseUrl, "/payment"),
          successURL: baseUrl,
          postPaymentAPI: "".concat(ecomUrl, "/api/v1/transaction/getPostPaymentAPI_GP"),
          paymentgateway: 2,
          paymentgatewaydetail: 4,
          currency: 'BND',
          paymentID,
          amount: totalprice,
          subscriptionFee: 0,
          totalAmount: totalprice,
          paymentType: "Credit Card",
          user: self.get('authObject.data.authenticated.user').id
        },
        url: self.store.adapterFor('application').get('ecomWeb') + self.store.adapterFor('application').get('namespace') + '/formpayment/extractPaymentInformation'
      }).then(function (response) {
        console.log('Success !'); // for stripe only
        // window.location.replace(`${ baseUrl }${response.data}`);
        // for baiduri payment

        window.location.replace("".concat(response.data));
      }).catch(function (error) {
        console.log('Error ' + error);
      });
    },

    promoCode(code) {
      let self = this,
          controller = self.get('controller');
      (0, _axios.default)({
        method: "POST",
        data: {
          isdelete: false,
          code
        },
        url: self.store.adapterFor('application').get('namespace') + '/promocode/getPromocode'
      }).then(function (response) {
        let promocode = response.data.promocode,
            totalprice = controller.get('totalprice'),
            grandtotal = 0,
            disc = 0;

        if (promocode) {
          disc = parseFloat(promocode.discountpercentage / 100).toFixed(2);
          grandtotal = parseFloat(totalprice).toFixed(2) - parseFloat(totalprice * disc).toFixed(2);
          controller.setProperties({
            promocode: promocode,
            nopromo: true,
            disablebutton: true,
            disc: totalprice * disc,
            discountpercentage: promocode.discountpercentage,
            grandtotal: grandtotal
          });
        } else {
          controller.setProperties({
            nopromo: false,
            disablebutton: false
          });
        }
      }).catch(function (error) {
        console.log('Error ' + error);
      });
    }

  });

  _exports.default = _default;
});